import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as types from '@/actionTypes/selectTypes';
import { REQUEST } from '@/utils/reduxUtils';
import * as userApis from '@/apis/userApi';
import * as contentApis from '@/apis/contentApi';
import * as apis from '@/apis/selectApi';
import * as actions from '@/actions/SelectActions';
import * as msgs from '@/actions/SnackbarActions';
import { responseCheck } from '@/utils/helpers/responseHelper';

function* fetchCompanyList({ condition, paginator }) {
  try {
    const result = yield call(
      userApis.requestCompanyList,
      condition,
      paginator
    );
    yield call(responseCheck, result);
    yield put(actions.searchCompany.success(result.data, result.pagination));
  } catch (error) {
    yield put(actions.searchCompany.failure(error));
  }
}

function* fetchApplicationList() {
  try {
    const result = yield call(apis.requestApplist);
    yield call(responseCheck, result);
    yield put(actions.getApplications.success(result.data));
  } catch (error) {
    yield put(msgs.warning(`${error}`, true));
    yield put(actions.getApplications.failure(error));
  }
}

function* fetchOtherBrnList({ brn }) {
  try {
    const result = yield call(apis.requestOtherBrnList, brn);
    yield call(responseCheck, result);
    yield put(msgs.clear());
    yield put(actions.getOtherBrnList.success(result.data));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.getOtherBrnList.failure(error));
  }
}

function* fetchOtherBrnListForSpoc() {
  try {
    const result = yield call(apis.requestOtherBrnListForSpoc);
    yield call(responseCheck, result);
    yield put(msgs.clear());
    yield put(actions.getOtherBrnsListForSpoc.success(result.data));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.getOtherBrnsListForSpoc.failure(error));
  }
}

function* fetchContentList({ condition, paginator, id }) {
  try {
    const result = yield call(
      contentApis.requestContentList,
      condition,
      paginator
    );
    yield call(responseCheck, result);
    yield put(
      actions.searchContentList.success(result.data, result.pagination, id)
    );
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.searchContentList.failure(error, id));
  }
}

function* fetchUserList({ condition, paginator }) {
  try {
    const result = yield call(userApis.requestUserList, condition, paginator);
    yield call(responseCheck, result);
    yield put(actions.getUserList.success(result.data, result.pagination));
  } catch (error) {
    yield put(actions.getUserList.failure(error));
  }
}

export default function* selectSagas() {
  yield takeLatest(types.getCompanyList[REQUEST], fetchCompanyList);
  yield takeLatest(types.getAppList[REQUEST], fetchApplicationList);
  yield takeLatest(types.getOtherBrnList[REQUEST], fetchOtherBrnList);
  yield takeLatest(
    types.getOtherBrnsListForSpoc[REQUEST],
    fetchOtherBrnListForSpoc
  );
  yield takeEvery(types.searchContentList[REQUEST], fetchContentList);
  yield takeLatest(types.getUserList[REQUEST], fetchUserList);
}
