import * as types from '@/actionTypes/emailTypes';
import { REQUEST, SUCCESS, FAILURE, createReducer } from '@/utils/reduxUtils';

export const initialState = {
  pagination: {},
  trackEmailList: [],
  isLoadingTrackEmailList: false,
  emailTempList: [],
  isLoadingEmailTempList: false,
  tempEmail: {},
  tempVariables: [],
  isLoadingTempEmail: false,
  isUpdateTempEmail: false,
  isPending: false
};

//Get Track Email List
export default createReducer(initialState, {
  [types.getTrackEmailList[REQUEST]](state) {
    return {
      ...state,
      isLoadingTrackEmailList: true
    };
  },
  [types.getTrackEmailList[SUCCESS]](state, action) {
    return {
      ...state,
      trackEmailList: action.data,
      pagination: action.pagination,
      isLoadingTrackEmailList: false
    };
  },
  [types.getTrackEmailList[FAILURE]](state) {
    return {
      ...state,
      trackEmailList: [],
      pagination: {},
      isLoadingTrackEmailList: false
    };
  },

  //Resend Email
  [types.resendEmail[REQUEST]](state) {
    return {
      ...state,
      isPending: true
    };
  },

  //Get template email list
  [types.getTempEmailList[REQUEST]](state) {
    return {
      ...state,
      isLoadingEmailTempList: true
    };
  },
  [types.getTempEmailList[SUCCESS]](state, action) {
    return {
      ...state,
      isLoadingEmailTempList: false,
      emailTempList: action.data
    };
  },
  [types.getTempEmailList[FAILURE]](state) {
    return {
      ...state,
      isLoadingEmailTempList: false,
      emailTempList: []
    };
  },

  //Get Template Email
  [types.getTempEmail[REQUEST]](state) {
    return {
      ...state,
      isLoadingTempEmail: true
    };
  },
  [types.getTempEmail[SUCCESS]](state, action) {
    return {
      ...state,
      isLoadingTempEmail: false,
      tempEmail: action.data
    };
  },
  [types.getTempEmail[FAILURE]](state) {
    return {
      ...state,
      isLoadingTempEmail: false,
      tempEmail: {}
    };
  },

  //Get Template Variables
  [types.getVariables[REQUEST]](state) {
    return {
      ...state
    };
  },
  [types.getVariables[SUCCESS]](state, action) {
    return {
      ...state,
      tempVariables: action.data
    };
  },
  [types.getVariables[FAILURE]](state) {
    return {
      ...state,
      tempVariables: []
    };
  },

  //Update Email template
  [types.updateTempEmail[REQUEST]](state) {
    return {
      ...state,
      isPending: true
    };
  },

  //transferProfile reducer
  [types.transferProfile[REQUEST]](state) {
    return {
      ...state,
      isPending: true
    };
  },

  //change pending reducer
  [types.changePending](state, action) {
    return {
      ...state,
      isPending: action.pendingStatus
    };
  }
});
