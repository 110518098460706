import { REQUEST, SUCCESS, FAILURE, createAction } from '@/utils/reduxUtils';
import * as types from '@/actionTypes/accountTypes';

export const getAccount = {
  request: () => createAction(types.getAccount[REQUEST]),
  success: (data) => createAction(types.getAccount[SUCCESS], { data }),
  failure: (status) => createAction(types.getAccount[FAILURE], status)
};

export const updateAccount = {
  request: (accountDetails) =>
    createAction(types.updateAccount[REQUEST], { accountDetails })
};

export const changePending = (pendingStatus) =>
  createAction(types.changePending, { pendingStatus });
