import styled from 'styled-components';
import Theme from '@/theme';

export default {
  HorizontalRule: styled.hr`
    height: auto;
    border-bottom-style: ${(props) => (props.double ? 'double' : 'solid')};
    border-bottom-width: ${(props) => (props.double ? 'medium' : 'thin')};
    border-bottom-color: ${(props) =>
      props.double ? Theme.colours.grey_800 : Theme.colours.grey_300};
  `
};
