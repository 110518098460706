import React from 'react';
import * as PropTypes from 'prop-types';
import SC from './styles';

const HorizontalRule = ({ double }) => {
  return <SC.HorizontalRule double={double} />;
};

HorizontalRule.defaultProps = {
  double: false
};

HorizontalRule.propTypes = {
  /** Sets the style of the horizontal rule to have either single or double lines */
  double: PropTypes.bool
};

export default HorizontalRule;
