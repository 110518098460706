import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '@/actionTypes/registrationTypes';
import { REQUEST } from '@/utils/reduxUtils';
import * as apis from '@/apis/registrationApi';
import * as actions from '@/actions/RegistrationActions';
import { responseCheck } from '@/utils/helpers/responseHelper';
import * as msgs from '@/actions/SnackbarActions';
import { navigate } from 'gatsby';
import { isProduction } from '@/utils/helpers';
import settings from 'static/assets/config/settings.json';

const navigateUrl = isProduction()
  ? `${settings.pathPrefix}/login/`
  : '/login/';

export const cesSuccessLabel = `Please contact your Singtel Account Manager or <a href='mailto:bizportal@singtel.com' class='help__email--link'>bizportal@singtel.com</a> if you require other application access.\n
Click <a href=${navigateUrl}>here</a> to access Singtel Business Portal.`;

export const companySuccessLabel = `Thank you for applying Singtel Business Portal access.\n Your Application has been processed and you will receive your
starter email shortly.\n If you do not hear from the system within 3 days,please contact
Singtel Business Portal support team at bizportal@singtel.com.`;

function* fetchVerifyBrn({ brn, accountNum }) {
  try {
    const result = yield call(apis.requestVerifyBrn, brn, accountNum);
    yield call(responseCheck, result);
    yield put(actions.verifyBrn.success(result.data));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.verifyBrn.failure(error));
  }
}

function* fetchVerifyBrnList({ brn, brnList }) {
  try {
    const result = yield call(apis.requestVerifyBrnList, brn, brnList);
    yield call(responseCheck, result);
    yield put(actions.verifyBrnList.success(brnList));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.verifyBrnList.failure(error));
  }
}

function* fetchCompanyRegistration({ companyInfo }) {
  try {
    const result = yield call(apis.requestCompanyRegistration, companyInfo);
    yield put(actions.changePending(false));
    yield call(responseCheck, result);
    yield call(navigate, '/result', {
      state: {
        head: 'Success',
        label: companySuccessLabel,
        redirectTo: '/login',
        noCount: true
      }
    });
  } catch (error) {
    yield put(msgs.danger(`${error}`));
  }
}

function* fetchVerifyServiceNum({ serviceInfo }) {
  try {
    yield put(msgs.clear());
    const result = yield call(apis.requestVerifyServiceNum, serviceInfo);
    yield call(responseCheck, result);
    yield put(actions.verifyServiceNum.success(result.data));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.verifyServiceNum.failure(error));
  }
}

function* fetchRegisterCESCustomer({ userInfo }) {
  try {
    const result = yield call(apis.requestCESRegistration, userInfo);
    yield put(actions.changePending(false));
    yield call(responseCheck, result);
    yield call(navigate, '/result', {
      state: {
        head: 'Success',
        label: cesSuccessLabel,
        redirectTo: '/login',
        noCount: true,
        showRedirect: false
      }
    });
  } catch (error) {
    yield put(msgs.danger(`${error?.message}`));
  }
}

export default function* registrationSagas() {
  yield takeLatest(types.verifyBrn[REQUEST], fetchVerifyBrn);
  yield takeLatest(types.verifyBrnList[REQUEST], fetchVerifyBrnList);
  yield takeLatest(types.verifyServiceNum[REQUEST], fetchVerifyServiceNum);
  yield takeLatest(
    types.companyRegistration[REQUEST],
    fetchCompanyRegistration
  );
  yield takeLatest(
    types.registerCESCustomer[REQUEST],
    fetchRegisterCESCustomer
  );
}
