import userSagas from './UserSagas';
import loginSagas from './LoginSagas';
import accountSagas from './AccountSagas';
import companySagas from './CompanySagas';
import messageSagas from './MessageSagas';
import SelectSagas from './SelectSagas';
import reportSagas from './ReportSagas';
import contentSagas from './ContentSagas';
import emailSagas from './EmailSagas';
import registrationSagas from './RegistrationSagas';
import userRegistrationSagas from './UserRegistrationSagas';
import { fork, all } from 'redux-saga/effects';

export default function* root() {
  yield all([fork(userSagas)]);
  yield all([fork(loginSagas)]);
  yield all([fork(accountSagas)]);
  yield all([fork(companySagas)]);
  yield all([fork(messageSagas)]);
  yield all([fork(SelectSagas)]);
  yield all([fork(reportSagas)]);
  yield all([fork(contentSagas)]);
  yield all([fork(emailSagas)]);
  yield all([fork(registrationSagas)]);
  yield all([fork(userRegistrationSagas)]);
}
