import smoothscroll from 'smoothscroll-polyfill';
import { navigate } from 'gatsby';
import 'unfetch/polyfill/index';
import createStore from './src/store';
import { WrappedRoot } from './index';
import * as msgs from '@/actions/SnackbarActions';
import * as modalActions from '@/actions/ResultModalActions';
import { getFlow, getLoginPath } from '@/utils/helpers/login';

smoothscroll.polyfill();

export const onPreRouteUpdate = ({ location }) => {
  const store = createStore.store.getState();
  const token = store.login?.currentUser?.authToken;
  /* clear snackbar content before route update */
  createStore.store.dispatch(msgs.clear());
  /* close Result modal before route update */
  createStore.store.dispatch(modalActions.closeModal());
  /* redirect user back to login page if token not exsist */
  if (
    !token &&
    !location.pathname.includes('/login') &&
    // !location.pathname.includes('/specificLogin') &&
    !location.pathname.includes('/static') &&
    !location.pathname.includes('/result')
  )
    navigate(getLoginPath());
  if (
    location.pathname.includes('/companySelfRegistration') ||
    location.pathname.includes('/cesUserRegistration')
  )
    navigate(getLoginPath());
};

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV === 'development') {
    // console.log('Route change:', location.pathname);
  }
};

export const onInitialClientRender = () => {
  const { flow } = getFlow();
  document.title = flow === 'UDF' ? 'Empower Portal' : 'Bizportal';

  window.addEventListener('message', (e) => {
    if (e && e.data && typeof e.data === 'string') {
      const { key, data } = JSON.parse(e.data) || {};
      if (key === 'VMB_P_BILL_SUMMARY') {
        navigate('/external/', { state: { link: data } });
      }
    }
  });
};

export const wrapRootElement = WrappedRoot;
