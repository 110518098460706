/**
 * Set cookie
 * @example
 * import cookieUtils from './cookieUtils';
 * cookieUtils.setCookie('cookieName','cookieValue', 1);
 *
 * @param {string} cname cookie name
 * @param {string} cvalue cookie value
 * @param {string} exdays cookie expire days
 */
function set(cname, cvalue, exdays) {
  let d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

/**
 * Get cookie
 * @example
 * import cookieUtils from './cookieUtils';
 * cookieUtils.getCookie('cookieName');
 *
 * @param {string} cname cookie name
 */
function get(cname) {
  let name = cname + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

/**
 * Remove cookie
 * @example
 * import cookieUtils from './cookieUtils';
 * cookieUtils.removeCookie('cookieName');
 *
 * @param {string} cname cookie name
 */
function remove(cname) {
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export default {
  set,
  get,
  remove
};
