import Fetch from '../utils/fetch';
import getUrl from '../utils/urlUtils';

//request for company list
export async function requestGetCompanyList(search, paginator) {
  const urls = await getUrl();
  const segment =
    search && search.segment && search.segment.map((item) => item.value);
  const status =
    search && search.status && search.status.map((item) => item.value);
  const postbody = { ...search, segment, status, paginator };
  return Fetch.post(urls.getCompanyList, postbody);
}

//request for company details
export async function requestGetCompanyDetails(profileId) {
  const urls = await getUrl();
  return Fetch.get(urls.getCompanyDetails + '/' + profileId);
}

//request for update company
export async function requestUpdateCompany(companyDetails) {
  const urls = await getUrl();
  return Fetch.post(urls.updateCompany, companyDetails);
}

//request for get whole list of application
export async function requestGetAppList(msbs, segment) {
  const urls = await getUrl();
  return Fetch.get(urls.getAppList + '/' + msbs + '/' + segment);
}

//request for get Company details from AcctO application
export async function requestGetBRNFromAcctO(brn) {
  const urls = await getUrl();
  return Fetch.post(urls.getBRNFromAcctO, brn);
}

//request create company
export async function requestCreateCompany(companyDetails) {
  const urls = await getUrl();
  return Fetch.post(urls.createCompany, companyDetails);
}

//request delete company, Single company or multiple companies both can, companyId + datetime in array
export async function requestDeleteCompany(companyList) {
  const urls = await getUrl();
  return Fetch.post(urls.deleteCompany, companyList);
}

//request suspend company
export async function requestSuspendCompany(companyId) {
  const urls = await getUrl();
  return Fetch.get(urls.suspendCompany + '/' + companyId);
}

//request activate company
export async function requestActivateCompany(companyId) {
  const urls = await getUrl();
  return Fetch.get(urls.activateCompany + '/' + companyId);
}

//request eBa, eBO, DMBD Package User List
export async function requestGetPackageUserList(cahpId) {
  const urls = await getUrl();
  return Fetch.get(urls.getPackageUserList + '/' + cahpId);
}

//request eBa Package List
export async function requestGetEBAPackageList() {
  const urls = await getUrl();
  return Fetch.get(urls.getEBAPackageList);
}

//request eBo Package List
export async function requestGetEBOPackageList() {
  const urls = await getUrl();
  return Fetch.get(urls.getEBOPackageList);
}

//request DMBD Package List
export async function requestGetDMBDPackageList() {
  const urls = await getUrl();
  return Fetch.get(urls.getDMBDPackageList);
}

//request Export Company List
export async function requestExportCompanyList(search) {
  const urls = await getUrl();
  const status =
    search && search.status && search.status.map((item) => item.value);
  const postbody = { ...search, status };
  return Fetch.post(urls.exportCompanyList, postbody);
}

//request vmb transaction id
export async function requestVmbTransactionId() {
  const urls = await getUrl();
  return Fetch.get(urls.getVmbTransactionId);
}
