import Fetch from '../utils/fetch';
import getUrl from '../utils/urlUtils';
//request Message List from api
export async function requestMessageList(condition, paginator) {
  const urls = await getUrl();
  const postbody = {
    ...condition,
    location: condition.location.value,
    paginator
  };
  return Fetch.post(urls.getMessageList, postbody);
}
//Update message
export async function editMessage(message) {
  const urls = await getUrl();
  return Fetch.post(urls.editMessage, message);
}
//createMessage
export async function createMessage(message) {
  const urls = await getUrl();
  return Fetch.post(urls.createMessage, message);
}
//createMessage
export async function deleteMessageList(obj) {
  const urls = await getUrl();
  return Fetch.post(urls.deleteMessageList, obj);
}
export async function exportMessage(condition) {
  const urls = await getUrl();
  return Fetch.post(urls.exportMessage, condition);
}
