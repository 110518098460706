import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Theme from '@/theme';
import SC from './styles';

const clrs = Theme.colours;

const CheckBox = (props) => {
  const {
    isChecked = false,
    disabled = false,
    onChange = () => {},
    onClick = () => {}
  } = props;
  const [checked, setChecked] = useState(isChecked);

  const handleChange = () => {
    onChange(!checked);
    setChecked((prev) => !prev);
  };

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <SC.StyledContainer className={props.className}>
      <SC.StyledSpan checked={checked} disabled={disabled}>
        <SC.StyledInput
          type="checkbox"
          {...props}
          checked={checked}
          onChange={handleChange}
          onClick={onClick}
        />
        <svg
          viewBox="0 0 32 32"
          height="22"
          width="22"
          fill={disabled ? clrs.grey_700 : clrs.linkblue}
        >
          <SC.StyledPath
            checked={checked}
            d="M12.8 25.3c-.1 0-.2 0-.3-.1l-7.3-6.8c-.2-.2-.2-.5 0-.7.2-.2.5-.2.7 0l6.9 6.4L26.1 7.5c.2-.2.5-.2.7-.1.2.2.2.5.1.7l-13.7 17c-.1.1-.2.2-.4.2z"
          ></SC.StyledPath>
        </svg>
      </SC.StyledSpan>
    </SC.StyledContainer>
  );
};

CheckBox.propTypes = {
  isChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default CheckBox;
