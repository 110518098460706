import 'url-search-params-polyfill';

import Fetch from '@/utils/fetch';
import getUrl from '@/utils/urlUtils';
import { isDevelop } from '@/utils/helpers';

/**
 * @description request for login
 * @param userid string
 * @param password string
 */
export async function requestLogin(userid, password) {
  const urls = await getUrl();
  const url = isDevelop()
    ? urls.login
    : `${window.location.origin}/siteminderagent/forms/login.fcc`;

  const postbody = new URLSearchParams();
  postbody.append('target', urls.login);
  postbody.append('USER', userid);
  postbody.append('PASSWORD', password);

  const header = {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  };

  return Fetch.postPlainBody(url, postbody, header);
}

export async function requestImpersonate(userid) {
  const urls = await getUrl();
  const url = `${window.location.origin}/siteminderagent/forms/reskinimper1.fcc`;

  const postbody = new URLSearchParams();
  postbody.append('target', urls.impersonate);
  postbody.append('impersonateid', userid);
  postbody.append('USER', userid);

  const header = {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  };

  //call impersonate api if in development environment with customized header
  return isDevelop()
    ? Fetch.get(urls.impersonate)
    : Fetch.postPlainBody(url, postbody, header);
}

//request getToken
export async function requestToken(email) {
  const urls = await getUrl();
  return Fetch.get(urls.getToken + '/' + email);
}

//request menu items from server
export async function requestMenu() {
  const urls = await getUrl();
  return Fetch.get(urls.getPermission);
}

//request notifications
export async function requestNotifications(location) {
  const urls = await getUrl();
  return Fetch.get(urls.getNotification + '/' + location);
}

//request set new password
export async function requestSetNewPassword(key, token, password) {
  const urls = await getUrl();
  const postbody = { key, token, password };
  return Fetch.post(urls.setNewPassword, postbody, {}, true);
}

//request forgot password
export async function requestForgotPassword(email, flow) {
  const urls = await getUrl();
  const postbody = { email };
  let requestUrl = urls.forgotPassword;
  if (flow) {
    postbody.flowId = flow;
    requestUrl = urls.forgotPasswordTpi;
  }
  return Fetch.post(requestUrl, postbody, {}, true);
}

//request change password
export async function requestChangePassword(oldPasswrd, newPasswrd) {
  const urls = await getUrl();
  const postbody = { oldPasswrd, newPasswrd };
  return Fetch.post(urls.changePassword, postbody);
}

//request logout
export async function requestLogout(isImpersonation) {
  const urls = await getUrl();
  const url = `${window.location.origin}/siteminderagent/forms/reskinimperlogout.fcc`;
  //call logoffImpersonation api if this is in impersontaion status
  return isDevelop()
    ? Fetch.get(urls.logout)
    : isImpersonation
    ? Fetch.get(url)
    : Fetch.get(urls.logout);
}

//request Report Application Access Action
export async function requestReportAppAccess(auditKey) {
  const urls = await getUrl();
  const postbody = { app: auditKey };
  return Fetch.post(urls.userApplicationAccess, postbody);
}
