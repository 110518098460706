import Fetch from '@/utils/fetch';
import getUrl from '@/utils/urlUtils';

export async function requestUserList(condition, paginator) {
  const urls = await getUrl();
  let { userRoleList = [], userStatusList = [], applicationAccessList = [] } =
    condition || {};
  userRoleList = (userRoleList || []).map((item) => item.value);
  userStatusList = (userStatusList || []).map((item) => item.value);
  applicationAccessList = (applicationAccessList || []).map(
    (item) => item.value
  );
  const postbody = {
    ...condition,
    userRoleList,
    userStatusList,
    applicationAccessList,
    paginator
  };
  return Fetch.post(urls.getUsers, postbody);
}

export async function requestUserDetail(userId) {
  const urls = await getUrl();
  return Fetch.get(urls.getUserDetail + userId);
}

export async function requestCompanyList(condition, paginator) {
  const urls = await getUrl();
  const postBody = { ...condition, paginator };
  return Fetch.post(urls.getCompanyList, postBody);
}

export async function requestCompanyApp(profileId) {
  const urls = await getUrl();
  return Fetch.get(urls.getCompanyApp + profileId);
}

export async function requestCreateUser(userDetail) {
  const urls = await getUrl();
  const { userApplication = [], ...others } = userDetail || {};
  let appList = [],
    pkgObj = {};
  userApplication.forEach((element) => {
    if (element.subscribed) {
      appList.push(element.id);
      if (element.packageId) pkgObj[element.id] = element.packageId;
    }
  });
  const postBody = {
    ...others,
    userApplications: appList,
    userApplicationPackages: pkgObj
  };
  return Fetch.post(urls.createUser, postBody);
}

export async function requestDeleteUser(userMapObj, newSpocAccountId) {
  const urls = await getUrl();
  const postbody = { selectedIdMap: userMapObj, newSpocAccountId };
  return Fetch.post(urls.deleteUser, postbody);
}

export async function requestDeleteSpocUser(
  newSpocAccountID,
  oldSpocAccountId
) {
  const urls = await getUrl();
  const postbody = { selectedIdMap: { newSpocAccountID, oldSpocAccountId } };
  return Fetch.post(urls.deletUserSpoc, postbody);
}
export async function requestEditUser(userDetail) {
  const urls = await getUrl();
  let { userApplication = [], selectOtherBrnNameSet = [], ...others } =
    userDetail || {};
  let appList = [],
    pkgObj = {};
  userApplication.forEach((element) => {
    if (element.subscribed) {
      appList.push(element.id);
      if (element.packageId && element.packageId > -1)
        pkgObj[element.id] = element.packageId;
    }
  });
  const postBody = {
    ...others,
    userApplications: appList,
    userApplicationPackages: pkgObj,
    selectOtherBrnNameSet: selectOtherBrnNameSet.map((item) => item.companyBrn)
  };
  return Fetch.post(urls.editUser, postBody);
}

export async function requestProvisionList(status, paginator) {
  const urls = await getUrl();
  return Fetch.post(urls.provisionList, {
    userProvisionStatus: status,
    paginator
  });
}

export async function requestUserAppList(userId) {
  const urls = await getUrl();
  return Fetch.get(urls.getUserApplications + userId);
}

export async function getUserApplicationsNoChanges(userId) {
  const urls = await getUrl();
  return Fetch.get(urls.getUserAppDetails + userId);
}
export async function requestConfirmUser(userDetail) {
  const urls = await getUrl();
  const { userInfo = {} } = userDetail || {};
  const postBody = {
    groupId: userInfo.groupId,
    emailId: userInfo.email,
    accountId: userInfo.accountId,
    timestamp: userInfo.timeStamp
  };
  return Fetch.post(urls.confirmUser, postBody);
}

export async function requestDownloadUsers(companyProfileId) {
  const urls = await getUrl();
  return Fetch.get(urls.downloadUsers + companyProfileId);
}

export async function requestResetPassword(email) {
  const urls = await getUrl();
  const postbody = { email };
  return Fetch.post(urls.resetPassword, postbody);
}

export async function requestUnlockUser(accountId) {
  const urls = await getUrl();
  const postbody = { accountId };
  return Fetch.post(urls.unlockUser, postbody);
}

export async function requestUpdateUserStatus(
  accountId,
  status,
  newSpocAccountId
) {
  const urls = await getUrl();
  const postBody = { accountId, status, newSpocAccountId };
  return Fetch.post(urls.updateUserStatus, postBody);
}

export async function requestUploadUser(file) {
  const urls = await getUrl();
  const postbody = new FormData();
  postbody.append('item', file);

  const header = { 'Content-Type': undefined };

  return Fetch.postPlainBody(urls.uploadUsers, postbody, header);
}

export async function requestUploadUserErrorList() {
  const urls = await getUrl();
  return Fetch.get(urls.uploadUserErrorList);
}

export async function requestDownloadErrorCSV(errorId) {
  const urls = await getUrl();
  return Fetch.get(urls.downloadErrorFile + errorId);
}

//request Export User List
export async function requestExportUserList(condition) {
  const urls = await getUrl();

  let { userRoleList = [], userStatusList = [], applicationAccessList = [] } =
    condition || {};
  userRoleList = (userRoleList || []).map((item) => item.value);
  userStatusList = (userStatusList || []).map((item) => item.value);
  applicationAccessList = (applicationAccessList || []).map(
    (item) => item.value
  );

  const postbody = {
    ...condition,
    userRoleList,
    userStatusList,
    applicationAccessList
  };
  return Fetch.post(urls.exportUserList, postbody);
}
