import { getUrlParams } from '@lux/helpers';

/**
 * UI standby code
 *
 * this function will remove extra dash in the link that occurs before non character (except &)
 *
 * Jira ticket (BIZP-852)
 *
 * @param {string} value
 * @return {string}
 */
export const replaceDashesBeforeNonCharacter = (value) => {
  const regex = /-+((?:(?!\&)\W+?))/g;
  return value?.replace(regex, (v, d) => d);
};

export const lowerCaseAtStart = (value, pattern) => {
  const regex = RegExp(`^(${pattern})`, 'i');
  return value?.replace(regex, (v) => v.toLowerCase());
};

const pattern = 'HTTPS://|HTTP://';

export const getRedirectSAMLUrl = (targetUrl) => {
  const targetParam = lowerCaseAtStart(targetUrl?.slice(4), pattern);
  return replaceDashesBeforeNonCharacter(targetParam);
};

export const getTarget = (loginUrl) => {
  const urlParams = getUrlParams(window?.location?.search);
  const isSMAuth = urlParams?.SMAUTHREASON;
  return (isSMAuth && urlParams?.TARGET) || loginUrl;
};

export const getFlow = () => {
  const urlParams = getUrlParams(window?.location?.search);
  return {
    flow: urlParams?.FLOWID || '',
    target: urlParams?.TARGET || '',
    ...urlParams
  };
};

export const whenNoTarget = () => !getTarget();

export const getHost = () => window?.location?.host || '';

export const getVendorTarget = (vendor = 'UDF') => {
  const VENDOR_CONFIG = {
    UDF: {
      'web.bizportal.singtel.com': 'https://empower.singtel.com/',
      'web.bizportal.uat3.test.aws.singtel.com':
        'https://empoweruat.singtel.com/',
      'web-test.bizportal.uat3.test.aws.singtel.com':
        'https://empowersit.singtel.com/'
    }
  };
  const hostKey = getHost();
  return VENDOR_CONFIG[vendor][hostKey];
};

export const getLoginPath = () => {
  const { flow } = getFlow();
  const loginPath =
    flow === 'UDF'
      ? getVendorTarget(flow)
      : '/login/' + (window?.location?.search || '');
  return loginPath;
};
