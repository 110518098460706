import * as types from '@/actionTypes/userTypes';
import { REQUEST, SUCCESS, FAILURE, createReducer } from '@/utils/reduxUtils';

export const initialState = {
  //user landing page
  userList: [],
  userPagination: {},
  isLoadingUser: false,
  //user details
  userDetail: {},
  isLoadingUserDetail: false,
  //company list
  companyList: [],
  companyPagination: {},
  isLoadingCompany: false,
  //company application
  companyApp: {},
  isLoadingCompanyApp: false,
  //user provision
  provisionList: [],
  provisionListPagination: {},
  isLoadingProvisionList: false,
  //user application
  userAppDetail: {},
  isLoadingAppDetail: false,
  //export user
  exportUser: '',
  //unlock user
  unlockUserStatus: '',
  //upload user
  uploadUserErrorList: [],
  isLoadingErrorList: false,
  isPendingAction: false,
  isUnlockedUser: false
};

export default createReducer(initialState, {
  [types.getUserList[REQUEST]](state) {
    return {
      ...state,
      isLoadingUser: true
    };
  },
  [types.getUserList[SUCCESS]](state, action) {
    return {
      ...state,
      userList: action.data,
      userPagination: action.pagination,
      isLoadingUser: false
    };
  },
  [types.getUserList[FAILURE]](state) {
    return {
      ...state,
      userDetail: {},
      userPagination: {},
      isLoadingUser: false
    };
  },
  //get user details
  [types.getUserDetail[REQUEST]](state) {
    return {
      ...state,
      userDetail: {},
      isLoadingUserDetail: true
    };
  },
  [types.getUserDetail[SUCCESS]](state, action) {
    return {
      ...state,
      userDetail: action.data,
      isLoadingUserDetail: false
    };
  },
  [types.getUserDetail[FAILURE]](state) {
    return {
      ...state,
      userDetail: {},
      isLoadingUserDetail: false
    };
  },
  //get company reducers
  [types.getCompanyList[REQUEST]](state) {
    return {
      ...state,
      isLoadingCompany: true
    };
  },
  [types.getCompanyList[SUCCESS]](state, action) {
    return {
      ...state,
      companyList: action.data,
      companyPagination: action.pagination,
      isLoadingCompany: false
    };
  },
  [types.getCompanyList[FAILURE]](state) {
    return {
      ...state,
      companyList: [],
      companyPagination: {},
      isLoadingCompany: false
    };
  },
  //get company reducers
  [types.getCompanyApp[REQUEST]](state) {
    return {
      ...state,
      isLoadingCompanyApp: true
    };
  },
  [types.getCompanyApp[SUCCESS]](state, action) {
    return {
      ...state,
      companyApp: action.data,
      isLoadingCompanyApp: false
    };
  },
  [types.getCompanyApp[FAILURE]](state) {
    return {
      ...state,
      companyApp: {},
      isLoadingCompanyApp: false
    };
  },
  //get provision list reducers
  [types.getProvisionList[REQUEST]](state) {
    return {
      ...state,
      isLoadingProvisionList: true
    };
  },
  [types.getProvisionList[SUCCESS]](state, action) {
    return {
      ...state,
      provisionList: action.data,
      provisionListPagination: action.pagination,
      isLoadingProvisionList: false
    };
  },
  [types.getProvisionList[FAILURE]](state) {
    return {
      ...state,
      provisionList: [],
      provisionListPagination: {},
      isLoadingProvisionList: false
    };
  },
  //get user app list reducers
  [types.getUserAppList[REQUEST]](state) {
    return {
      ...state,
      userDetail: {},
      userAppDetail: {},
      isLoadingAppDetail: true
    };
  },
  [types.getUserAppList[SUCCESS]](state, action) {
    return {
      ...state,
      userAppDetail: action.data,
      isLoadingAppDetail: false
    };
  },
  [types.getUserAppList[FAILURE]](state) {
    return {
      ...state,
      userAppDetail: {},
      isLoadingAppDetail: false
    };
  },
  //create user reducer
  [types.createUser[REQUEST]](state) {
    return {
      ...state,
      isPendingAction: true
    };
  },
  //delete user reducer
  [types.deleteUser[REQUEST]](state) {
    return {
      ...state,
      isPendingAction: true
    };
  },
  //delete spoc user reducer
  [types.deleteSpocUser[REQUEST]](state) {
    return {
      ...state,
      isPendingAction: true
    };
  },
  //edit user reducer
  [types.editUser[REQUEST]](state) {
    return {
      ...state,
      isPendingAction: true
    };
  },
  //confirm user reducer
  [types.confirmUser[REQUEST]](state) {
    return {
      ...state,
      isPendingAction: true
    };
  },
  //reset User password
  [types.resetPassword[REQUEST]](state) {
    return {
      ...state,
      isPendingAction: true
    };
  },

  //unlock User
  [types.unlockUser[REQUEST]](state) {
    return {
      ...state,
      isUnlockedUser: false
    };
  },
  [types.unlockUser[SUCCESS]](state, action) {
    return {
      ...state,
      unlockUserStatus: action.data,
      isUnlockedUser: true
    };
  },
  [types.unlockUser[FAILURE]](state) {
    return {
      ...state,
      isUnlockedUser: false
    };
  },

  //change user status reducer
  [types.updateUserStatus[REQUEST]](state) {
    return {
      ...state,
      isPendingAction: true
    };
  },
  //upload user reducer
  [types.uploadUsers[REQUEST]](state) {
    return {
      ...state,
      isPendingAction: true
    };
  },
  [types.getUploadErrorList[REQUEST]](state) {
    return {
      ...state,
      isLoadingErrorList: true
    };
  },
  [types.getUploadErrorList[SUCCESS]](state, action) {
    return {
      ...state,
      isLoadingErrorList: false,
      uploadUserErrorList: action.data
    };
  },
  [types.getUploadErrorList[FAILURE]](state) {
    return {
      ...state,
      isLoadingErrorList: false,
      uploadUserErrorList: []
    };
  },
  //change pending reducer
  [types.changePending](state, action) {
    return {
      ...state,
      isPendingAction: action.pendingStatus
    };
  }, //Export User List reducers
  [types.exportUserList[REQUEST]](state) {
    return {
      ...state,
      isPendingAction: true
    };
  },
  //Clear user Details
  [types.clearUserDetails](state) {
    console.log('CLEAR REDU');
    return {
      ...state,
      unlockUser: ''
    };
  }
});
