import { Theme } from '@lux/components';

const colours = {
  ...Theme.colours,
  grey_50: '#F5F5F5',
  grey_100: '#E5E5E5',
  grey_200: '#E1E1E1',
  grey_300: '#E2E2E2',
  grey_400: '#CCCCCC',
  grey_500: '#DDDDDD',
  grey_600: '#D6D6D6',
  grey_700: '#C6C6C6',
  grey_800: '#A1A1A1',
  grey_900: '#8A8A8A',
  grey_1000: '#707070',
  grey_1100: '#666666',
  grey_1200: '#505050'
};
// console.log(colours);
export default colours;
