import styled from 'styled-components';
import {
  Theme,
  Grid as BaseGrid,
  Column,
  Heading,
  List,
  ListItem,
  Anchor,
  Row
} from '@lux/components';
import { remCalc } from '@lux/helpers';
import AppTheme from '@/theme';

const MenuItems = styled(List)`
  display: flex;
  height: 100%;
`;

const AnchorBlack = styled(Anchor)`
  color: ${Theme.colours.grey_50};
  font-size: ${Theme.fonts.sizes.small};
  cursor: ${(props) => (props.pointer ? 'pointer' : 'auto')};
  &:not([disabled]) {
    &:hover {
      color: ${Theme.colours.black};
    }
  }
`;

const LinkAnchor = styled(Anchor)`
  color: ${Theme.colours.black};
  font-size: ${Theme.fonts.sizes.small};

  &:not([disabled]) {
    &:hover {
      color: ${Theme.colours.bluehover};
    }
  }
`;

const LinkColumn = styled(Column)`
  padding: ${remCalc(5)};
`;

export default {
  Header: styled.div`
    padding: ${remCalc(20)} 0;
    background-color: ${Theme.colours.white};
    box-shadow: 1px 2px 10px 1px ${Theme.colours.grey_d6};
    border-top: 4px solid ${Theme.colours.singtelred};
  `,
  Grid: styled(BaseGrid)`
    width: 100%;
  `,
  FlexRow: styled(Row)`
    ${Theme.media.lg`
    max-height: 550px;
    `};
  `,
  PrimaryMenuColumn: styled(Column)`
    padding: ${remCalc(20)};

    &:last-child {
      border: none;
    }
  `,
  AnchorBlackPrimary: styled(AnchorBlack)`
    color: ${Theme.colours.black};
    font-size: ${Theme.fonts.sizes.large};
  `,
  AnchorBlackSecondary: styled(AnchorBlack)`
    color: ${Theme.colours.black};
    font-size: ${Theme.fonts.sizes.middle};
  `,
  AnchorBlackTertiary: styled(LinkAnchor)`
    font-size: ${Theme.fonts.sizes.middle};
    font-family: AvenirLTStd-Book, Arial, Helvetica, sans-serif;
  `,
  SecondaryContainer: styled.div`
    margin: ${remCalc(20)} 0;
  `,
  SecondaryMenu: styled.div`
    width: 100%;
    background-color: ${Theme.colours.white};
    box-shadow: 1px 2px 10px 1px ${Theme.colours.grey_d6};
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
  `,
  LeftMenuItems: styled(MenuItems)`
    float: left;
    align-items: center;
  `,
  RightMenu: styled(MenuItems)`
    float: right;
    align-items: center;
  `,
  ControlListItem: styled(ListItem)`
    display: flex;
    align-items: center;
    margin-left: ${remCalc(10)} !important;
    ${Theme.media.md`margin-left: ${remCalc(20)} !important;`};
  `,
  GridMobile: styled.div`
    ${Theme.media.lg`display: none;`};
  `,
  GridDesktop: styled.div`
    display: none;
    ${Theme.media.lg`display: block;`};
  `,
  MenuList: styled.div`
    ${Theme.media.sm`
      position: absolute;
      top: 0;
      left: 0;
    `};
    ${Theme.media.lg`
      top: 4.4rem;
      left: 0;
      box-shadow: 0 0 3px ${Theme.colours.grey_8a};
    `};
    width: 100%;
    padding: 0 ${remCalc(20)};
    background-color: ${Theme.colours.white};
    z-index: 99;
  `,
  Brand: styled(Heading)`
    font-family: ${AppTheme.fonts.heading300};
    margin: 0;
    font-weight: bold;
  `,
  Badge: styled.span`
    position: absolute;
    top: -16px;
    right: 14px;
    padding: 0px 4px;
    border-radius: 50%;
    background-color: ${Theme.colours.cherise_d1};
    color: white;
    font-size: 12px;
    font-family: monospace;
  `,
  Hamburger: styled.div`
    cursor: pointer;
    position: relative;
  `,
  Divider: styled.hr`
    ${Theme.media.sm`display: block`};
    ${Theme.media.lg`display: none`};
  `,
  AvatarConatiner: styled.div`
    margin: 0 ${remCalc(20)};
  `,
  Typography: styled.div`
    font-family: ${Theme.fonts.families.AvenirBook};
  `,
  User: styled.span`
    font-weight: bold;
    color: ${Theme.colours.rederror};
  `,
  Impersonation: styled.span`
    background-color: ${Theme.colours.green_60};
    position: absolute;
    top: -${remCalc(20)};
    right: 0;
    margin: ${remCalc(10)};
    padding: ${remCalc(7)} ${remCalc(10)};
    color: white;
    border-radius: 5px;
    z-index: 1;
  `,
  PaddedLinkAnchor: styled(LinkAnchor)`
    padding-left: 20px;
  `,
  AnchorBlack,
  LinkAnchor,
  LinkColumn,
  List: styled.div`
    padding: 10px;
  `,
  ListItem: styled.div`
    padding-left: 25px;
    padding-bottom: 10px;
  `,
  ListTitle: styled.div`
    padding-bottom: 10px;
  `,
  ListNumber: styled.span`
    padding-right: 10px;
  `
};
