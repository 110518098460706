import * as types from '@/actionTypes/loginTypes';
import { REQUEST, SUCCESS, FAILURE, createReducer } from '@/utils/reduxUtils';
import { getExpiryTime } from '@/utils/tokenUtils';
import moment from 'moment';

export const initialState = {
  isLoggedIn: false,
  isPending: false,
  isImpersonation: false,
  currentUser: {},
  contents: [],
  notifications: [],
  menu: {},
  loginTime: {},
  expiryTime: {},
  loadingInitialData: false,
  forgotPassStatus: false,
  userInfo: {}
};

export default createReducer(initialState, {
  //login reducers
  [types.requestUserLogin[REQUEST]](state) {
    return {
      ...state,
      isPending: true
    };
  },
  [types.requestUserLogin[SUCCESS]](state, action) {
    const { data } = action;
    const { loginDetails } = data || {};
    const loginTime = moment();
    const expiryTime = getExpiryTime(loginTime);
    return {
      ...state,
      currentUser: {
        authToken: data.access_token,
        tokenType: data.token_type,
        authStatus: loginDetails?.status,
        email: loginDetails?.email,
        firstName: loginDetails?.firstName,
        lastName: loginDetails?.lastName,
        role: loginDetails?.role,
        account: loginDetails?.account,
        company: loginDetails?.loginCompany
      },
      loginTime,
      expiryTime,
      isPending: false,
      isLoggedIn: true,
      isImpersonation: false
    };
  },
  [types.requestUserLogin[FAILURE]](state) {
    return {
      ...state,
      isPending: false,
      isLoggedIn: false,
      loginTime: {},
      expiryTime: {}
    };
  },
  ['LOGIN/REQUEST_USER_LOGIN_END'](state) {
    return {
      ...state,
      isPending: false,
      isLoggedIn: false,
      loginTime: {},
      expiryTime: {}
    };
  },
  //requestMenuItems reducer
  [types.requestMenuItems[SUCCESS]](state, action) {
    const { data } = action;
    return {
      ...state,
      menu: data
    };
  },
  [types.requestMenuItems[FAILURE]](state) {
    return {
      ...state,
      menu: {}
    };
  },
  //impersonate user
  [types.requestImpersonate[REQUEST]](state) {
    return {
      ...state,
      isPending: true,
      isImpersonation: true
    };
  },
  [types.requestImpersonate[SUCCESS]](state, action) {
    const { data } = action;
    const { loginDetails } = data || {};
    const loginTime = moment();
    const expiryTime = getExpiryTime(loginTime);
    return {
      ...initialState,
      currentUser: {
        authToken: data.access_token,
        tokenType: data.token_type,
        authStatus: loginDetails?.status,
        email: loginDetails?.email,
        firstName: loginDetails.firstName,
        lastName: loginDetails.lastName,
        role: loginDetails.role,
        account: loginDetails.account,
        company: loginDetails.loginCompany
      },
      loginTime,
      expiryTime,
      isPending: false,
      isLoggedIn: true,
      isImpersonation: true
    };
  },
  [types.requestImpersonate[FAILURE]](state) {
    return {
      ...state,
      isPending: false,
      isImpersonation: false
    };
  },
  //carousel reducers
  [types.getContentData[SUCCESS]](state, action) {
    const { data } = action;
    return {
      ...state,
      contents: data,
      loadingInitialData: false
    };
  },
  [types.getContentData[FAILURE]](state) {
    return {
      ...state,
      contents: [],
      loadingInitialData: false
    };
  },
  [types.getNotifications[SUCCESS]](state, action) {
    const { data } = action;
    return {
      ...state,
      notifications: data
    };
  },
  [types.getNotifications[FAILURE]](state) {
    return {
      ...state,
      notifications: []
    };
  },
  [types.changePassword[REQUEST]](state) {
    return {
      ...state,
      isPending: true
    };
  },
  [types.forgotPassword[REQUEST]](state) {
    return {
      ...state,
      isPending: true,
      forgotPassStatus: true
    };
  },
  [types.setNewPassword[REQUEST]](state) {
    return {
      ...state,
      isPending: true
    };
  },
  [types.clearLoginTrash]() {
    return {
      ...initialState
    };
  },
  [types.extendExpiryTime](state) {
    const expiryTime = getExpiryTime(state.loginTime);
    return {
      ...state,
      expiryTime
    };
  },
  //change pending reducer
  [types.changePending](state, action) {
    return {
      ...state,
      isPending: action.pendingStatus,
      forgotPassStatus: action.pendingStatus
    };
  },
  [types.setUserInfo](state, action) {
    return {
      ...state,
      userInfo: action.userInfo
    };
  }
});
