import React from 'react';
import PropTypes from 'prop-types';
import { SelectBox } from '@lux/components';

import SC from './styles';

const NewSelectBox = ({
  items = [],
  onChange = () => {},
  value = {},
  disabled = false,
  ...others
}) => {
  return (
    <SC.Container>
      {items.map((item) => (
        <SC.SelectBox
          width={`calc(${(1 / items.length) * 100}% - 10px)`}
          key={item.label + item.value}
        >
          <SelectBox
            active={item.value === value.value}
            disabled={disabled && item !== value}
            onClick={() => {
              onChange(item);
            }}
            {...others}
          >
            <SC.label>{item.label}</SC.label>
          </SelectBox>
        </SC.SelectBox>
      ))}
    </SC.Container>
  );
};

NewSelectBox.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string
    })
  ),
  value: PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string
  }),
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

export default NewSelectBox;
