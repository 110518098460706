import 'whatwg-fetch';
import { TOKEN_INVALID } from '@/utils/constants';
import { isDevelop } from '@/utils/helpers';
import createStore from '@/store';
import * as loginActions from '@/actions/LoginActions';
import { isTokenExpired } from '@/utils/tokenUtils';
import * as msgs from '@/actions/SnackbarActions';
const IsJsonString = function (str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

function logout() {
  const store = createStore.store.getState();
  const isLoggedIn = store.login?.isLoggedIn;
  if (isLoggedIn) {
    createStore.store.dispatch(loginActions.userLogout.request());
  }
}
let time;
function resetTimer() {
  clearTimeout(time);
  time = setTimeout(logout, 1000 * 60 * 55);
  // 1000 milliseconds = 1 second
}

export const fetchApi = function (url, option) {
  const store = createStore.store.getState();
  const isLoggedIn = store.login?.isLoggedIn;
  //Calling IDLE Timeout on every API call
  resetTimer();
  return fetch(url, option)
    .then((res) => {
      let statusCode = res.status;
      sessionStorage.setItem('httpResponse', statusCode);
      return res.text();
    })
    .then((text) => {
      let res = IsJsonString(text) ? JSON.parse(text) : text;
      const tokenExpired = isTokenExpired();
      if (
        res?.error === TOKEN_INVALID ||
        (isLoggedIn &&
          tokenExpired &&
          url.includes('api/') &&
          !url.includes('/auth/logout'))
      ) {
        //dispatch logout action
        createStore.store.dispatch(
          msgs.danger('Your session is expired/invalid, please login again')
        );
        setTimeout(() => {
          createStore.store.dispatch(loginActions.userLogout.request());
        }, 3000);
      } else if (isLoggedIn && res?.status?.code === '401-002') {
        setTimeout(() => {
          createStore.store.dispatch(loginActions.userLogout.request());
        }, 3000);
      } else if (isLoggedIn && !tokenExpired && url.includes('api/')) {
        //extend the expiryTime for valid api calls
        createStore.store.dispatch(loginActions.extendExpiryTime());
      }
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getCustomHeader = function (header = {}, noAuth) {
  const store = createStore.store.getState();
  const isImpersonation = store.login?.isImpersonation;
  //use customized request header if in dev environment
  const devHead = isDevelop()
    ? {
        //use impersonatee user info if user is in impersonation mode
        HTTP_USER_ID: isImpersonation
          ? 'shaw.ong@singtel.com'
          : 'g-ebillhelp@singtel.com',
        HTTP_USER_ROLE: isImpersonation ? 'CA' : 'SA',
        HTTP_USERLOGIN_STATUS: 'ACTIVE'
      }
    : {};
  let result = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...devHead,
    ...header
  };

  const token = store.login?.currentUser?.authToken;
  if (token && !noAuth) {
    result['Authorization'] = 'Bearer ' + token;
  }
  Object.keys(result).forEach(
    (key) => result[key] === undefined && delete result[key]
  );
  return result;
};

const option = {
  mode: 'cors',
  cache: 'default'
};

/**
 * Call server API/JSON based on HTTP GET
 * @example
 * import Fetch from 'core/v2/fetch';
 * Fetch.get('http://api/user/profile').then(user=>console.log(user)).catch(err=>throw err);
 *
 * @param {string} url
 * @returns Promise
 */
function get(url, fileHeader, header) {
  let op = Object.assign(
    {},
    option,
    {
      method: 'GET',
      headers: getCustomHeader(header)
    },
    fileHeader
  );
  return fetchApi(url, op);
}

/**
 * Call server API based on HTTP POST
 * @example
 * import Fetch from './fetch';
 * Fetch.post('http://api/user/login', {user:'hello', password:'pass'})
 *      .then(status=>console.log(user)).catch(err=>throw err);
 *
 * @param {string} url
 * @param {object} pd
 * @returns Promise
 */
function post(url, pd, hd = {}, noAuth = false) {
  let op = Object.assign({}, option, {
    method: 'POST',
    body: typeof pd === 'string' ? pd : JSON.stringify(pd),
    headers: getCustomHeader(hd, noAuth)
  });

  return fetchApi(url, op);
}

/**
 * Call server API based on HTTP POST
 * @example
 * import Fetch from './fetch';
 * Fetch.post('http://api/user/login', {user:'hello', password:'pass'})
 *      .then(status=>console.log(user)).catch(err=>throw err);
 *
 * @param {string} url
 * @param {object} pd
 * @returns Promise
 */
function postPlainBody(url, pd, hd = {}) {
  let op = Object.assign({}, option, {
    method: 'POST',
    body: pd,
    headers: getCustomHeader(hd)
  });

  return fetchApi(url, op);
}

/**
 * Call server API based on HTTP PUB
 * @example
 * import Fetch from './fetch';
 * Fetch.put('http://api/user/changePassword', {
 *     newPassword:'hello',
 *     olbPassword:'pass',
 *     confirmPassword:'pass'
 * }).then(status=>console.log(user)).catch(err=>throw err);
 *
 * @param {string} url
 * @param {object} pd
 * @returns Promise
 */
function put(url, pd = {}) {
  let op = Object.assign({}, option, {
    method: 'PUT',
    body: JSON.stringify(pd),
    headers: getCustomHeader()
  });

  return fetchApi(url, op);
}

/**
 * Call server API based on HTTP DELETE
 * @example
 * import Fetch from './fetch';
 * Fetch.del('http://api/user/remove/userid').then(status=>console.log(status)).catch(err=>throw err);
 *
 * @param {string} url
 * @returns Promise
 */
function del(url, pd = {}) {
  let op = Object.assign({}, option, {
    method: 'DELETE',
    body: JSON.stringify(pd),
    headers: getCustomHeader()
  });

  return fetchApi(url, op);
}

export default {
  get,
  post,
  postPlainBody,
  put,
  del
};
