import Fetch from '../utils/fetch';
import getUrl from '../utils/urlUtils';

//request for track email list
export async function requestGetTrackEmailList(search, paginator) {
  const urls = await getUrl();
  const postbody = { ...search, paginator };
  return Fetch.post(urls.getTrackEmailList, postbody);
}

//request for resend email
export async function requestResendEmail(recipientIdSet) {
  const urls = await getUrl();
  const postbody = { recipientIdSet };
  return Fetch.post(urls.resendEmail, postbody);
}

//request for get Temp Email List
export async function requestGetTempEmailList() {
  const urls = await getUrl();
  return Fetch.get(urls.getTempEmailList);
}

//request for get temp email
export async function requestGetTempEmail(templateName) {
  const urls = await getUrl();
  const postbody = { templateName };
  return Fetch.post(urls.getTempEmail, postbody);
}

//request for get Variables
export async function requestGetVariables(templateName) {
  const urls = await getUrl();
  const postbody = { templateName };
  return Fetch.post(urls.getVariables, postbody);
}

//Request update temp email
export async function requestUpdateTempEmail(name, context, updateTime) {
  const urls = await getUrl();
  const postbody = { name, context, updateTime };
  return Fetch.post(urls.updateTempEmail, postbody);
}

//Request tranfer Profile
export async function requestTransferProfile(
  giverAccountId,
  recipientAccountId
) {
  const urls = await getUrl();
  const postbody = { giverAccountId, recipientAccountId };
  return Fetch.post(urls.transferProfile, postbody);
}
