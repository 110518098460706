import * as types from '@/actionTypes/userRegistrationTypes';
import { REQUEST, SUCCESS, FAILURE, createReducer } from '@/utils/reduxUtils';
export const initialState = {
  invitationList: [],
  pagination: {},
  isLoadingUserInvitation: false,
  isPendingAction: false,
  inviteDetails: {},
  isLoadingInviteDetail: false,
  isUpdatingStatus: false
};

export default createReducer(initialState, {
  [types.getUserInviteList[REQUEST]](state) {
    return {
      ...state,
      isLoadingUserInvitation: true
    };
  },
  [types.getUserInviteList[SUCCESS]](state, action) {
    return {
      ...state,
      invitationList: action.data,
      pagination: action.pagination,
      isLoadingUserInvitation: false
    };
  },
  [types.getUserInviteList[FAILURE]](state) {
    return {
      ...state,
      invitationList: [],
      pagination: {},
      isLoadingUserInvitation: false
    };
  },
  [types.sendUserInvitation[REQUEST]](state) {
    return {
      ...state,
      isPendingAction: true
    };
  },
  [types.changePendingInvite](state, action) {
    return {
      ...state,
      isPendingAction: action.pendingStatus
    };
  },
  [types.submitUserRegistrationForm[REQUEST]](state) {
    return {
      ...state,
      isPendingAction: true
    };
  },
  [types.getApplicationSubsInfo[REQUEST]](state) {
    return {
      ...state,
      isLoadingInviteDetail: true
    };
  },
  [types.getApplicationSubsInfo[SUCCESS]](state, action) {
    return {
      ...state,
      isLoadingInviteDetail: false,
      inviteDetails: action.data
    };
  },
  [types.getApplicationSubsInfo[FAILURE]](state) {
    return {
      ...state,
      isLoadingInviteDetail: false,
      inviteDetails: {}
    };
  },
  [types.getInvitedUserInfo[REQUEST]](state) {
    return {
      ...state,
      isLoadingInviteDetail: true
    };
  },
  [types.getInvitedUserInfo[SUCCESS]](state, action) {
    return {
      ...state,
      isLoadingInviteDetail: false,
      inviteDetails: action.data
    };
  },
  [types.getInvitedUserInfo[FAILURE]](state) {
    return {
      ...state,
      isLoadingInviteDetail: false,
      inviteDetails: {}
    };
  },
  [types.approveUserInvitation[REQUEST]](state) {
    return {
      ...state,
      isUpdatingStatus: true
    };
  },
  [types.rejectUserInvitation[REQUEST]](state) {
    return {
      ...state,
      isUpdatingStatus: true
    };
  },
  [types.changePendingStatus](state, action) {
    return {
      ...state,
      isUpdatingStatus: action.pendingStatus
    };
  }
});
