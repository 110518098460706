import { REQUEST, SUCCESS, FAILURE, createAction } from '@/utils/reduxUtils';
import * as types from '@/actionTypes/loginTypes';
import { getLoginPath } from '@/utils/helpers/login';

export const requestLogin = {
  request: (userid, password) =>
    createAction(types.requestUserLogin[REQUEST], { userid, password }),
  success: (data) => createAction(types.requestUserLogin[SUCCESS], { data }),
  failure: (status) =>
    createAction(types.requestUserLogin[FAILURE], { status }),
  end: () => createAction('LOGIN/REQUEST_USER_LOGIN_END')
};

export const requestMenuItems = {
  request: (email) => createAction(types.requestMenuItems[REQUEST], { email }),
  success: (data) => createAction(types.requestMenuItems[SUCCESS], { data }),
  failure: (status) => createAction(types.requestMenuItems[FAILURE], { status })
};

export const requestImpersonate = {
  request: (userid) =>
    createAction(types.requestImpersonate[REQUEST], { userid }),
  success: (data) => createAction(types.requestImpersonate[SUCCESS], { data }),
  failure: (status) =>
    createAction(types.requestImpersonate[FAILURE], { status })
};

export const getContentData = {
  request: () => createAction(types.getContentData[REQUEST]),
  success: (data) => createAction(types.getContentData[SUCCESS], { data }),
  failure: (status) => createAction(types.getContentData[FAILURE], status)
};

export const getNotifications = {
  request: (location) =>
    createAction(types.getNotifications[REQUEST], { location }),
  success: (data) => createAction(types.getNotifications[SUCCESS], { data }),
  failure: (status) => createAction(types.getNotifications[FAILURE], status)
};

export const userLogout = {
  request: (
    isImpersonation,
    redirectLink = getLoginPath(),
    message,
    external
  ) =>
    createAction(types.userLogout[REQUEST], {
      isImpersonation,
      redirectLink,
      message,
      external
    })
};

export const startLogin = (userId, password) =>
  createAction(types.startLogin, { userId, password });

export const setNewPassword = {
  request: (key, token, password, flow) =>
    createAction(types.setNewPassword[REQUEST], { key, token, password, flow })
};

export const forgotPassword = {
  request: (email, flow) =>
    createAction(types.forgotPassword[REQUEST], { email, flow })
};

export const extendExpiryTime = () => createAction(types.extendExpiryTime, {});

export const changePassword = {
  request: (oldPassword, newPassword, authStatus, flow) =>
    createAction(types.changePassword[REQUEST], {
      oldPassword,
      newPassword,
      authStatus,
      flow
    })
};

export const reportUserAccessApp = {
  request: (auditKey) =>
    createAction(types.reportUserAccessApp[REQUEST], {
      auditKey
    })
};

export const clearLoginTrash = (redirectLink, message) =>
  createAction(types.clearLoginTrash, { redirectLink, message });

export const changePending = (pendingStatus) =>
  createAction(types.changePending, { pendingStatus });

export const setUserInfo = (userInfo) =>
  createAction(types.setUserInfo, { userInfo });
