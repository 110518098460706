import * as types from '../../actionTypes/companyTypes';
import {
  REQUEST,
  SUCCESS,
  FAILURE,
  createReducer
} from '../../utils/reduxUtils';

export const initialState = {
  companyList: [],
  companyDetails: {},
  companyErrMsg: '',
  pagination: {},
  appList: [],
  isPending: false,
  isLoadingCompanyList: false,
  isLoadingCompanyDetails: false,
  brnFromAcctO: {},
  packageUserList: [],
  isLoadingPackageUserList: false,
  eBAPackageList: [],
  eBOPackageList: [],
  dmbdPackageList: [],
  isLoadingEBAPackageList: false,
  isLoadingEBOPackageList: false,
  isLoadingDMBDPackageList: false,
  exportCompanyList: [],
  vmbTransactionId: ''
};

//Get Company List
export default createReducer(initialState, {
  //get company List reducers
  [types.getCompanyList[REQUEST]](state) {
    return {
      ...state,
      isLoadingCompanyList: true
    };
  },
  [types.getCompanyList[SUCCESS]](state, action) {
    return {
      ...state,
      companyList: action.data,
      pagination: action.pagination,
      isLoadingCompanyList: false
    };
  },
  [types.getCompanyList[FAILURE]](state) {
    return {
      ...state,
      companyList: [],
      pagination: {},
      isLoadingCompanyList: false
    };
  },

  //Get Company details
  [types.getCompanyDetails[REQUEST]](state) {
    return {
      ...state,
      isLoadingCompanyDetails: true
    };
  },
  [types.getCompanyDetails[SUCCESS]](state, action) {
    return {
      ...state,
      companyDetails: action.data,
      isLoadingCompanyDetails: false
    };
  },
  [types.getCompanyDetails[FAILURE]](state) {
    return {
      ...state,
      companyDetails: {},
      isLoadingCompanyDetails: false
    };
  },
  //update company reducers
  [types.updateCompany[REQUEST]](state) {
    return {
      ...state,
      isPending: true
    };
  },
  //Get full application list
  [types.getAppList[REQUEST]](state) {
    return {
      ...state
    };
  },
  [types.getAppList[SUCCESS]](state, action) {
    const { result, vmbTransactionId } = action.data;
    return {
      ...state,
      appList: result,
      vmbTransactionId: vmbTransactionId
    };
  },
  [types.getAppList[FAILURE]](state) {
    return {
      ...state,
      appList: {},
      vmbTransactionId: ''
    };
  },
  //Get BRN information from AcctO application
  [types.getBRNFromAcctO[REQUEST]](state) {
    return {
      ...state,
      isPending: true
    };
  },
  [types.getBRNFromAcctO[SUCCESS]](state, action) {
    return {
      ...state,
      brnFromAcctO: action.data,
      isPending: false
    };
  },
  [types.getBRNFromAcctO[FAILURE]](state) {
    return {
      ...state,
      brnFromAcctO: {},
      isPending: false
    };
  },
  //Create company reducers
  [types.createCompany[REQUEST]](state) {
    return {
      ...state,
      isPending: true
    };
  },
  //Delete Company reducers
  [types.deleteCompany[REQUEST]](state) {
    return {
      ...state,
      isPending: true
    };
  },
  //Suspend Company reducers
  [types.suspendCompany[REQUEST]](state) {
    return {
      ...state,
      isPending: true
    };
  },
  //Activate Company reducers
  [types.activateCompany[REQUEST]](state) {
    return {
      ...state,
      isPending: true
    };
  },
  //Get Package User List reducers
  [types.getPackageUserList[REQUEST]](state) {
    return {
      ...state,
      isLoadingPackageUserList: true
    };
  },
  [types.getPackageUserList[SUCCESS]](state, action) {
    return {
      ...state,
      packageUserList: action.data,
      isLoadingPackageUserList: false
    };
  },
  [types.getPackageUserList[FAILURE]](state) {
    return {
      ...state,
      packageUserList: [],
      isLoadingPackageUserList: false
    };
  },
  //Get eBA Package List reducers
  [types.getEBAPackageList[REQUEST]](state) {
    return {
      ...state,
      isLoadingEBAPackageList: true
    };
  },
  [types.getEBAPackageList[SUCCESS]](state, action) {
    return {
      ...state,
      isLoadingEBAPackageList: false,
      eBAPackageList: action.data
    };
  },
  [types.getEBAPackageList[FAILURE]](state) {
    return {
      ...state,
      isLoadingEBAPackageList: false,
      eBAPackageList: []
    };
  },
  //Get eBO Package List reducers
  [types.getEBOPackageList[REQUEST]](state) {
    return {
      ...state,
      isLoadingEBOPackageList: true
    };
  },
  [types.getEBOPackageList[SUCCESS]](state, action) {
    return {
      ...state,
      isLoadingEBOPackageList: false,
      eBOPackageList: action.data
    };
  },
  [types.getEBOPackageList[FAILURE]](state) {
    return {
      ...state,
      isLoadingEBOPackageList: false,
      eBOPackageList: []
    };
  },
  //Get DMBD Package List reducers
  [types.getDMBDPackageList[REQUEST]](state) {
    return {
      ...state,
      isLoadingDMBDPackageList: true
    };
  },
  [types.getDMBDPackageList[SUCCESS]](state, action) {
    return {
      ...state,
      isLoadingDMBDPackageList: false,
      dmbdPackageList: action.data
    };
  },
  [types.getDMBDPackageList[FAILURE]](state) {
    return {
      ...state,
      isLoadingDMBDPackageList: false,
      dmbdPackageList: []
    };
  },
  //Clear company Details
  [types.clearCompanyDetails](state) {
    return {
      ...state,
      brnFromAcctO: {},
      vmbTransactionId: ''
    };
  },
  //Export Company List reducers
  [types.exportCompanyList[REQUEST]](state) {
    return {
      ...state,
      isPending: true
    };
  },
  [types.exportCompanyList[SUCCESS]](state, action) {
    return {
      ...state,
      isPending: false,
      exportCompanyList: action.data
    };
  },
  [types.exportCompanyList[FAILURE]](state) {
    return {
      ...state,
      isPending: false,
      exportCompanyList: []
    };
  },
  //change pending reducer
  [types.changePending](state, action) {
    return {
      ...state,
      isPending: action.pendingStatus
    };
  }
});
