import { REQUEST, SUCCESS, FAILURE, createAction } from '@/utils/reduxUtils';
import * as types from '@/actionTypes/reportTypes';

export const getReportData = {
  request: (apiUrlKey, reportType, criteria, paginator) =>
    createAction(types.getReportData[REQUEST], {
      apiUrlKey,
      reportType,
      criteria,
      paginator
    }),
  success: (data, pagination) =>
    createAction(types.getReportData[SUCCESS], { data, pagination }),
  failure: (status) => createAction(types.getReportData[FAILURE], status)
};

export const exportReportData = {
  request: (apiUrlKey, criteria, paginator, fileName, reportType) =>
    createAction(types.exportReportData[REQUEST], {
      apiUrlKey,
      criteria,
      paginator,
      fileName,
      reportType
    })
};

export const changePending = (pendingStatus) =>
  createAction(types.changePending, { pendingStatus });
