import { Theme as defaultTheme } from '@lux/components';
import mediaQueries from '@lux/components/src/helpers/mediaQueries';
import { remCalc } from '@lux/helpers';

import grid from './grid';
import colours from './colours';
import fonts from './fonts';
import animations from './animations';

const SPACER = 8;
export const spacing = (size) => remCalc(SPACER * size);

const theme = {
  ...defaultTheme,
  colours,
  fonts,
  grid,
  spacing,
  animations,
  media: mediaQueries(grid.breakpoints)
};

export default theme;
