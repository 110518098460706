import styled from 'styled-components';
import Theme from '@/theme';

export default {
  Container: styled.div`
    font-family: ${Theme.fonts.primary};
    color: ${Theme.colours.grey_1100};
    font-size: 1em;
    font-weight: 600;
    margin: 1rem 0;
  `,
  Ul: styled.ul`
    min-height: 1.5rem;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  `,
  Li: styled.li`
    ${Theme.media.md`
          margin-left: 15px;
      `};
    padding: 5px 7px;
    border-radius: 3px;
    user-select: none;
    background: ${(prop) =>
      prop.isSelected ? Theme.colours.grey_1000 : 'none'};
    color: ${(prop) =>
      prop.isSelected
        ? Theme.colours.grey_500
        : prop.disabled
        ? Theme.colours.grey_500
        : Theme.colours.grey_1100};
    pointer-events: ${(prop) =>
      prop.disabled || prop.isSelected ? 'none' : 'auto'};
    cursor: ${(prop) =>
      prop.disabled || prop.isSelected ? 'default' : 'pointer'};
  `
};
