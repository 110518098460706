import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RadioButton, Row } from '@lux/components';
import styled from 'styled-components';

export const StyledGroup = styled(Row)`
  margin-right: 0;
  margin-left: 0;
  flex-direction: ${(props) => (props.inline ? 'row' : 'column')};
`;

const RadioButtonGroup = ({
  data = [],
  onChange = () => {},
  name,
  form,
  disabled,
  value,
  inline
}) => {
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    setNewData(checkType(data));
  }, [data]);

  const onRadioBtnChange = (checkedValue) => {
    onChange && onChange(checkedValue);
  };

  const checkType = (obj) => {
    let newArray = [];
    if (obj instanceof Array) {
      newArray = obj;
    } else {
      newArray = Object.keys(obj).map((val) => {
        return {
          description: obj[val],
          value: val
        };
      });
    }
    return newArray;
  };

  return (
    <StyledGroup inline={inline} between="sm">
      {newData.map((buttonValue, i) => (
        <RadioButton
          key={i}
          name={name}
          form={form}
          value={buttonValue.value}
          disabled={disabled}
          checked={value === buttonValue.value}
          onChange={onRadioBtnChange}
        >
          {buttonValue.description}
        </RadioButton>
      ))}
    </StyledGroup>
  );
};

RadioButtonGroup.propTypes = {
  /** Class name */
  className: PropTypes.node,
  /** Set the form */
  form: PropTypes.string,
  /** Name of the group of radio buttons it belongs to */
  name: PropTypes.string,
  /** RadioButton data are in the form of either Object : <b>{ 'value' : 'label' }</b> or Array to support description <b>[{ 'value': 'option1', 'label' : 'Option 1', description: 'This is a sample description.'}]</b> */
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        value: PropTypes.string,
        description: PropTypes.node
      })
    ),
    PropTypes.object
  ]),
  /** RadioButtons can be arranged inline or vertical */
  inline: PropTypes.bool,
  /** RadioButtons can be disabled */
  disabled: PropTypes.bool,
  /** Current value of selected button */
  value: PropTypes.string,
  /** onChange handler. Returns currently checked value */
  onChange: PropTypes.func
};

export default RadioButtonGroup;
