const grid = {
  gutterWidth: 1,
  outerMargin: 2,
  container: {
    md: 75,
    lg: 77.5
  },
  breakpoints: {
    sm: 0,
    md: 45,
    lg: 64
  }
};

export default grid;
