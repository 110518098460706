import * as types from '@/actionTypes/contentTypes';
import { REQUEST, SUCCESS, FAILURE, createReducer } from '@/utils/reduxUtils';

export const initialState = {
  contentList: [],
  contentPagination: {},
  isLoadingContent: false,

  contentDetail: {},
  isLoadingContentDetail: false,

  contentImage: '',
  isLoadingContentImage: false,

  loginContents: [],
  isLoadingPageContent: false,

  isPendingAction: false
};

export default createReducer(initialState, {
  //get content list reducers
  [types.getContentList[REQUEST]](state) {
    return {
      ...state,
      contentList: [],
      isLoadingContent: true,

      contentDetail: {},
      isLoadingContentDetail: false
    };
  },
  [types.getContentList[SUCCESS]](state, action) {
    const { data, pagination } = action;
    return {
      ...state,
      contentList: data,
      contentPagination: pagination,
      isLoadingContent: false
    };
  },
  [types.getContentList[FAILURE]](state) {
    return {
      ...state,
      contentList: [],
      contentPagination: {},
      isLoadingContent: false
    };
  },
  //get content detail reducers
  [types.getContentDetail[REQUEST]](state) {
    return {
      ...state,
      contentDetail: {},
      isLoadingContentDetail: true
    };
  },
  [types.getContentDetail[SUCCESS]](state, action) {
    const { data } = action;
    return {
      ...state,
      contentDetail: data,
      isLoadingContentDetail: false
    };
  },
  [types.getContentDetail[FAILURE]](state) {
    return {
      ...state,
      contentDetail: {},
      isLoadingContentDetail: false
    };
  },
  //get page contents reducers
  [types.getPageContents[REQUEST]](state) {
    return {
      ...state,
      loginContents: [],
      isLoadingPageContent: true
    };
  },
  [types.getPageContents[SUCCESS]](state, action) {
    const { data } = action;
    return {
      ...state,
      loginContents: data,
      isLoadingPageContent: false
    };
  },
  [types.getPageContents[FAILURE]](state) {
    return {
      ...state,
      loginContents: [],
      isLoadingPageContent: false
    };
  },
  //create contents
  [types.createContent[REQUEST]](state) {
    return {
      ...state,
      isPendingAction: true
    };
  },
  //edit contents
  [types.editContent[REQUEST]](state) {
    return {
      ...state,
      isPendingAction: true
    };
  },
  //delete contents
  [types.deleteContents[REQUEST]](state) {
    return {
      ...state,
      isPendingAction: true
    };
  },
  //assign contents
  [types.assignContent[REQUEST]](state) {
    return {
      ...state,
      isPendingAction: true
    };
  },
  //change pending reducer
  [types.changePending](state, action) {
    return {
      ...state,
      isPendingAction: action.pendingStatus
    };
  }
});
