import { call, put, takeLatest } from 'redux-saga/effects';
import { navigate } from 'gatsby';
import * as types from '@/actionTypes/reportTypes';
import { REQUEST } from '@/utils/reduxUtils';
import * as apis from '@/apis/reportApi';
import * as actions from '@/actions/ReportActions';
import { OnDownLoadCSV } from '@/utils/helpers';
import { responseCheck } from '@/utils/helpers/responseHelper';
import * as msgs from '@/actions/SnackbarActions';

function* fetchReportData({ apiUrlKey, reportType, criteria, paginator }) {
  try {
    const result = yield call(apis[apiUrlKey], criteria, paginator, reportType);
    yield call(responseCheck, result);
    yield put(actions.getReportData.success(result.data, result.pagination));
    yield call(navigate, '/reports/list/', {
      state: { apiUrlKey, criteria, reportType }
    });
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.getReportData.failure(error));
  }
}

function* fetchExportReportData({
  apiUrlKey,
  criteria,
  paginator,
  fileName,
  reportType
}) {
  try {
    const result = yield call(apis[apiUrlKey], criteria, paginator, reportType);
    yield put(actions.changePending(false));
    yield call(OnDownLoadCSV, result, fileName);
  } catch (error) {
    yield put(actions.changePending(false));
    yield put(msgs.danger(`${error}`));
  }
}

export default function* userSagas() {
  yield takeLatest(types.getReportData[REQUEST], fetchReportData);
  yield takeLatest(types.exportReportData[REQUEST], fetchExportReportData);
}
