import * as types from '@/actionTypes/selectTypes';
import { REQUEST, SUCCESS, FAILURE, createReducer } from '@/utils/reduxUtils';

export const initialState = {
  companyList: [],
  companyPagination: {},
  isLoadingCompany: false,

  appList: [],
  isLoadingAppList: false,

  otherBrnList: [],
  isLoadingOtherBrns: false,

  contentData: {},

  userList: [],
  userPagination: {},
  isLoadingUser: false
};

export default createReducer(initialState, {
  //get company reducers
  [types.getCompanyList[REQUEST]](state) {
    return {
      ...state,
      isLoadingCompany: true
    };
  },
  [types.getCompanyList[SUCCESS]](state, action) {
    const { currentPage } = action.pagination;
    const data =
      currentPage > 1 ? [...state.companyList, ...action.data] : action.data;
    return {
      ...state,
      isLoadingCompany: false,
      companyList: data,
      companyPagination: action.pagination
    };
  },
  [types.getCompanyList[FAILURE]](state) {
    return {
      ...state,
      isLoadingCompany: false,
      companyList: [],
      companyPagination: {}
    };
  },
  //get application reducers
  [types.getAppList[REQUEST]](state) {
    return {
      ...state,
      isLoadingAppList: true
    };
  },
  [types.getAppList[SUCCESS]](state, action) {
    return {
      ...state,
      isLoadingAppList: false,
      appList: action.data
    };
  },
  [types.getAppList[FAILURE]](state) {
    return {
      ...state,
      isLoadingAppList: false,
      appList: []
    };
  },
  //get other brn list
  [types.getOtherBrnList[REQUEST]](state) {
    return {
      ...state,
      isLoadingOtherBrns: true
    };
  },
  [types.getOtherBrnList[SUCCESS]](state, action) {
    return {
      ...state,
      otherBrnList: action.data,
      isLoadingOtherBrns: false
    };
  },
  [types.getOtherBrnList[FAILURE]](state) {
    return {
      ...state,
      otherBrnList: [],
      isLoadingOtherBrns: false
    };
  },
  //get other brn list for SPOC
  [types.getOtherBrnsListForSpoc[REQUEST]](state) {
    return {
      ...state,
      isLoadingOtherBrns: true
    };
  },
  [types.getOtherBrnsListForSpoc[SUCCESS]](state, action) {
    return {
      ...state,
      otherBrnList: action.data,
      isLoadingOtherBrns: false
    };
  },
  [types.getOtherBrnsListForSpoc[FAILURE]](state) {
    return {
      ...state,
      otherBrnList: [],
      isLoadingOtherBrns: false
    };
  },
  //get content list reducers
  [types.searchContentList[REQUEST]](state, action) {
    return {
      ...state,
      contentData: {
        ...state.contentData,
        [action.id]: {
          ...state.contentData[action.id],
          isLoadingContentList: true
        }
      }
    };
  },
  [types.searchContentList[SUCCESS]](state, action) {
    const { currentPage } = action.pagination;
    const currentData = state.contentData[action.id]?.contentList;
    const data =
      currentPage > 1 ? [...currentData, ...action.data] : action.data;
    return {
      ...state,
      contentData: {
        ...state.contentData,
        [action.id]: {
          contentList: data,
          contentPagination: action.pagination,
          isLoadingContentList: false
        }
      }
    };
  },
  [types.searchContentList[FAILURE]](state, action) {
    return {
      ...state,
      contentData: {
        ...state.contentData,
        [action.id]: {
          isLoadingContentList: false,
          contentList: [],
          contentPagination: {}
        }
      }
    };
  },
  //get user list reducers
  [types.getUserList[REQUEST]](state) {
    return {
      ...state,
      isLoadingUser: true
    };
  },
  [types.getUserList[SUCCESS]](state, action) {
    const { currentPage } = action.pagination;
    const data =
      currentPage > 1 ? [...state.userList, ...action.data] : action.data;
    return {
      ...state,
      isLoadingUser: false,
      userList: data,
      userPagination: action.pagination
    };
  },
  [types.getUserList[FAILURE]](state) {
    return {
      ...state,
      isLoadingUser: false,
      userList: [],
      userPagination: {}
    };
  }
});
