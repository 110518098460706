import countries from 'static/assets/mocks/countries.json';

export const isProduction = () =>
  process.env.NODE_ENV === 'production' &&
  process.env.GATSBY_STAGING_ENV !== 'true';

export const isServer = () =>
  typeof window === 'undefined' || typeof document === 'undefined';

export const isDevelop = () =>
  typeof window !== 'undefined' && !isProduction() && !isServer();

export const getCountryCode = () => {
  return countries.map((item) => {
    const text = `${item.dial_code} (${item.name})`;
    const value = item.dial_code;
    return { text, value };
  });
};

export const getCountryCodeSelect = () => {
  return countries.map((item) => {
    const label = `${item.dial_code} (${item.name})`;
    const value = item.dial_code;
    return { label, value };
  });
};
export const isNumeric = (input) => {
  return input && !isNaN(input);
};

export const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

export const isEmptyObject = (obj) => {
  for (let key in obj) {
    return !key;
  }
  return true;
};

//get selected item index by its value
export const getSelectedIndex = (list, value) => {
  if (value) {
    const index = list.findIndex((item) => item.value == value);
    return index > -1 ? index : undefined;
  }
  return undefined;
};
export const getSelectedCountry = (list, value) => {
  return value
    ? list.filter((item) => item.value == value)[0] || undefined
    : undefined;
};
export const OnDownLoadCSV = (data, filename) => {
  const file = new Blob([data], { type: 'text/csv' });
  if (window.navigator.msSaveOrOpenBlob)
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename);
  else {
    // Other browsers
    const a = document.createElement('a'),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }, 0);
  }
};

export function isValidWithoutDashPassword(
  password /* :string */
) /* :boolean */ {
  let exp = /^.[^\-]+$$/;
  return exp.test(password);
}

export function isValidPassword(password /* :string */) /* :boolean */ {
  let exp = /^.{8,14}$/;
  return exp.test(password);
}
export const debounce = (fn, delay) => {
  let timer;

  return function () {
    const context = this;
    const args = arguments;

    clearTimeout(timer);

    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};

export const longDateFormat = (inputDate) => {
  return new Date(inputDate).getTime();
};

export const getByTestId = (wrapper, elementId) => {
  return wrapper.find({ 'data-testid': elementId });
};

export const checkEmptyValue = (value) => {
  return value === undefined || value === '' || value === null;
};
export const formatTextNewLine = (data = '') => {
  const newLine = data.replace(/(?:\r\n|\r|\n)/g, '<br>');
  // let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  // return newLine.replace(urlRegex, function (url) {
  //   return '<a target="_blank" href="' + url + '">' + url + '</a>';
  // });
  return newLine;
};

export const getVMBSubdomain = () => {
  const subDomainName = window.location.host.split('.')[0];
  return /-test/.test(subDomainName) ? 'vmb-test' : 'vmb';
};

export const isSpocOrCaRole = (userRole = '') => {
  return ['SPOC', 'CA'].indexOf(userRole) !== -1;
};

export const shouldNotDisplayValueForSpecificUser = (
  userRole = '',
  testValue = ''
) => {
  return isSpocOrCaRole(userRole) && /udf@singtel.com/.test(testValue);
};
