import styled from 'styled-components';
import Theme from '@/theme';
import { remCalc } from '@lux/helpers';

const clrs = Theme.colours;

export default {
  StyledContainer: styled.span`
    padding: ${remCalc(8)};
    flex: 0 0 auto;
    overflow: visible;
    text-align: center;
    cursor: pointer;
    display: inline-flex;
    outline: 0;
    position: relative;
    align-items: center;
    user-select: none;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
  `,
  StyledSpan: styled.span`
    width: 100%;
    display: flex;
    align-items: inherit;
    justify-content: inherit;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: ${clrs.grey_700};
    background-color: ${(props) =>
      props.disabled ? clrs.grey_50 : clrs.white};
    :hover {
      border-color: ${(props) =>
        props.disabled ? clrs.grey_700 : clrs.linkblue};
    }
  `,
  StyledInput: styled.input`
    top: 0;
    left: 0;
    width: 100%;
    cursor: inherit;
    height: 100%;
    margin: 0;
    opacity: 0;
    padding: 0;
    z-index: 1;
    position: absolute;
}`,
  StyledPath: styled.path`
    display: ${(props) => (props.checked ? 'block' : 'none')};
  `
};
