import * as types from '@/actionTypes/userRegistrationTypes';
import { REQUEST, SUCCESS, FAILURE, createAction } from '@/utils/reduxUtils';

export const searchUserInviteList = {
  request: (condition, paginator) =>
    createAction(types.getUserInviteList[REQUEST], { condition, paginator }),
  success: (data, pagination) =>
    createAction(types.getUserInviteList[SUCCESS], { data, pagination }),
  failure: (status) =>
    createAction(types.getUserInviteList[FAILURE], { status })
};
export const senduserInvitation = {
  request: (inviteDetail, currentUser) =>
    createAction(types.sendUserInvitation[REQUEST], {
      inviteDetail,
      currentUser
    })
};

export const changePendingInvite = (pendingStatus) =>
  createAction(types.changePendingInvite, { pendingStatus });

export const resendUserInviteEmail = {
  request: (userDetails) =>
    createAction(types.resendUserInviteEmail[REQUEST], { userDetails })
};

export const submitUserRegistrationForm = {
  request: (userDetails) =>
    createAction(types.submitUserRegistrationForm[REQUEST], { userDetails })
};

export const getApplicationSubsInfo = {
  request: (inviteUserId, userEmail) =>
    createAction(types.getApplicationSubsInfo[REQUEST], {
      inviteUserId,
      userEmail
    }),
  success: (data) =>
    createAction(types.getApplicationSubsInfo[SUCCESS], { data }),
  failure: (status) =>
    createAction(types.getApplicationSubsInfo[FAILURE], status)
};

export const rejectInvitation = {
  request: (uidObj, currentUserEmail) =>
    createAction(types.rejectUserInvitation[REQUEST], {
      uidObj,
      currentUserEmail
    })
};

export const approveInvitation = {
  request: (uidObj, currentUserEmail) =>
    createAction(types.approveUserInvitation[REQUEST], {
      uidObj,
      currentUserEmail
    })
};

export const changePendingStatus = (pendingStatus) =>
  createAction(types.changePendingStatus, { pendingStatus });

export const getInvitedUserInfo = {
  request: (emailInfo) =>
    createAction(types.getInvitedUserInfo[REQUEST], { emailInfo }),
  success: (data) => createAction(types.getInvitedUserInfo[SUCCESS], { data }),
  failure: (status) => createAction(types.getInvitedUserInfo[FAILURE], status)
};
