import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '@/actionTypes/userRegistrationTypes';
import { REQUEST } from '@/utils/reduxUtils';
import * as apis from '@/apis/userRegistrationApi';
import * as actions from '@/actions/UserRegistrationActions';
import * as msgs from '@/actions/SnackbarActions';
import * as modalActions from '@/actions/ResultModalActions';

import { responseCheck } from '@/utils/helpers/responseHelper';
import { navigate } from 'gatsby';

function* fetchUserInviteList({ condition, paginator }) {
  try {
    const result = yield call(
      apis.requestInvitedUserList,
      condition,
      paginator
    );
    yield call(responseCheck, result);
    yield put(
      actions.searchUserInviteList.success(result.data, result.pagination)
    );
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.searchUserInviteList.failure(error));
  }
}
function* sendUserInviteList({ inviteDetail, currentUser }) {
  try {
    yield put(msgs.clear());
    const result = yield call(
      apis.requestInviteUser,
      inviteDetail,
      currentUser
    );
    yield put(actions.changePendingInvite(false));
    yield call(responseCheck, result);
    yield put(
      modalActions.openModal(
        'Success',
        'Invitation sent successfully!',
        '/self-registrationMgmt'
      )
    );
  } catch (error) {
    yield put(msgs.danger(`${error?.message}`));
  }
}
function* resendUserInviteEmail({ userDetails }) {
  try {
    const result = yield call(apis.resendEmail, userDetails);
    yield call(responseCheck, result);
    yield put(msgs.success('Resend email - ' + result.status.message));
  } catch (error) {
    yield put(msgs.danger(`${error?.message}`));
  }
}
function* submitUserRegistrationForm({ userDetails }) {
  try {
    const result = yield call(apis.submitUserRegistrationForm, userDetails);
    yield put(actions.changePendingInvite(false));
    yield call(responseCheck, result);
    yield call(navigate, '/result', {
      state: {
        head: 'Submitted successfully',
        label:
          'Your form has been submitted.Please await approval from administrator.',
        redirectTo: '/login'
      }
    });
  } catch (error) {
    yield call(navigate, '/result', {
      state: {
        head: 'Failure',
        label: `User registration failed! ${error}`,
        redirectTo: '/login'
      }
    });
  }
}
function* fetchAppSubsInfo({ inviteUserId, userEmail }) {
  try {
    const result = yield call(
      apis.requestApplicationSubsInfo,
      inviteUserId,
      userEmail
    );
    yield call(responseCheck, result);
    yield put(actions.getApplicationSubsInfo.success(result.data));
  } catch (error) {
    yield put(actions.getApplicationSubsInfo.failure());
    yield put(
      modalActions.openModal(
        'Failure',
        `${error?.message}`,
        '/self-registrationMgmt'
      )
    );
  }
}
function* rejectInvitation({ uidObj, currentUserEmail }) {
  try {
    const result = yield call(apis.rejectInvitation, uidObj, currentUserEmail);
    yield put(actions.changePendingStatus(false));
    yield call(responseCheck, result);
    yield put(
      modalActions.openModal(
        'Successful',
        'User invitation rejected!',
        '/self-registrationMgmt'
      )
    );
  } catch (error) {
    yield put(
      modalActions.openModal(
        'Failure',
        `${error?.message}`,
        '/self-registrationMgmt'
      )
    );
  }
}
function* approveInvitation({ uidObj, currentUserEmail }) {
  try {
    const result = yield call(apis.approveInvitation, uidObj, currentUserEmail);
    yield put(actions.changePendingStatus(false));
    yield call(responseCheck, result);
    yield put(
      modalActions.openModal(
        'Successful',
        'User invitation approved!',
        '/self-registrationMgmt'
      )
    );
  } catch (error) {
    yield put(
      modalActions.openModal(
        'Failure',
        `${error?.message}`,
        '/self-registrationMgmt'
      )
    );
  }
}
function* getInvitedUserInfo({ emailInfo }) {
  try {
    const result = yield call(apis.getInvitedUserInfo, emailInfo);
    yield call(responseCheck, result);
    yield put(actions.getInvitedUserInfo.success(result.data));
  } catch (error) {
    yield put(actions.getInvitedUserInfo.failure());
    yield put(modalActions.openModal('Failure', `${error?.message}`, '/login'));
  }
}
export default function* userRegistrationSagas() {
  yield takeLatest(actionTypes.getUserInviteList[REQUEST], fetchUserInviteList);
  yield takeLatest(actionTypes.sendUserInvitation[REQUEST], sendUserInviteList);
  yield takeLatest(
    actionTypes.resendUserInviteEmail[REQUEST],
    resendUserInviteEmail
  );
  yield takeLatest(
    actionTypes.submitUserRegistrationForm[REQUEST],
    submitUserRegistrationForm
  );
  yield takeLatest(
    actionTypes.getApplicationSubsInfo[REQUEST],
    fetchAppSubsInfo
  );
  yield takeLatest(actionTypes.rejectUserInvitation[REQUEST], rejectInvitation);
  yield takeLatest(
    actionTypes.approveUserInvitation[REQUEST],
    approveInvitation
  );
  yield takeLatest(actionTypes.getInvitedUserInfo[REQUEST], getInvitedUserInfo);
}
