import { createRequestType } from '@/utils/reduxUtils';

export const requestUserLogin = createRequestType('LOGIN/REQUEST_USER_LOGIN');

export const requestMenuItems = createRequestType('LOGIN/REQUEST_MENU_ITEMS');

export const requestImpersonate = createRequestType(
  'LOGIN/REQUEST_IMPERSONNATE'
);

export const getContentData = createRequestType('LOGIN/GET_CONTENT_DATA');

export const getNotifications = createRequestType('LOGIN/GET_NOTIFICATIONS');

export const userLogout = createRequestType('LOGIN/USER_LOGOUT');

export const startLogin = 'LOGIN/START_LOGIN';

export const forgotPassword = createRequestType('LOGIN/FORGOT_PASSWORD');

export const setNewPassword = createRequestType('LOGIN/SET_NEW_PASSWORD');

export const changePassword = createRequestType('LOGIN/CHANGE_PASSWORD');

export const reportUserAccessApp = createRequestType(
  'LOGIN/REPORT_USER_ACCESS_APP'
);

export const clearLoginTrash = 'LOGIN/CLEAR_LOGIN_TRASH';

export const extendExpiryTime = 'LOGIN/EXTEND_EXPIRY_TIME';

export const changePending = 'LOGIN/CHANGE_PENDING';

export const setUserInfo = 'LOGIN/REQUEST_USER_INFO';
