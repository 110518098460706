import {
  REQUEST,
  SUCCESS,
  FAILURE,
  createAction
} from '../../utils/reduxUtils';

import * as actions from '../../actionTypes/emailTypes';

export const getTrackEmailList = {
  request: (search, paginator) =>
    createAction(actions.getTrackEmailList[REQUEST], { search, paginator }),
  success: (data, pagination) =>
    createAction(actions.getTrackEmailList[SUCCESS], { data, pagination }),
  failure: (status) => createAction(actions.getTrackEmailList[FAILURE], status)
};

export const resendEmail = {
  request: (recipientIdSet) =>
    createAction(actions.resendEmail[REQUEST], { recipientIdSet })
};

export const getTempEmailList = {
  request: () => createAction(actions.getTempEmailList[REQUEST]),
  success: (data) => createAction(actions.getTempEmailList[SUCCESS], { data }),
  failure: (status) => createAction(actions.getTempEmailList[FAILURE], status)
};

export const getTempEmail = {
  request: (templateName) =>
    createAction(actions.getTempEmail[REQUEST], { templateName }),
  success: (data) => createAction(actions.getTempEmail[SUCCESS], { data }),
  failure: (status) => createAction(actions.getTempEmail[FAILURE], status)
};

export const getVariables = {
  request: (templateName) =>
    createAction(actions.getVariables[REQUEST], { templateName }),
  success: (data) => createAction(actions.getVariables[SUCCESS], { data }),
  failure: (status) => createAction(actions.getVariables[FAILURE], status)
};

export const updateTempEmail = {
  request: (name, context, updateTime) =>
    createAction(actions.updateTempEmail[REQUEST], {
      name,
      context,
      updateTime
    })
};

export const transferProfile = {
  request: (giverAccountId, recipientAccountId) =>
    createAction(actions.transferProfile[REQUEST], {
      giverAccountId,
      recipientAccountId
    })
};

export const changePending = (pendingStatus) =>
  createAction(actions.changePending, { pendingStatus });
