import Fetch from '@/utils/fetch';
import getUrl from '@/utils/urlUtils';

export async function requestVerifyBrn(brn, accountNumber) {
  const urls = await getUrl();
  const postbody = { brn, accountNumber };

  return Fetch.post(urls.verifyBrn, postbody);
}

export async function requestVerifyBrnList(brn, brnList) {
  const urls = await getUrl();
  const postbody = { brn, brnList };

  return Fetch.post(urls.checkOtherBrn, postbody);
}

export async function requestCompanyRegistration(companyInfo) {
  const urls = await getUrl();

  return Fetch.post(urls.SMESelfRegistration, companyInfo);
}

export async function requestVerifyServiceNum(serviceInfo) {
  const urls = await getUrl();

  return Fetch.post(urls.verifyServiceNumber, serviceInfo);
}

export async function requestCESRegistration(userInfo) {
  const urls = await getUrl();

  return Fetch.post(urls.registerForCES, userInfo);
}
