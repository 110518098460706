export default {
  'BIZP-AUTH': {
    Address: '/api',
    Type: 'api',
    Path: '/v1/auth',
    URL: {
      login: 'login',
      getToken: 'getToken',
      impersonate: 'impersonateUser',
      resetPassword: 'resetPassword',
      setNewPassword: 'forgotPasswordReset',
      forgotPassword: 'forgotPassword',
      forgotPasswordTpi: 'forgotPasswordTpi',
      changePassword: 'changePassword',
      getPermission: 'application/authorization',
      logout: 'logout'
    }
  },
  'BIZP-CONTENT': {
    Address: '/api',
    Type: 'api',
    Path: '/v1/content',
    URL: {
      searchContent: 'manage/search',
      deleteContent: 'manage/delete',
      getContentDetail: 'manage',
      getContentImage: 'manage/image/',
      createContent: 'manage/add',
      assignContent: 'assign/',
      getPageContent: '',
      updateContent: 'manage/update'
    }
  },
  'BIZP-USER': {
    Address: '/api',
    Type: 'api',
    Path: '/v1/user',
    URL: {
      editUser: 'update',
      getUsers: 'getUsers',
      getUserDetail: 'getUserDetails/',
      createUser: 'create',
      deleteUser: 'delete',
      confirmUser: 'confirmUser',
      getUserApplications: 'getUserApplications/',
      provisionList: 'userProvisionSummary',
      getOtherBrns: 'getOtherBrns',
      getOtherBrnsForSpoc: 'getOtherBrnsForSpoc',
      updateUserStatus: 'updateStatus',
      uploadUsers: 'uploadUser',
      uploadUserErrorList: 'getUploadUserErrorList',
      downloadUsers: 'downloadUsers/',
      downloadErrorFile: 'downloadErrorFile/',
      getUserAppDetails: 'getUserAppDetails/',
      exportUserList: 'export',
      deletUserSpoc: 'deletUserSpoc',
      unlockUser: 'unlockUser'
    }
  },
  'BIZP-USER-ACCOUNT': {
    Address: '/api',
    Type: 'api',
    Path: '/v1/userAccount',
    URL: {
      getAccount: '',
      updateAccount: 'update'
    }
  },
  'BIZP-COMPANY': {
    Address: '/api',
    Type: 'api',
    Path: '/v1/company',
    URL: {
      getCompanyList: '',
      createCompany: 'create',
      updateCompany: 'edit/update',
      deleteCompany: 'delete',
      suspendCompany: 'suspend',
      activateCompany: 'activate',
      getCompanyDetails: 'view',
      getCompanyApp: 'companyhaveapp/',
      getBRNFromAcctO: 'accto',
      getEBAPackageList: 'packages/eba',
      getEBOPackageList: 'packages/ebo',
      getDMBDPackageList: 'packages/dmbd',
      getPackageUserList: 'usedapp',
      exportCompanyList: 'export',
      getVmbTransactionId: 'fetchvmbTransactionId'
    }
  },
  'BIZP-APPLICATION': {
    Address: '/api',
    Type: 'api',
    Path: '/v1/application',
    URL: {
      getAppList: '',
      getCommonAppList: 'search/getApplications'
    }
  },
  'BIZP-MESSAGE': {
    Address: '/api',
    Type: 'api',
    Path: '/v1/message',
    URL: {
      getMessageList: '',
      createMessage: 'create',
      editMessage: 'edit',
      getNotification: 'notification',
      exportMessage: 'export',
      deleteMessageList: 'delete'
    }
  },
  'BIZP-SOCKET': {
    Address: '/socket',
    Type: 'ws',
    Path: '/websocket/v1',
    URL: {
      messageBus: 'ws/DataChannel'
    }
  },
  'BIZP-REPORT': {
    Address: '/api',
    Type: 'api',
    Path: '/v1/reports',
    URL: {
      userApplicationAccess: 'userApplicationAccess',
      getUserAppReport: 'userAppReport',
      getPegasusReport: 'pegasusReport/',
      getLoginReport: 'userLoginReport',
      getUserActReport: 'userActivityReport',
      getRequestAcctReport: 'requestAccountReport',
      exportUserAppReport: 'export/userAppReport',
      exportPegasusReport: 'export/pegasusReport',
      exportLoginReport: 'export/userLoginReport',
      exportUserActReport: 'export/userActivityReport',
      exportRequestAcctReport: 'export/requestAccountReport'
    }
  },
  'BIZP-EMAIL': {
    Address: '/api',
    Type: 'api',
    Path: '/v1/',
    URL: {
      getTrackEmailList: 'trackemail/getList',
      resendEmail: 'trackemail/resendEmails',
      getTempEmailList: 'emailTemplate/allTemplates',
      getTempEmail: 'emailTemplate/getTemplate',
      getVariables: 'emailTemplate/getVariables',
      updateTempEmail: 'emailTemplate/update',
      transferProfile: 'access/transfer'
    }
  },
  'BIZP-REGISTRATION': {
    Address: '/api',
    Type: 'api',
    Path: '/v1/registration',
    URL: {
      verifyBrn: 'verifyBrn',
      SMESelfRegistration: 'SMESelfRegistration',
      verifyServiceNumber: 'verifyServiceNumber',
      checkOtherBrn: 'checkOtherBrn',
      registerForCES: 'registerForCES',
      sendEmailNotification: 'sendEmailNotification',
      saveSelfSpocTransferReq: 'saveSelfSpocTransferReq'
    }
  },
  'BIZP-USER-SELF-REGISTRATION': {
    Address: '/api',
    Type: 'api',
    Path: '/v1/',
    URL: {
      getUserInviteList: 'manageUserSelfRegistration',
      sendUserInvitation: 'UserSelfRegistration/inviteUser',
      resendEmailInvite: 'UserSelfRegistration/ResendEmail',
      submitUserRegistrationForm: 'UserSelfRegistration/updateInviteUser',
      getApplicationSubsInfo: 'UserSelfRegistration/editInviteUser',
      rejectInvitation: 'UserSelfRegistration/rejectInvitedUser',
      approveInvitation: 'UserSelfRegistration/approveInvitedUser',
      getInvitedUserDetails: 'UserSelfRegistration/getInviteUserData'
    }
  },
  'BIZP-SELF-SPOC-TRANSFER': {
    Address: '/api',
    Type: 'api',
    Path: '/v1/selfSpocTransfer',
    URL: {
      sendEmailNotification: '/request/sendNotification',
      saveSelfSpocTransferReq: '/request/create',
      getSelfSpocTransferReq: 'requests',
      getRequestSpocUserDetail: 'request',
      rejectSpocRequest: '/request/reject',
      approveSpocRequest: '/request/approve'
    }
  }
};
