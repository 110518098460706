import Fetch from '@/utils/fetch';
import getUrl from '@/utils/urlUtils';

export async function requestApplist() {
  const urls = await getUrl();
  return Fetch.get(urls.getCommonAppList);
}

export async function requestOtherBrnList(brn) {
  brn = btoa(brn);
  const urls = await getUrl();
  return Fetch.get(urls.getOtherBrns + `/${brn}`);
}

export async function requestOtherBrnListForSpoc() {
  const urls = await getUrl();
  return Fetch.get(urls.getOtherBrnsForSpoc);
}
