import React from 'react';
import styled from 'styled-components';
import { remCalc } from '@lux/helpers';
import { Link } from 'gatsby';
import { useSelector } from 'react-redux';
import { SYSTEM_PATH_PREFIX } from '@/utils/constants';
import { getLoginPath } from '@/utils/helpers/login';

const StyledLogo = styled.img`
  height: ${remCalc(40)};
`;

const SingtelLogo = () => {
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  return (
    <Link to={isLoggedIn ? '/home/' : getLoginPath()}>
      <StyledLogo
        src={SYSTEM_PATH_PREFIX + '/assets/images/logo.png'}
        alt="Singtel Logo"
      />
    </Link>
  );
};

export default SingtelLogo;
