import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '@/actionTypes/accountTypes';
import { REQUEST } from '@/utils/reduxUtils';
import * as apis from '@/apis/accountApi';
import * as userInfoApis from '@/apis/accountApi';
import * as actions from '@/actions/AccountActions';
import * as loginActions from '@/actions/LoginActions';
import * as modalActions from '@/actions/ResultModalActions';
import { responseCheck } from '@/utils/helpers/responseHelper';

function* fetchAccount() {
  try {
    const result = yield call(apis.requestAccount);
    yield call(responseCheck, result);
    yield put(actions.getAccount.success(result.data));
  } catch (error) {
    yield put(actions.getAccount.failure(error));
  }
}

function* fetchupdateAccount({ accountDetails }) {
  try {
    const result = yield call(apis.requestUpdateAccount, accountDetails);
    yield call(responseCheck, result);
    yield put(actions.changePending(false));
    let userInfo = { data: {} };
    userInfo = yield call(userInfoApis.requestAccount);
    yield put(loginActions.setUserInfo(userInfo.data));
    yield put(
      modalActions.openModal(
        'Success',
        'Account updated successfully!',
        '/account'
      )
    );
  } catch (error) {
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal('Failure', `${error?.message}`, '/account')
    );
  }
}

export default function* loginSagas() {
  yield takeLatest(actionTypes.getAccount[REQUEST], fetchAccount);
  yield takeLatest(actionTypes.updateAccount[REQUEST], fetchupdateAccount);
}
