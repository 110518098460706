import * as types from '@/actionTypes/registrationTypes';
import { REQUEST, SUCCESS, FAILURE, createReducer } from '@/utils/reduxUtils';

export const initialState = {
  applicationList: [],
  isServiceNumValid: false,
  isBrnListValid: false,
  verifiedBrnList: [],
  isPending: false
};

export default createReducer(initialState, {
  //verify brn reducers
  [types.verifyBrn[REQUEST]](state) {
    return {
      ...state,
      applicationList: [],
      isPending: true
    };
  },
  [types.verifyBrn[SUCCESS]](state, action) {
    return {
      ...state,
      applicationList: action.data,
      isPending: false
    };
  },
  [types.verifyBrn[FAILURE]](state) {
    return {
      ...state,
      applicationList: [],
      isPending: false
    };
  },
  //verify brn List reducers
  [types.verifyBrnList[REQUEST]](state) {
    return {
      ...state,
      isBrnListValid: false,
      verifiedBrnList: [],
      isPending: true
    };
  },
  [types.verifyBrnList[SUCCESS]](state, action) {
    return {
      ...state,
      isBrnListValid: true,
      verifiedBrnList: action.data,
      isPending: false
    };
  },
  [types.verifyBrnList[FAILURE]](state) {
    return {
      ...state,
      isBrnListValid: false,
      verifiedBrnList: [],
      isPending: false
    };
  },
  [types.companyRegistration[REQUEST]](state) {
    return {
      ...state,
      isPending: true
    };
  },
  [types.verifyServiceNum[REQUEST]](state) {
    return {
      ...state,
      isServiceNumValid: false,
      isPending: true
    };
  },
  [types.verifyServiceNum[SUCCESS]](state) {
    return {
      ...state,
      isServiceNumValid: true,
      isPending: false
    };
  },
  [types.verifyServiceNum[FAILURE]](state) {
    return {
      ...state,
      isServiceNumValid: false,
      isPending: false
    };
  },
  [types.registerCESCustomer[REQUEST]](state) {
    return {
      ...state,
      isPending: true
    };
  },
  [types.changePending](state, action) {
    return {
      ...state,
      isPending: action.pendingStatus
    };
  }
});
