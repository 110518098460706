import * as loginActions from '@/actions/LoginActions';
import createStore from '@/store';
import { getLoginPath } from '@/utils/helpers/login';

/**
 * check whether api responce is successful
 *
 * @param {*} data only suitable for below responce format
 * {
 * "status": {
 *   "error": "",
 *   "code": "000000",
 *   "message": "success"
 * },
 * "data": ""
 * }
 * @returns true
 */
export const responseCheck = (data) => {
  let httpResponseHeader = sessionStorage.httpResponse;
  let commonError = '';
  // to check if response is in correct format
  const checkResStatus = data instanceof Object || Array.isArray(data);
  if (!checkResStatus) {
    createStore.store.dispatch(loginActions.userLogout.request());
  }
  if (checkResStatus) {
    commonError =
      'Unable to get response at this time due to System unavailability.';
  }
  const pegasusError = `Sorry, An unexpected error occurred while processing your request, Code ${
    httpResponseHeader && httpResponseHeader
  }- ${data?.status?.code}`;
  if (data?.status?.code === '000000') {
    return true;
  } else if (data?.status?.code === '504000') {
    throw new Error(pegasusError);
  } else {
    const error = data?.error || data?.status?.error;
    const authErrorList = [
      'invalid_token',
      'Invalid Session',
      'authentication_required'
    ];
    //dispatch clearLoginTrash action when token is invalid or session is invalid(user login from other browser) or authentication is required
    if (authErrorList.indexOf(error) > -1) {
      const messgae =
        error === 'invalid_token' || error === 'authentication_required'
          ? data.message
          : data.status.message;
      //get impersonation status
      const store = createStore.store.getState();
      const isImpersonation = store.login?.isImpersonation;
      //dispatch logout action
      createStore.store.dispatch(
        loginActions.userLogout.request(
          isImpersonation,
          getLoginPath(),
          messgae
        )
      );
    }
    // throw new Error(data?.status?.message || data?.message || commonError);
    if (!checkResStatus) {
      throw data?.status?.message || data?.message || commonError;
    } else {
      throw new Error(data?.status?.message || data?.message || commonError);
    }
  }
};
