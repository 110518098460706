import './fonts.css';
import { Theme } from '@lux/components';

const SystemFonts =
  '-apple-system, BlinkMacSystemFont,Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol';

const fonts = {
  ...Theme.fonts,
  heading100: `Lato Hairline, ${SystemFonts}`,
  heading300: `Lato Light, ${SystemFonts}`,
  heading500: `Lato Medium, ${SystemFonts}`,
  primary: `AvenirLTStd-Book, Avenir, ${SystemFonts}`,
  secondary: `AvenirLTStd-Medium, Avenir, ${SystemFonts}`,
  tertiary: `AvenirLTStd-Heavy, Avenir, ${SystemFonts}`
};

export default fonts;
