import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import loginReducer from './LoginReducer';
import accountReducer from './AccountReducer';
import userReducer from './UserReducer';
import companyReducer from './CompanyReducer';
import messageReducer from './MessageReducer';
import selectReducer from './SelectReducer';
import snackbarReducer from './SnackbarReducer';
import reportReducer from './ReportReducer';
import contentReducer from './ContentReducer';
import emailReducer from './EmailReducer';
import registrationReducer from './RegistrationReducer';
import userRegistration from './UserRegistrationReducer';
import resultModal from './ResultModalReducer';

//store login user information in localstorage to persist user login status.
const loginPersistConfig = {
  key: 'loginUser',
  storage: storage,
  //exclude isPending here, otherwise if user closed the window before api returns,
  //isPending status will still be true since it was stored in localStorage
  blacklist: ['isPending']
};

const rootReducer = combineReducers({
  login: persistReducer(loginPersistConfig, loginReducer),
  account: accountReducer,
  user: userReducer,
  company: companyReducer,
  message: messageReducer,
  select: selectReducer,
  snackbar: snackbarReducer,
  report: reportReducer,
  content: contentReducer,
  email: emailReducer,
  registration: registrationReducer,
  userRegistration,
  resultModal
});

export default rootReducer;
