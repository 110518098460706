import { navigate } from 'gatsby';
import * as types from '@/actionTypes/companyTypes';
import { REQUEST } from '@/utils/reduxUtils';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as apis from '@/apis/companyApi';
import * as actions from '@/actions/CompanyActions';
import { OnDownLoadCSV } from '@/utils/helpers';
import { responseCheck } from '@/utils/helpers/responseHelper';
import * as msgs from '@/actions/SnackbarActions';
import * as modalActions from '@/actions/ResultModalActions';

function* fetchCompanyList({ search, paginator }) {
  try {
    const result = yield call(apis.requestGetCompanyList, search, paginator);
    yield call(responseCheck, result);
    yield put(actions.getCompanyList.success(result.data, result.pagination));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.getCompanyList.failure(error));
  }
}

function* fetchCompanyDetails({ profileId }) {
  try {
    const result = yield call(apis.requestGetCompanyDetails, profileId);
    yield call(responseCheck, result);
    yield put(actions.getCompanyDetails.success(result.data));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.getCompanyDetails.failure(error));
  }
}

function* fetchUpdateCompany({ companyDetails }) {
  try {
    const result = yield call(apis.requestUpdateCompany, companyDetails);
    yield call(responseCheck, result);
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal(
        'Success',
        'Updated company successfully!',
        '/company'
      )
    );
  } catch (error) {
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal('Failure ', `${error?.message}`, '/company')
    );
  }
}

function* fetchAppList({ msbs, segment, mode }) {
  try {
    const result = yield call(apis.requestGetAppList, msbs, segment);
    yield call(responseCheck, result);
    let vmbTransactionId = { data: '' };

    if (mode === 'edit') {
      vmbTransactionId = yield call(apis.requestVmbTransactionId);
      call(responseCheck, vmbTransactionId);
    }

    yield put(
      actions.getAppList.success({
        result: result.data,
        vmbTransactionId: vmbTransactionId.data
      })
    );
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.getAppList.failure(error));
  }
}

function* fetchBRNFromAcctO({ brn }) {
  try {
    const result = yield call(apis.requestGetBRNFromAcctO, brn);
    yield call(responseCheck, result);
    yield put(actions.getBRNFromAcctO.success(result.data));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.getBRNFromAcctO.failure(error));
  }
}

function* fetchCreateCompany({ companyDetails }) {
  try {
    const result = yield call(apis.requestCreateCompany, companyDetails);
    yield call(responseCheck, result);
    yield put(actions.changePending(false));
    yield call(navigate, '/user/create/details', {
      state: {
        company: {
          brn: companyDetails.brn,
          companyName: companyDetails.companyName,
          segment: companyDetails.segment,
          profileId: result.data.profileId
        },
        disableRole: true,
        role: 'SPOC'
      }
    });
  } catch (error) {
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal('Failure', `${error?.message}`, '/company')
    );
  }
}

function* fetchDeleteCompany({ companyList }) {
  try {
    const result = yield call(apis.requestDeleteCompany, companyList);
    yield call(responseCheck, result);
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal(
        'Success',
        'Company deleted successfully!',
        '/company'
      )
    );
  } catch (error) {
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal('Failure', `${error?.message}`, '/company')
    );
  }
}

function* fetchSuspendCompany({ companyId }) {
  try {
    const result = yield call(apis.requestSuspendCompany, companyId);
    yield call(responseCheck, result);
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal('Success', result.status.message, '/company')
    );
  } catch (error) {
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal('Failure', `${error?.message}`, '/company')
    );
  }
}

function* fetchActivateCompany({ companyId }) {
  try {
    const result = yield call(apis.requestActivateCompany, companyId);
    yield call(responseCheck, result);
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal('Success', result.status.message, '/company')
    );
  } catch (error) {
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal('Failure', `${error?.message}`, '/company')
    );
  }
}

function* fetchPackageUserList({ cahpId }) {
  try {
    const result = yield call(apis.requestGetPackageUserList, cahpId);
    yield call(responseCheck, result);
    yield put(actions.getPackageUserList.success(result.data));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.getPackageUserList.failure(error));
  }
}

function* fetchEBAPackageList() {
  try {
    const result = yield call(apis.requestGetEBAPackageList);
    yield call(responseCheck, result);
    yield put(actions.getEBAPackageList.success(result.data));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.getEBAPackageList.failure(error));
  }
}

function* fetchEBOPackageList() {
  try {
    const result = yield call(apis.requestGetEBOPackageList);
    yield call(responseCheck, result);
    yield put(actions.getEBOPackageList.success(result.data));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.getEBOPackageList.failure(error));
  }
}

function* fetchDMBDPackageList() {
  try {
    const result = yield call(apis.requestGetDMBDPackageList);
    yield call(responseCheck, result);
    yield put(actions.getDMBDPackageList.success(result.data));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.getDMBDPackageList.failure(error));
  }
}

function* fetchExportCompanyList({ search, paginator }) {
  try {
    const result = yield call(apis.requestExportCompanyList, search, paginator);
    yield put(actions.changePending(false));
    yield call(OnDownLoadCSV, result, 'CompanyList.csv');
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.exportCompanyList.failure(error));
  }
}

export default function* companySagas() {
  yield takeLatest(types.getCompanyList[REQUEST], fetchCompanyList);
  yield takeLatest(types.getCompanyDetails[REQUEST], fetchCompanyDetails);
  yield takeLatest(types.updateCompany[REQUEST], fetchUpdateCompany);
  yield takeLatest(types.getAppList[REQUEST], fetchAppList);
  yield takeLatest(types.getBRNFromAcctO[REQUEST], fetchBRNFromAcctO);
  yield takeLatest(types.createCompany[REQUEST], fetchCreateCompany);
  yield takeLatest(types.deleteCompany[REQUEST], fetchDeleteCompany);
  yield takeLatest(types.suspendCompany[REQUEST], fetchSuspendCompany);
  yield takeLatest(types.activateCompany[REQUEST], fetchActivateCompany);
  yield takeLatest(types.getPackageUserList[REQUEST], fetchPackageUserList);
  yield takeLatest(types.getEBAPackageList[REQUEST], fetchEBAPackageList);
  yield takeLatest(types.getEBOPackageList[REQUEST], fetchEBOPackageList);
  yield takeLatest(types.getDMBDPackageList[REQUEST], fetchDMBDPackageList);
  yield takeLatest(types.exportCompanyList[REQUEST], fetchExportCompanyList);
}
