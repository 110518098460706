import styled from 'styled-components';

export const Container = styled.div`
  ${({ bottom }) =>
    bottom &&
    ` position: absolute;
      bottom: 0;
      height: 100px;
      width: 100%;
      background-color: #ffffff;
    `}
`;
