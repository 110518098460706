import * as types from '@/actionTypes/reportTypes';
import { REQUEST, SUCCESS, FAILURE, createReducer } from '@/utils/reduxUtils';

export const initialState = {
  pagination: {},
  isLoadingReport: false,
  isPending: false,
  data: []
};

export default createReducer(initialState, {
  //Get Report List reducers
  [types.getReportData[REQUEST]](state) {
    return {
      ...state,
      isLoadingReport: true
    };
  },
  [types.getReportData[SUCCESS]](state, action) {
    return {
      ...state,
      data: action.data,
      pagination: action.pagination,
      isLoadingReport: false
    };
  },
  [types.getReportData[FAILURE]](state) {
    return {
      ...state,
      data: [],
      pagination: {},
      isLoadingReport: false
    };
  },
  [types.exportReportData[REQUEST]](state) {
    return {
      ...state,
      isPending: true
    };
  },
  //change pending status
  [types.changePending](state, action) {
    return {
      ...state,
      isPending: action.pendingStatus
    };
  }
});
