import { createRequestType } from '@/utils/reduxUtils';
export const getMessageLocationList = createRequestType(
  'MESSAGE/GET_MESSAGE_LOCATION_LIST'
);
export const editMessage = createRequestType('MESSAGE/EDIT_MESSAGE');
export const createMessage = createRequestType('MESSAGE/CREATE_MESSAGE');
export const exportMessage = createRequestType('MESSAGE/EXPORT_MESSAGE');
export const getMessageList = createRequestType('MESSAGE/GET_MESSAGE_LIST');
export const deleteMessageList = createRequestType(
  'MESSAGE/DELETE_MESSAGE_LIST'
);
export const changePending = 'MESSAGE/CHANGE_PENDING';
