import getUrl from '@/utils/urlUtils';
import Fetch from '@/utils/fetch';

export async function requestInvitedUserList(condition, paginator) {
  const urls = await getUrl();
  const status = condition.status?.value;
  const postbody = {
    ...condition,
    status,
    paginator
  };
  return Fetch.post(urls.getUserInviteList, postbody);
}
export async function requestInviteUser(inviteDetail, currentUser) {
  const urls = await getUrl();
  const { userSelfRegistrationApplications = [], ...others } =
    inviteDetail || {};
  let appList = [],
    pkgObj = {};
  userSelfRegistrationApplications.forEach((element) => {
    if (element.subscribed) {
      appList.push(element.id);
      if (element.packageId) pkgObj[element.id] = element.packageId;
    }
  });
  const postBody = {
    profileId: currentUser?.company?.profileId,
    userFirstName: currentUser?.firstName,
    userLastName: currentUser?.lastName,
    userEmial: currentUser?.email,
    ...others,
    userSelfRegistrationApplications: appList,
    userApplicationPackages: pkgObj
  };
  return Fetch.post(urls.sendUserInvitation, postBody);
}
export async function resendEmail(userDetail) {
  const urls = await getUrl();
  const { uiId, emailId, userEmail, currentUserEmail } = userDetail || {};
  const postBody = {
    uiId: uiId,
    email: userEmail,
    emailID: emailId,
    userEmail: currentUserEmail
  };
  return Fetch.post(urls.resendEmailInvite, postBody);
}
export async function requestApplicationSubsInfo(inviteUserId, userEmail) {
  const urls = await getUrl();
  const postBody = {
    inviteUserId,
    userEmail
  };
  return Fetch.post(urls.getApplicationSubsInfo, postBody);
}

export async function rejectInvitation(uidObj, currentUserEmail) {
  const urls = await getUrl();

  const postBody = {
    uidMap: uidObj,
    userEmail: currentUserEmail
  };
  return Fetch.post(urls.rejectInvitation, postBody);
}
export async function approveInvitation(uidObj, currentUserEmail) {
  const urls = await getUrl();

  const postBody = {
    uidMap: uidObj,
    userEmail: currentUserEmail
  };

  return Fetch.post(urls.approveInvitation, postBody);
}

export async function submitUserRegistrationForm(userDetail) {
  const urls = await getUrl();
  return Fetch.post(urls.submitUserRegistrationForm, userDetail);
}

export async function getInvitedUserInfo(emailInfo) {
  const urls = await getUrl();
  const postBody = { ...emailInfo };
  return Fetch.post(urls.getInvitedUserDetails, postBody);
}
