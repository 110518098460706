import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Column, Row } from '@lux/components';

import { Button } from '@/components/Lux';
import Content from '@/components/Content';
import LSC from '@/theme/customLuxHeadingStyle';

import * as actions from '@/actions/ResultModalActions';
const ModalContainer = styled(Modal)`
  text-align: center;
`;

const selector = ({ resultModal }) => ({
  open: resultModal.open,
  modalTitle: resultModal.modalTitle,
  modalMessage: resultModal.modalMessage,
  redirectTo: resultModal.redirectTo
});

const ResultModal = () => {
  const dispatch = useDispatch();
  const { open, modalTitle, modalMessage, redirectTo } = useSelector(selector);
  const message = React.useMemo(() => {
    return modalMessage !== 'loginNotification' ? (
      <Content>{modalMessage}</Content>
    ) : (
      <>
        <div>
          <b>
            Singtel Business Portal will be discontinued after 21-Feb-24. We
            have migrated your BizPortal accounts to the new portal.
          </b>
        </div>
        <div>
          To access your <b>bills and billing reports</b>{' '}
          <i>
            (View My Bill, eBill Analyser, eBill Organiser, Download Bill Data)
          </i>
          , please log in to <b>Empower Portal</b> at{' '}
          <b>
            <a href="empower.singtel.com">empower.singtel.com</a>
          </b>{' '}
          with your BizPortal login email.
        </div>
        <div>
          <ul>
            <li>
              Click Access Empower now &gt; Forgot Password if you have
              forgotten your password
            </li>
            <li>
              If your account has not been created, click <i>Create Account</i>
            </li>
          </ul>
        </div>
        <div>
          For purchasing Singtel services via the Singtel eSHOP, please access
          via the existing links in your BizPortal homepage.
        </div>
      </>
    );
  }, [modalMessage]);

  return (
    <ModalContainer open={open} disableClose={true}>
      <LSC.Heading2 level={2}>{modalTitle}</LSC.Heading2>
      {/* <Content>{modalMessage}</Content> */}
      {/* <Heading>Abd</Heading> */}
      {message}
      <Row center="sm">
        <Column sm={6} md={4} lg={4}>
          <Button
            onClick={() => {
              dispatch(actions.closeModal());
              navigate(redirectTo);
            }}
          >
            OK
          </Button>
        </Column>
      </Row>
    </ModalContainer>
  );
};

// const displayMessage = React.useMemo(() => {
//       return typeof modalMessage === "string" ? <Content>{modalMessage}</Content> : {modalMessage}
//     }, [modalMessage]);

// const ResultModal = () => {
//   const dispatch = useDispatch();
//   const { open, modalTitle, modalMessage, redirectTo } = useSelector(selector);
//   const displayMessage = React.useMemo(() => {
//     return typeof modalMessage === "string" ? <Content>{modalMessage}</Content> : {modalMessage}
//   }, [modalMessage]);

//   return (
//     <ModalContainer open={open} disableClose={true}>
//       <LSC.Heading2 level={2}>{modalTitle}</LSC.Heading2>
//       {displayMessage}
//       <Row center="sm">
//         <Column sm={6} md={4} lg={4}>
//           <Button
//             onClick={() => {
//               dispatch(actions.closeModal());
//               navigate(redirectTo);
//             }}
//           >
//             OK
//           </Button>
//         </Column>
//       </Row>
//     </ModalContainer>
//   );
// };

export default ResultModal;
