import moment from 'moment';
import createStore from '@/store';

/**
 * to get the token expiry time
 * @param {*} [loginTime] moment object/valid time string
 * @returns new expiryTime
 */
const getExpiryTime = (loginTime) => {
  const maxExpriyTime = moment(loginTime).add(115, 'm');
  const newExpiryTime = moment().add(55, 'm');
  return moment.min(newExpiryTime, maxExpriyTime);
};

/**
 * to check if the token is expired
 * @returns true if token is expired
 */
const isTokenExpired = () => {
  const store = createStore.store.getState();
  const expiryTime = store.login?.expiryTime;
  const isExpired = moment().isAfter(expiryTime);
  return isExpired;
};

export { getExpiryTime, isTokenExpired };
