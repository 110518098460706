import styled from 'styled-components';
import { Theme, Grid } from '@lux/components';
import { remCalc } from '@lux/helpers';

export default {
  Grid: styled(Grid)`
    font-family: ${Theme.fonts.families.AvenirBook};
    padding: ${remCalc(25)} 0;
  `
};
