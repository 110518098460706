import React from 'react';
import * as PropTypes from 'prop-types';
import SC from './styles';

const Content = (props) => <SC.Grid>{props.children}</SC.Grid>;

Content.defaultProps = {
  children: ''
};

Content.propTypes = {
  /** Children inside component */
  children: PropTypes.any
};

export default Content;
