import styled from 'styled-components';
import { Heading } from '@lux/components';
import Theme from '@/theme';
import { remCalc } from '@lux/helpers';

export default {
  Heading3: styled(Heading)`
    font-family: ${Theme.fonts.heading100};
    font-weight: bold;
    letter-spacing: ${remCalc(1.2)};
  `,
  Heading2: styled(Heading)`
    font-family: ${Theme.fonts.heading300};
    font-weight: bold;
    letter-spacing: ${remCalc(2)};
  `,
  Heading1: styled(Heading)`
    font-family: ${Theme.fonts.heading100};
    font-weight: bold;
    letter-spacing: ${remCalc(2)};
  `
};
