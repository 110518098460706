import * as types from '@/actionTypes/messageTypes';
import { REQUEST, SUCCESS, FAILURE, createReducer } from '@/utils/reduxUtils';

export const initialState = {
  messageList: [],
  pagination: {},
  isFetching: false,
  isPending: false,
  isExportPending: false
};

export default createReducer(initialState, {
  [types.editMessage[REQUEST]](state) {
    return {
      ...state,
      isFetching: true
    };
  },
  [types.editMessage[SUCCESS]](state) {
    return {
      ...state,
      isFetching: false
    };
  },
  [types.editMessage[FAILURE]](state) {
    return {
      ...state,
      isFetching: false
    };
  },
  [types.createMessage[REQUEST]](state) {
    return {
      ...state,
      isFetching: true
    };
  },
  [types.createMessage[SUCCESS]](state) {
    return {
      ...state,
      isFetching: false
    };
  },
  [types.createMessage[FAILURE]](state) {
    return {
      ...state,
      isFetching: false
    };
  },
  [types.exportMessage[REQUEST]](state) {
    return {
      ...state,
      isExportPending: true
    };
  },
  [types.exportMessage[SUCCESS]](state) {
    return {
      ...state,
      isExportPending: false
    };
  },
  [types.exportMessage[FAILURE]](state) {
    return {
      ...state,
      isExportPending: false
    };
  },
  [types.getMessageList[REQUEST]](state) {
    return {
      ...state,
      isFetching: true
    };
  },
  [types.getMessageList[SUCCESS]](state, action) {
    return {
      ...state,
      messageList: action.data,
      pagination: action.pagination,
      isFetching: false
    };
  },
  [types.getMessageList[FAILURE]](state) {
    return {
      ...state,
      isFetching: false
    };
  },
  [types.deleteMessageList[REQUEST]](state) {
    return {
      ...state,
      isPending: true
    };
  }, //change pending reducer
  [types.changePending](state, action) {
    return {
      ...state,
      isPending: action.pendingStatus
    };
  }
});
