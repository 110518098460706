import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Spinner } from '@lux/components';

const CusSpinner = (props) => {
  const { fullscreen = false, show = false, ...others } = props;
  return show ? (
    <StyledContainer className={props.className} fullscreen={fullscreen}>
      <Spinner {...others} />
    </StyledContainer>
  ) : null;
};

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => {
    return props.fullscreen
      ? `
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10001;
  `
      : `margin: 1.45rem 0;`;
  }}
`;

CusSpinner.propTypes = {
  fullscreen: PropTypes.bool,
  show: PropTypes.bool,
  className: PropTypes.string
};

export default CusSpinner;
