import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@lux/components';
import HorizontalRule from '@/components/HorizontalRule';
import LSC from '@/theme/customLuxHeadingStyle';

const Title = (props) => {
  const { title = '', level = 1, single = false, double = false } = props;

  const titleByLevel = () => {
    const displayTitle = title ? title : props.children;
    switch (level) {
      case 2:
        return <LSC.Heading2 level={level}>{displayTitle}</LSC.Heading2>;
      case 3:
        return <LSC.Heading3 level={level}>{displayTitle}</LSC.Heading3>;
      case 1:
      case 4:
        return <LSC.Heading1 level={level}>{displayTitle}</LSC.Heading1>;
      default:
        return <Heading level={level}>{displayTitle}</Heading>;
    }
  };

  return (
    <Fragment>
      {titleByLevel()}
      {single || double ? (
        single ? (
          <HorizontalRule />
        ) : (
          <HorizontalRule double />
        )
      ) : null}
    </Fragment>
  );
};

Title.propTypes = {
  /** Children inside component */
  children: PropTypes.any,
  /** title label */
  title: PropTypes.string,
  /** title level */
  level: PropTypes.number,
  /** single line under title */
  single: PropTypes.bool,
  /** double line under title */
  double: PropTypes.bool
};

export default Title;
