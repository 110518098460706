import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '@/actionTypes/contentTypes';
import { REQUEST } from '@/utils/reduxUtils';
import * as apis from '@/apis/contentApi';
import * as actions from '@/actions/ContentActions';
import * as msgs from '@/actions/SnackbarActions';
import * as modalActions from '@/actions/ResultModalActions';
import { responseCheck } from '@/utils/helpers/responseHelper';

function* fetchContentList({ condition, paginator }) {
  try {
    const result = yield call(apis.requestContentList, condition, paginator);
    yield call(responseCheck, result);
    yield put(actions.getContentList.success(result.data, result.pagination));
  } catch (error) {
    yield put(msgs.danger(`${error?.message}`));
    yield put(actions.getContentList.failure(error));
  }
}

function* fetchContentDetail({ contentId }) {
  try {
    const result = yield call(apis.requestContentDetail, contentId);
    yield call(responseCheck, result);
    yield put(actions.getContentDetail.success(result.data));
  } catch (error) {
    yield put(msgs.danger(`${error?.message}`));
    yield put(actions.getContentDetail.failure(error));
  }
}

function* fetchCreateContent({ content }) {
  try {
    yield put(msgs.clear());
    const result = yield call(apis.requestCreateContent, content);
    yield put(actions.changePending(false));
    yield call(responseCheck, result);
    yield put(
      modalActions.openModal(
        'Success',
        'Create content successfully!',
        '/content'
      )
    );
  } catch (error) {
    yield put(msgs.danger(`${error?.message}`));
  }
}

function* fetchEditContent({ content }) {
  try {
    const result = yield call(apis.requestEditContent, content);
    yield call(responseCheck, result);
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal(
        'Success',
        'Edit content successfully!',
        '/content'
      )
    );
  } catch (error) {
    yield put(msgs.danger(`${error?.message}`));
    yield put(actions.changePending(false));
  }
}

function* deleteContents({ contents }) {
  try {
    const result = yield call(apis.requestDeleteContents, contents);
    yield call(responseCheck, result);
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal(
        'Success',
        'Delete Content(s) successfully!',
        '/content'
      )
    );
  } catch (error) {
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal('Failure', `${error?.message}`, '/content')
    );
  }
}

function* fetchAssignContent({ contents, target }) {
  try {
    const result = yield call(apis.requestAssignContents, contents, target);
    yield call(responseCheck, result);
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal(
        'Success',
        'Assign content successfully!',
        '/content'
      )
    );
  } catch (error) {
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal('Failure', `${error?.message}`, '/content/assign')
    );
  }
}

function* fetchPageContents({ page }) {
  try {
    const result = yield call(apis.requestPageContents, page);
    yield call(responseCheck, result);
    yield put(actions.getPageContents.success(result.data));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.getPageContents.failure(error));
  }
}

export default function* contentSagas() {
  yield takeLatest(types.getContentList[REQUEST], fetchContentList);
  yield takeLatest(types.getContentDetail[REQUEST], fetchContentDetail);
  yield takeLatest(types.createContent[REQUEST], fetchCreateContent);
  yield takeLatest(types.editContent[REQUEST], fetchEditContent);
  yield takeLatest(types.deleteContents[REQUEST], deleteContents);
  yield takeLatest(types.assignContent[REQUEST], fetchAssignContent);
  yield takeLatest(types.getPageContents[REQUEST], fetchPageContents);
}
