import React, { useState, useEffect } from 'react';
import Header from './header';
import { useSelector, useDispatch } from 'react-redux';

import * as actions from '@/actions/LoginActions';

import {
  defaultMenuItems,
  applicationItems,
  loginMenuItems,
  adminMenus
} from './configs';

const selector = ({ login }) => ({
  isLoggedIn: login.isLoggedIn,
  role: login.currentUser?.role,
  permissions: login.menu,
  isImpersonation: login.isImpersonation,
  userInfo: login.userInfo
});

const getHasPermissionItems = (permissions, menuItems) => {
  let result = [];
  menuItems.forEach((app) => {
    if (app.permissionKey) {
      if (permissions?.[app.permissionKey]) {
        if (app.subMenu && app.subMenu.length) {
          let sub = getHasPermissionItems(permissions, app.subMenu);
          app.subMenu = sub;
        }
        result.push(app);
      }
    } else if (app.subMenu && app.subMenu.length) {
      let sub = getHasPermissionItems(permissions, app.subMenu);
      app.subMenu = sub;
      app.subMenu.length && result.push(app);
    } else {
      result.push(app);
    }
  });
  return result;
};

const getMenuItems = (permissions) => {
  const appConfig = JSON.parse(JSON.stringify(applicationItems)),
    adminConfig = JSON.parse(JSON.stringify(adminMenus));
  const appItems = getHasPermissionItems(permissions.applications, appConfig);
  const adminPages = getHasPermissionItems(permissions.pages, adminConfig);
  let result = [...appItems, ...adminPages, ...loginMenuItems];
  return result;
};

const MenuHeader = () => {
  const dispatch = useDispatch();
  const {
    isLoggedIn,
    permissions,
    isImpersonation,
    role,
    userInfo
  } = useSelector(selector);
  const [menuItems, setMenuItems] = useState(defaultMenuItems);

  useEffect(() => {
    if (isLoggedIn) {
      const items = getMenuItems(permissions);
      setMenuItems(items);
    } else {
      setMenuItems(defaultMenuItems);
    }
  }, [isLoggedIn, permissions]);

  const onLogout = () => {
    dispatch(actions.userLogout.request(isImpersonation));
  };

  const onClickItem = (value) => {
    value.auditKey &&
      dispatch(actions.reportUserAccessApp.request(value.auditKey));
  };

  return (
    <Header
      title="Business Portal"
      defaultMenuItems={defaultMenuItems}
      loginMenuItems={loginMenuItems}
      menuItems={menuItems}
      onLogout={onLogout}
      onItemClick={onClickItem}
      isLoggedIn={isLoggedIn}
      userName={`${userInfo?.firstName || ''} ${userInfo?.lastName || ''}`}
      role={role}
      isImpersonation={isImpersonation}
      permissions={permissions}
    />
  );
};

export default MenuHeader;
