import { REQUEST, SUCCESS, FAILURE, createAction } from '@/utils/reduxUtils';
import * as types from '@/actionTypes/messageTypes';

export const editMessage = {
  request: (message) => createAction(types.editMessage[REQUEST], { message }),
  success: (data) => createAction(types.editMessage[SUCCESS], { data }),
  failure: (status) => createAction(types.editMessage[FAILURE], { status })
};
export const createMessage = {
  request: (message) => createAction(types.createMessage[REQUEST], { message }),
  success: (data) => createAction(types.createMessage[SUCCESS], { data }),
  failure: (status) => createAction(types.createMessage[FAILURE], { status })
};
export const exportMessage = {
  request: (condition) =>
    createAction(types.exportMessage[REQUEST], { condition }),
  success: () => createAction(types.exportMessage[SUCCESS]),
  failure: (status) => createAction(types.exportMessage[FAILURE], { status })
};
export const getMessageList = {
  request: (condition, paginator) =>
    createAction(types.getMessageList[REQUEST], { condition, paginator }),
  success: (data, pagination) =>
    createAction(types.getMessageList[SUCCESS], { data, pagination }),
  failure: (status) => createAction(types.getMessageList[FAILURE], { status })
};
export const deleteMessageList = {
  request: (obj) => createAction(types.deleteMessageList[REQUEST], { obj }),
  success: (data) => createAction(types.deleteMessageList[SUCCESS], { data }),
  failure: (status) =>
    createAction(types.deleteMessageList[FAILURE], { status })
};
export const changePending = (pendingStatus) =>
  createAction(types.changePending, { pendingStatus });
