import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@lux/components';
import styled from 'styled-components';

const CusTextField = ({ className, ...props }) => {
  return (
    <div className={className}>
      <StyleTextField {...props} />
    </div>
  );
};

export const StyleTextField = styled(TextField)`
  &:first-child {
    margin: 0;
  }
`;

CusTextField.propTypes = {
  className: PropTypes.string
};

export default CusTextField;
