import { createRequestType } from '../utils/reduxUtils';

export const verifyBrn = createRequestType('REGISTRATION/VERIFY_BRN');

export const verifyBrnList = createRequestType('REGISTRATION/VERIFY_BRN_LIST');

export const companyRegistration = createRequestType(
  'REGISTRATION/COMPANY_REGISTRATION'
);

export const verifyServiceNum = createRequestType(
  'REGISTRATION/VERIFY_SERVICE_NUMBER'
);

export const registerCESCustomer = createRequestType(
  'REGISTRATION/REGISTER_CES_COMSTOMER'
);

export const changePending = 'REGISTRATION/CHANGE_PENDING';
