import * as types from '@/actionTypes/snackbarTypes';
import { createAction } from '@/utils/reduxUtils';

export const danger = (message) =>
  createAction(types.danger, { message, types: 'danger' });

export const info = (message) =>
  createAction(types.info, { message, types: 'info' });

export const warning = (message) =>
  createAction(types.warning, { message, types: 'warning' });

export const success = (message) =>
  createAction(types.success, { message, types: 'success' });

export const clear = () =>
  createAction(types.clear, { message: '', types: '' });
