import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '@/actionTypes/userTypes';
import { REQUEST } from '@/utils/reduxUtils';
import * as apis from '@/apis/userApi';
import * as actions from '@/actions/UserActions';
import * as msgs from '@/actions/SnackbarActions';
import * as modalActions from '@/actions/ResultModalActions';
import { OnDownLoadCSV } from '@/utils/helpers';
import { responseCheck } from '@/utils/helpers/responseHelper';
import { navigate } from 'gatsby';

function* fetchUserList({ condition, paginator }) {
  try {
    const result = yield call(apis.requestUserList, condition, paginator);
    yield call(responseCheck, result);
    yield put(actions.searchUser.success(result.data, result.pagination));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.searchUser.failure(error));
  }
}

function* fetchUserDetail({ userId }) {
  try {
    const result = yield call(apis.requestUserDetail, userId);
    yield call(responseCheck, result);
    yield put(actions.getUserDetail.success(result.data, result.pagination));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.getUserDetail.failure(error));
  }
}

function* fetchCompanyList({ condition, paginator }) {
  try {
    const result = yield call(apis.requestCompanyList, condition, paginator);
    yield call(responseCheck, result);
    yield put(actions.searchCompany.success(result.data, result.pagination));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.searchCompany.failure(error));
  }
}

function* fetchCompanyApp({ profileId }) {
  try {
    const result = yield call(apis.requestCompanyApp, profileId);
    yield call(responseCheck, result);
    yield put(actions.getCompanyApp.success(result.data));
  } catch (error) {
    yield put(actions.getCompanyApp.failure(error));
  }
}

function* createUser({ userDetail }) {
  try {
    const result = yield call(apis.requestCreateUser, userDetail);
    yield put(actions.changePending(false));
    yield call(responseCheck, result);
    const { userEmail, created, provisioned } = result.data;
    if (userEmail && created && provisioned) {
      yield call(navigate, '/user/confirmUser', {
        state: { user: { userId: userEmail } }
      });
    } else {
      yield call(navigate, '/user/provisionSummary');
    }
  } catch (error) {
    yield put(msgs.warning(`${error?.message}`));
  }
}

function* deleteUser({ userMapObj, newSpocAccountId }) {
  try {
    const result = yield call(
      apis.requestDeleteUser,
      userMapObj,
      newSpocAccountId
    );
    yield put(actions.changePending(false));
    yield call(responseCheck, result);
    yield put(
      modalActions.openModal(
        'Success',
        'Deleted user(s) successfully!',
        '/user'
      )
    );
  } catch (error) {
    yield put(modalActions.openModal('Failure', `${error?.message}`, '/user'));
  }
}
function* deleteSpocUser({ newSpocAccountId, oldSpocAccountId }) {
  try {
    const result = yield call(
      apis.requestDeleteSpocUser,
      newSpocAccountId,
      oldSpocAccountId
    );
    yield put(actions.changePending(false));
    yield call(responseCheck, result);
    yield put(
      modalActions.openModal(
        'Success',
        'Deleted user(s) successfully!',
        '/user'
      )
    );
  } catch (error) {
    yield put(modalActions.openModal('Failure', `${error?.message}`, '/user'));
  }
}

function* editUser({ userDetail }) {
  try {
    const result = yield call(apis.requestEditUser, userDetail);
    yield put(actions.changePending(false));
    yield call(responseCheck, result);
    const { userEmail, provisioned, noChnages } = result.data;
    if (userEmail && provisioned) {
      yield call(navigate, '/user/confirmUser', {
        state: { user: { userId: userEmail, noChnages } }
      });
    } else {
      yield call(navigate, '/user/provisionSummary');
    }
  } catch (error) {
    yield put(msgs.warning(`${error?.message}`));
  }
}

function* confirmUser({ userDetail }) {
  try {
    const result = yield call(apis.requestConfirmUser, userDetail);
    yield put(actions.changePending(false));
    yield call(responseCheck, result);

    yield put(
      modalActions.openModal(
        'Success',
        'Confirmed user successfully!',
        '/user/landing/'
      )
    );
  } catch (error) {
    yield put(
      modalActions.openModal(
        'Failure',
        `${error?.message}`,
        '/user/provisionSummary'
      )
    );
  }
}

function* fetchProvisionList({ status, paginator }) {
  try {
    const result = yield call(apis.requestProvisionList, status, paginator);
    yield call(responseCheck, result);
    yield put(msgs.clear());
    yield put(actions.getProvisionList.success(result.data, result.pagination));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.getProvisionList.failure(error));
  }
}

function* fetchUserAppList({ userId, noChnages }) {
  try {
    let result;
    if (noChnages) {
      result = yield call(apis.getUserApplicationsNoChanges, userId);
    } else {
      result = yield call(apis.requestUserAppList, userId);
    }
    yield call(responseCheck, result);
    yield put(msgs.clear());
    yield put(actions.getUserAppList.success(result.data));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.getUserAppList.failure(error));
  }
}

function* fetchDownloadUsers({ companyProfileId }) {
  try {
    const result = yield call(apis.requestDownloadUsers, companyProfileId);
    if (result) {
      yield call(OnDownLoadCSV, result, 'DownloadUser.csv');
    }
  } catch (error) {
    yield put(msgs.danger(`${error}`));
  }
}

function* updateUserStatus({ accountId, status, newSpocAccountId }) {
  try {
    const result = yield call(
      apis.requestUpdateUserStatus,
      accountId,
      status,
      newSpocAccountId
    );
    yield put(actions.changePending(false));
    yield call(responseCheck, result);

    yield put(
      modalActions.openModal(
        'Success',
        'Changed user status successfully!',
        '/user'
      )
    );
  } catch (error) {
    yield put(modalActions.openModal('Failure', `${error?.message}`, '/user'));
  }
}

function* resetUserPassword({ email }) {
  try {
    const result = yield call(apis.requestResetPassword, email);
    yield put(actions.changePending(false));
    yield call(responseCheck, result);
    yield put(msgs.success(result.status.message));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
  }
}

function* unlockUser({ accountId }) {
  try {
    const result = yield call(apis.requestUnlockUser, accountId);
    yield call(responseCheck, result);
    yield put(actions.unlockUser.success(result.status.message));
  } catch (error) {
    yield put(actions.unlockUser.failure(error));
    yield put(msgs.danger(`${error}`));
  }
}

function* uploadUsers({ file }) {
  try {
    const result = yield call(apis.requestUploadUser, file);
    yield call(responseCheck, result);
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal(
        'Success',
        'Uploaded user(s) successfully!',
        '/user'
      )
    );
  } catch (error) {
    yield put(actions.changePending(false));
    yield put(actions.getUploadErrorList.request());
    yield put(msgs.danger(`${error?.message}`));
  }
}

function* fetchUploadUserErrorList() {
  try {
    const result = yield call(apis.requestUploadUserErrorList);
    yield call(responseCheck, result);
    yield put(actions.getUploadErrorList.success(result.data));
  } catch (error) {
    yield put(actions.getUploadErrorList.failure(error));
    yield put(msgs.danger(`${error}`));
  }
}

function* fetchDownloadErrorCSV({ fileName, errorId }) {
  try {
    const result = yield call(apis.requestDownloadErrorCSV, errorId);
    if (result) {
      yield call(OnDownLoadCSV, result, fileName);
    }
  } catch (error) {
    yield put(msgs.danger(`${error}`));
  }
}
function* fetchExportUserList({ condition }) {
  try {
    const result = yield call(apis.requestExportUserList, condition);
    yield put(actions.changePending(false));
    yield call(OnDownLoadCSV, result, 'UserList.csv');
  } catch (error) {
    yield put(msgs.danger(`${error?.message}`));
    yield put(actions.changePending(false));
  }
}
export default function* userSagas() {
  yield takeLatest(types.getUserList[REQUEST], fetchUserList);
  yield takeLatest(types.getUserDetail[REQUEST], fetchUserDetail);
  yield takeLatest(types.getCompanyList[REQUEST], fetchCompanyList);
  yield takeLatest(types.getCompanyApp[REQUEST], fetchCompanyApp);
  yield takeLatest(types.createUser[REQUEST], createUser);
  yield takeLatest(types.deleteUser[REQUEST], deleteUser);
  yield takeLatest(types.editUser[REQUEST], editUser);
  yield takeLatest(types.getProvisionList[REQUEST], fetchProvisionList);
  yield takeLatest(types.getUserAppList[REQUEST], fetchUserAppList);
  yield takeLatest(types.confirmUser[REQUEST], confirmUser);
  yield takeLatest(types.updateUserStatus[REQUEST], updateUserStatus);
  yield takeLatest(types.resetPassword[REQUEST], resetUserPassword);
  yield takeLatest(types.unlockUser[REQUEST], unlockUser);
  yield takeLatest(types.uploadUsers[REQUEST], uploadUsers);
  yield takeLatest(types.downloadUsers[REQUEST], fetchDownloadUsers);
  yield takeLatest(types.getUploadErrorList[REQUEST], fetchUploadUserErrorList);
  yield takeLatest(types.downloadErrorCSV[REQUEST], fetchDownloadErrorCSV);
  yield takeLatest(types.exportUserList[REQUEST], fetchExportUserList);
  yield takeLatest(types.deleteSpocUser[REQUEST], deleteSpocUser);
}
