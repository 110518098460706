import styled from 'styled-components';

export default {
  Container: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
  `,
  SelectBox: styled.div`
    width: ${(p) => (p.width ? p.width : 'auto')};
  `,
  label: styled.span`
    padding: 10px 16px;
  `
};
