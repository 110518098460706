import settings from 'static/assets/config/settings.json';
import { isProduction, getVMBSubdomain } from '@/utils/helpers';

// const hostName = settings.appHost;

const getHost = () => {
  if (isProduction()) {
    const hostNameList = window.location.host.split('.');
    return hostNameList.reduce(
      (total, currentValue, index) =>
        index > 0 ? total + (total ? '.' : '') + currentValue : total,
      ''
    );
  } else {
    return settings.appHost;
  }
};
/**
 * Page: Home Page
 * Purpose: to display the iframe widgets
 */
export const getHomeAppWidgetURL = () => {
  const hostName = getHost();
  const vmb = getVMBSubdomain();
  return {
    // APPLICATION_VMB_C: `https://vmb.${hostName}/Dashboard/CorpBills.aspx`,
    // APPLICATION_VMB_P: `https://vmb.${hostName}/Dashboard/PersBills.aspx`,
    APPLICATION_VMB_C: `https://${vmb}.${hostName}/reskin/corpdashboard?timestamp=${new Date().getTime()}`,
    APPLICATION_VMB_P: `https://${vmb}.${hostName}/reskin/persdashboard?timestamp=${new Date().getTime()}`,
    APPLICATION_ETRACKER: `https://etracker.${hostName}/etracker/dashboard/lastViewShortcutAction.do`,
    APPLICATION_EBA1: `https://eba.${hostName}/BPDashboard/big1.aspx`,
    APPLICATION_EBA2: `https://eba.${hostName}/BPDashboard/big2.aspx`,
    APPLICATION_EMONITOR: `https://metroe.${hostName}/nmp/Reports/HomepageReport/Report700x253.aspx`
  };
};

/**
 * Page: Account Settings Page
 * Purpose: to allow user to download the subscribed application data
 */
export const getAccountDownloadList = () => {
  const hostName = getHost();
  return {
    APPLICATION_DMBD: {
      label: 'Download My Bill Data (DMBD)',
      link: `https://dmbd.${hostName}/dmbd/emailnotification.aspx`
    },
    APPLICATION_EBA: {
      label: 'eBill Analyser (eBA)',
      link: `https://eba.${hostName}/EmailNotify/EmailNotification.aspx`
    },
    APPLICATION_EBO: {
      label: 'eBill Organiser (eBO)',
      link: `https://ebo.${hostName}/EmailNotify/EmailNotificationEBO.aspx`
    }
  };
};

export const getVMBAcctManagement = () => {
  const hostName = getHost();
  const vmb = getVMBSubdomain();
  return {
    // ACCT_PAPER_OPTION: `https://vmb.${hostName}/Admin/PortalInterface/CompanyPaperOption.aspx?brn=`,
    // DOWNLOAD_REPORT: `https://vmb.${hostName}/Admin/PortalInterface/DownloadPaperOptionHistory.aspx?brn=`
    ACCT_PAPER_OPTION: `https://${vmb}.${hostName}/reskin/admin/CompanyPaperOption?brn=`,
    DOWNLOAD_REPORT: `https://${vmb}.${hostName}/reskin/paperoptionhistory?brn=`
  };
};

/**
 * Component: Header
 * Purpose: to render the menu items along with its url
 */
export const getMenuItemURL = () => {
  const hostName = getHost();
  const vmb = getVMBSubdomain();
  return {
    // VMB_C: `https://vmb.${hostName}/page/corpbilloverview.html`,
    // VMB_P: `https://vmb.${hostName}/page/personalbilloverview.html`,
    VMB_C: `https://${vmb}.${hostName}/reskin/corpbilloverview/`,
    VMB_P: `https://${vmb}.${hostName}/reskin/persbilloverview/`,
    EBA_DB: `https://eba.${hostName}/Dashboard/DashBoard.aspx`,
    EBA_CCM: `https://eba.${hostName}/CostCentre/SelCostCentre.aspx`,
    EBA_PB: `https://eba.${hostName}/Phonebook/phonebook.aspx`,
    EBA_GRC: `https://eba.${hostName}/TabPage/EBillAnalyzer.aspx`,
    EBA_SM: `https://eba.${hostName}/ScheduleManager/ScheduleManager.aspx`,
    EBA_DR: `https://eba.${hostName}/reports/DownLoadReport.aspx`,
    EBA_DC: `https://eba.${hostName}/DownChart/DownloadChart.aspx`,
    EBO_RR: `https://ebo.${hostName}/RetrieveReports.aspx`,
    EBO_UDS: `https://ebo.${hostName}/UpdateDepartment.aspx`,
    EBO_GSR: `https://ebo.${hostName}/GenerateSpecialReport.aspx`,
    // DMBD: `https://dmbd.${hostName}/billdata.aspx`,
    DMBD: `https://dmbd.${hostName}/dmbd/billdata.aspx`,
    BUSINESS_ESHOP: `https://smemobile.${hostName}/`,
    // BUSINESS_ESHOP: `https://smemobile.${hostName}/`, // Later will revert back
    DATA_SERVICES: `https://dataeshop.${hostName}/bod/`,
    WHOLESALE: `https://dataeshop.${hostName}/wholesale`,
    ECARE: `https://smodweb.${hostName}/`,
    ETRACKER: `https://etracker.${hostName}/etracker/`,
    EREQUEST: `https://erequest.${hostName}/`,
    EMONITOR: `https://metroe.${hostName}/nmp/`,
    SATELLITE: `https://dataeshop.${hostName}/satellite/`,
    TSM: `https://web.bizportal.singtel.com/affwebservices/public/saml2sso?SPID=https%3A%2F%2Ftsm.bridgealliance.com%2Fsingtel`,
    PE_REPORTS: `https://enetmonitoring.${hostName}/`,
    MSPORTAL: `https://msportal.${hostName}/singtelms/login.do`,
    TMS: `https://tms.${hostName}/iddtms/Start.aspx`,
    WATT: `https://watt.${hostName}/`
  };
};

/**
 * Page: Manage User Account/Role
 * Purpose: to redirect admin to downstream application page
 */
export const getUserManageAccountURL = () => {
  const hostName = getHost();
  const vmb = getVMBSubdomain();
  return {
    2: `https://${vmb}.${hostName}/reskin/admin/AssignAccountToCuser/`,
    3: `https://${vmb}.${hostName}/reskin/admin/PersonalManageService/`,
    4: `https://eba.${hostName}/AccMan/AccMgt.aspx`,
    // 5: `https://ebo.${hostName}/AccMan/AccMgt.aspx`, // Change for eba
    5: `https://eba.${hostName}/AccMan/AccMgt.aspx`,
    6: `https://eba.${hostName}/AccMan/AccMgt.aspx`,
    // 10: `https://smodweb.${hostName}/app/userarea/UserDetails.aspx`,
    // 12: `https://etracker.${hostName}/etracker/administration/initRoleAssignmentAction.do`,
    24: `https://msportal.${hostName}/singtelmsadmin/portaladmin/viewUserProfile.do`
  };
};

/**
 * Page: Specific login page
 * Purpose: to redirect login user to download bill page
 */
export const getDownloadBillURL = () => {
  const hostName = getHost();
  const vmb = getVMBSubdomain();
  return `https://${vmb}.${hostName}/reskin/downloadBills`;
  //return `https://vmb.${hostName}/page/corpbillacctlatest.html`;
};
