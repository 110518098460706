import { isDevelop } from '@/utils/helpers';
import settings from 'static/assets/config/settings.json';

//path prefix for importing static assets
export const SYSTEM_PATH_PREFIX = isDevelop() ? '' : settings.pathPrefix;

export const SET_LOGGED_IN = 'SET_LOGGED_IN';

//cookie key
export const TOKEN_KEY_COOKIE = 'loginToken';

//url path
export const URL_PATH = SYSTEM_PATH_PREFIX + '/assets/config/url.json';

export const URL_KEY = 'BIZPORTAL-URL';

export const TOKEN_INVALID = 'unauthorized';

//general date/time format
export const UTC_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZZ';

export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export const DATE_FORMAT = 'DD/MM/YYYY';

export const TIME_FORMAT = 'HH:mm:ss';

//user role map
export const USER_ROLE_MAP = {
  SA: 'Singtel Administrator',
  CS: 'Customer Support',
  SPOC: 'Company Main Administrator',
  CA: 'Company Administrator',
  EU: 'Company End User'
};

export * from './ApplicationUrl';

export const USER_UPLOAD_STATUS = ['UPLOADING', 'UPLOADED'];
