import styled from 'styled-components';
import { Modal } from '@lux/components';

import Theme from '@/theme';

const StyledModal = styled(Modal)`
  text-align: left;
  & > div > div > div:last-child {
    ${Theme.media.md`
        max-width:80vw;
        min-width:30vw;
        width:auto;
    `};
  }
`;

export default StyledModal;
