import * as types from '@/actionTypes/userTypes';
import { REQUEST, SUCCESS, FAILURE, createAction } from '@/utils/reduxUtils';

export const searchUser = {
  request: (condition, paginator) =>
    createAction(types.getUserList[REQUEST], { condition, paginator }),
  success: (data, pagination) =>
    createAction(types.getUserList[SUCCESS], { data, pagination }),
  failure: (status) => createAction(types.getUserList[FAILURE], { status })
};

export const getUserDetail = {
  request: (userId) => createAction(types.getUserDetail[REQUEST], { userId }),
  success: (data) => createAction(types.getUserDetail[SUCCESS], { data }),
  failure: (status) => createAction(types.getUserDetail[FAILURE], { status })
};

export const searchCompany = {
  request: (condition, paginator) =>
    createAction(types.getCompanyList[REQUEST], { condition, paginator }),
  success: (data, pagination) =>
    createAction(types.getCompanyList[SUCCESS], { data, pagination }),
  failure: (status) => createAction(types.getCompanyList[FAILURE], { status })
};

export const getCompanyApp = {
  request: (profileId) =>
    createAction(types.getCompanyApp[REQUEST], { profileId }),
  success: (data) => createAction(types.getCompanyApp[SUCCESS], { data }),
  failure: (status) => createAction(types.getCompanyApp[FAILURE], { status })
};

export const createUser = {
  request: (userDetail) =>
    createAction(types.createUser[REQUEST], { userDetail })
};

export const deleteUser = {
  request: (userMapObj, newSpocAccountId) =>
    createAction(types.deleteUser[REQUEST], { userMapObj, newSpocAccountId })
};

export const deleteSpocUser = {
  request: (newSpocAccountId, oldSpocAccountId) =>
    createAction(types.deleteSpocUser[REQUEST], {
      newSpocAccountId,
      oldSpocAccountId
    })
};

export const editUser = {
  request: (userDetail) => createAction(types.editUser[REQUEST], { userDetail })
};

export const getProvisionList = {
  request: (status, paginator) =>
    createAction(types.getProvisionList[REQUEST], { status, paginator }),
  success: (data, pagination) =>
    createAction(types.getProvisionList[SUCCESS], { data, pagination }),
  failure: (status) => createAction(types.getProvisionList[FAILURE], { status })
};

export const getUserAppList = {
  request: (userId, noChnages) =>
    createAction(types.getUserAppList[REQUEST], { userId, noChnages }),
  success: (data) => createAction(types.getUserAppList[SUCCESS], { data }),
  failure: (status) => createAction(types.getUserAppList[FAILURE], { status })
};

export const confirmUser = {
  request: (userDetail) =>
    createAction(types.confirmUser[REQUEST], { userDetail })
};

export const downloadUsers = {
  request: (companyProfileId) =>
    createAction(types.downloadUsers[REQUEST], { companyProfileId })
};

export const updateUserStatus = {
  request: (accountId, status, newSpocAccountId) =>
    createAction(types.updateUserStatus[REQUEST], {
      accountId,
      status,
      newSpocAccountId
    })
};

export const resetPassword = {
  request: (email) => createAction(types.resetPassword[REQUEST], { email })
};

export const unlockUser = {
  request: (accountId) =>
    createAction(types.unlockUser[REQUEST], { accountId }),
  success: (data) => createAction(types.unlockUser[SUCCESS], { data }),
  failure: (status) => createAction(types.unlockUser[FAILURE], status)
};

export const clearUserDetails = () => createAction(types.clearUserDetails);

export const uploadUsers = {
  request: (file) => createAction(types.uploadUsers[REQUEST], { file })
};

export const getUploadErrorList = {
  request: () => createAction(types.getUploadErrorList[REQUEST]),
  success: (data) => createAction(types.getUploadErrorList[SUCCESS], { data }),
  failure: (status) =>
    createAction(types.getUploadErrorList[FAILURE], { status })
};

export const downloadErrorCSV = {
  request: (fileName, errorId) =>
    createAction(types.downloadErrorCSV[REQUEST], { fileName, errorId })
};

export const changePending = (pendingStatus) =>
  createAction(types.changePending, { pendingStatus });

export const exportUserList = {
  request: (condition) =>
    createAction(types.exportUserList[REQUEST], { condition })
};
