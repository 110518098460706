import { createRequestType } from '@/utils/reduxUtils';
export const getUserInviteList = createRequestType(
  'USERINVITATION/GET_USER_INVITATION_LIST'
);
export const sendUserInvitation = createRequestType(
  'USERINVITATION/CREATE_INVITATION'
);
export const changePendingInvite = 'USERINVITATION/CHANGE_PENDING';
export const resendUserInviteEmail = createRequestType(
  'USERINVITATION/RESEND_EMAIL'
);
export const submitUserRegistrationForm = createRequestType(
  'USERINVITATION/SUBMIT_FORM'
);
export const getApplicationSubsInfo = createRequestType(
  'USERINVITATION/GET_USER_APP_SUB'
);

export const rejectUserInvitation = createRequestType(
  'USERINVITATION/REJECT_INVITATION'
);

export const approveUserInvitation = createRequestType(
  'USERINVITATION/APPROVE_INVITATION'
);

export const getInvitedUserInfo = createRequestType(
  'USERINVITATION/GET_INVITED_USER_INFO'
);
export const changePendingStatus = 'USERINVITATION/CHANGE_PENDING_STATUS';
