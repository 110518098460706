import * as types from '@/actionTypes/contentTypes';
import { REQUEST, SUCCESS, FAILURE, createAction } from '@/utils/reduxUtils';

export const getContentList = {
  request: (condition, paginator) =>
    createAction(types.getContentList[REQUEST], { condition, paginator }),
  success: (data, pagination) =>
    createAction(types.getContentList[SUCCESS], { data, pagination }),
  failure: (status) => createAction(types.getContentList[FAILURE], { status })
};

export const getContentDetail = {
  request: (contentId) =>
    createAction(types.getContentDetail[REQUEST], { contentId }),
  success: (data) => createAction(types.getContentDetail[SUCCESS], { data }),
  failure: (status) => createAction(types.getContentDetail[FAILURE], { status })
};

export const createContent = {
  request: (content) => createAction(types.createContent[REQUEST], { content })
};

export const editContent = {
  request: (content) => createAction(types.editContent[REQUEST], { content })
};

export const deleteContents = {
  request: (contents) =>
    createAction(types.deleteContents[REQUEST], { contents })
};

export const assignContents = {
  request: (contents, target) =>
    createAction(types.assignContent[REQUEST], { contents, target })
};

export const getPageContents = {
  request: (page) => createAction(types.getPageContents[REQUEST], { page }),
  success: (data) => createAction(types.getPageContents[SUCCESS], { data }),
  failure: (status) => createAction(types.getPageContents[FAILURE], { status })
};

export const changePending = (pendingStatus) =>
  createAction(types.changePending, { pendingStatus });
