import { keyframes } from 'styled-components';

/**
 * Animates slight shrinkage and expansion of a component
 */
const bounce = keyframes`
  0% { transform: scale(0.8) translateX(-50%); transform-origin:0% 50%; }
  100% { transform: scale(1) translateX(-50%); transform-origin:0% 50%; }
`;

const animations = {
  bounce
};

export default animations;
