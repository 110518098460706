import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SC from './styles';

const Pagination = (props) => {
  const [currIndex, setCurrIndex] = useState(0);
  const [current, setCurrent] = useState(1);
  const [pageList, setPageList] = useState([]);
  const {
    currentPage = 1,
    numOfPagesToDisplay = 5,
    onPageChange = () => {},
    totalPages = 1,
    disabled = false
  } = props;

  useEffect(() => {
    setCurrent(currentPage);
  }, [currentPage]);

  //generate new page list
  useEffect(() => {
    let result = [];
    const totalPageNum = Math.ceil(totalPages / numOfPagesToDisplay);
    for (let j = 0; j <= totalPageNum - 1; j++) {
      let arr = [];
      for (let i = 1; i <= numOfPagesToDisplay; i++) {
        const p = j * numOfPagesToDisplay + i;
        arr.push(p);
        if (p >= totalPages) break;
      }
      result.push(arr);
    }
    setPageList(result);
  }, [numOfPagesToDisplay, totalPages]);

  //handle go to next page effect
  useEffect(() => {
    const index = Math.ceil(current / numOfPagesToDisplay);
    setCurrIndex(index - 1);
  }, [current, numOfPagesToDisplay]);

  const onChangePage = (page) => {
    setCurrent(page);
    onPageChange(page);
  };
  return (
    <SC.Container>
      <SC.Ul>
        <SC.Li
          disabled={disabled || currIndex === 0}
          onClick={() => {
            onChangePage(0 * numOfPagesToDisplay + 1);
          }}
        >
          {'<<'}
        </SC.Li>
        <SC.Li
          disabled={disabled || currIndex === 0}
          onClick={() => {
            onChangePage((currIndex - 1) * numOfPagesToDisplay + 1);
          }}
        >
          Prev
        </SC.Li>
        {(pageList[currIndex] || []).map((item, i) => {
          return (
            <SC.Li
              key={i + item}
              disabled={disabled}
              onClick={() => {
                onChangePage(item);
              }}
              isSelected={current === item}
            >
              {item}
            </SC.Li>
          );
        })}
        <SC.Li
          disabled={disabled || currIndex >= pageList.length - 1}
          onClick={() => {
            onChangePage((currIndex + 1) * numOfPagesToDisplay + 1);
          }}
        >
          Next
        </SC.Li>
        <SC.Li
          disabled={disabled || currIndex >= pageList.length - 1}
          onClick={() => {
            onChangePage((pageList.length - 1) * numOfPagesToDisplay + 1);
          }}
        >
          {'>>'}
        </SC.Li>
      </SC.Ul>
    </SC.Container>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  numOfPagesToDisplay: PropTypes.number,
  onPageChange: PropTypes.func,
  totalPages: PropTypes.number,
  disabled: PropTypes.bool
};

export default Pagination;
