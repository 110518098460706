import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';

import createStore from './src/store';
import Header from './src/components/Header';
import Footer from './src/components/Footer';
import Theme from './src/theme';
import SnackBar from './src/components/Snackbar';
import ResultModal from './src/components/ResultModal';
import './styles.css';

const { store, persistor } = createStore;

const WrappedRoot = ({ element }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={Theme}>
        <Fragment>
          <Header />
          <SnackBar />
          <ResultModal />
          {element}
          <Footer />
        </Fragment>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

export { WrappedRoot };
