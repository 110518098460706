import { createRequestType } from '../utils/reduxUtils';

export const getCompanyList = createRequestType('COMPANY/GET_COMPANY_LIST');

export const updateCompany = createRequestType('COMPANY/UPDATE_COMPANY');

export const getCompanyDetails = createRequestType(
  'COMPANY/GET_COMPANY_DETAILS'
);

export const getAppList = createRequestType('COMPANY/GET_APP_LIST');

export const getBRNFromAcctO = createRequestType('COMPANY/GET_BRN_FROM_ACCTO');

export const createCompany = createRequestType('COMPANY/CREATE_COMPANY');

export const deleteCompany = createRequestType('COMPANY/DELETE_COMPANY');

export const suspendCompany = createRequestType('COMPANY/SUSPEND_COMPANY');

export const activateCompany = createRequestType('COMPANY/ACTIVATE_COMPANY');

export const getPackageUserList = createRequestType(
  'COMPANY/GET_PACKAGE_USER_LIST'
);

export const getEBAPackageList = createRequestType(
  'COMPANY/GET_EBA_PACKAGE_LIST'
);

export const getEBOPackageList = createRequestType(
  'COMPANY/GET_EBO_PACKAGE_LIST'
);

export const getDMBDPackageList = createRequestType(
  'COMPANY/GET_DMBD_PACKAGE_LIST'
);

export const exportCompanyList = createRequestType(
  'COMPANY/EXPORT_COMPANY_LIST'
);

export const clearCompanyDetails = 'COMPANY/CLEAR_COMPANY_DETAILS';

export const changePending = 'COMPANY/CHANGE_PENDING';
