import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { remCalc } from '@lux/helpers';
import Theme from '@/theme';
import * as actions from '@/actions/SnackbarActions';

const { colours, styles, animations } = Theme;

const SC = {
  StyledContainer: styled.div`
    padding: ${remCalc(6)} ${remCalc(16)};
    bottom: ${remCalc(80)};
    max-width: 80%;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    animation: ${animations.bounce} 0.3s;

    ${Theme.media.md`
      top: ${remCalc(100)};
      bottom:auto;
    `};

    display: flex;
    z-index: 10000;
    position: fixed;
    align-items: center;
    justify-content: center;
    color: white;
    fill: white;
    border-radius: ${remCalc(3)};
    ${styles.panelDropShadow}
    ${(props) => {
      switch (props.type) {
        case 'danger':
          return `background:${colours.rederror}`;
        case 'warning':
          return `background:${colours.yellow_d2}`;
        case 'info':
          return `background:${colours.grey_700}`;
        case 'success':
          return `background:${colours.green_60}`;
        default:
          return `background:${colours.rederror}`;
      }
    }};
  `,
  Content: styled.div`
    display: flex;
    align-items: center;
    width: 100%;
  `,
  Text: styled.div`
    max-width: calc(100% - ${remCalc(48)});
    overflow: hidden;
    max-height: 100px;
    line-height: 25px;
  `,
  Close: styled.div`
    margin: ${remCalc(10)};
    width: ${remCalc(28)};
    height: ${remCalc(28)};
    cursor: pointer;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNTg3NTQzNjM5MjI3IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjExMzkiIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+PC9zdHlsZT48L2RlZnM+PHBhdGggZD0iTTUxMiAxMjhDMzAwLjggMTI4IDEyOCAzMDAuOCAxMjggNTEyczE3Mi44IDM4NCAzODQgMzg0IDM4NC0xNzIuOCAzODQtMzg0UzcyMy4yIDEyOCA1MTIgMTI4ek01MTIgODMyYy0xNzkuMiAwLTMyMC0xNDAuOC0zMjAtMzIwczE0MC44LTMyMCAzMjAtMzIwIDMyMCAxNDAuOCAzMjAgMzIwUzY5MS4yIDgzMiA1MTIgODMyeiIgcC1pZD0iMTE0MCIgZmlsbD0iI2ZmZmZmZiI+PC9wYXRoPjxwYXRoIGQ9Ik02NzIgMzUyYy0xMi44LTEyLjgtMzItMTIuOC00NC44IDBMNTEyIDQ2Ny4yIDM5Ni44IDM1MkMzODQgMzM5LjIgMzY0LjggMzM5LjIgMzUyIDM1MlMzMzkuMiAzODQgMzUyIDM5Ni44TDQ2Ny4yIDUxMiAzNTIgNjI3LjJjLTEyLjggMTIuOC0xMi44IDMyIDAgNDQuOHMzMiAxMi44IDQ0LjggMEw1MTIgNTU2LjhsMTE1LjIgMTE1LjJjMTIuOCAxMi44IDMyIDEyLjggNDQuOCAwczEyLjgtMzIgMC00NC44TDU1Ni44IDUxMmwxMTUuMi0xMTUuMkM2ODQuOCAzODQgNjg0LjggMzY0LjggNjcyIDM1MnoiIHAtaWQ9IjExNDEiIGZpbGw9IiNmZmZmZmYiPjwvcGF0aD48L3N2Zz4=');
  `
};

const selector = ({ snackbar }) => ({
  message: snackbar.message,
  types: snackbar.types
});

const Snackbar = (props) => {
  const dispatch = useDispatch();
  const { className } = props;
  const { message, types } = useSelector(selector);

  return (
    message && (
      <SC.StyledContainer type={types}>
        <SC.Content className={className} type={types}>
          <SC.Text title={message}>{message}</SC.Text>
          <SC.Close
            onClick={() => {
              dispatch(actions.clear());
            }}
          />
        </SC.Content>
      </SC.StyledContainer>
    )
  );
};

Snackbar.propTypes = {
  className: PropTypes.string
};

export default Snackbar;
