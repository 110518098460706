import Fetch from '../utils/fetch';
import getUrl from '../utils/urlUtils';

//request for get User Application Report
export async function requestGetUserAppReport(search, paginator) {
  const urls = await getUrl();
  let { status = [], selectedSegmentGroup = [], selectedAplication = [] } =
    search || {};
  status = status.map((item) => item.value);
  selectedSegmentGroup = selectedSegmentGroup.map((item) => item.value);
  selectedAplication = selectedAplication.map((item) => item.label);
  const postbody = {
    ...search,
    status,
    selectedSegmentGroup,
    selectedAplication,
    paginator
  };
  return Fetch.post(urls.getUserAppReport, postbody);
}

//request for get User access eBA, eBO, DMBD Application Report
export async function requestGetPegasusReport(search, paginator, reportType) {
  const urls = await getUrl();
  const appId = reportType.toLowerCase();
  const postbody = {
    ...search,
    paginator
  };
  return Fetch.post(urls.getPegasusReport + appId, postbody);
}

//request for get User Login Report
export async function requestGetLoginReport(search, paginator) {
  const urls = await getUrl();
  let { selectedAplication = [] } = search || {};
  selectedAplication = selectedAplication.map((item) => item.label);
  const postbody = {
    ...search,
    selectedAplication,
    paginator
  };
  return Fetch.post(urls.getLoginReport, postbody);
}

//request for get User Activity Report
export async function requestGetUserActReport(search, paginator) {
  const urls = await getUrl();
  const postbody = {
    ...search,
    paginator
  };
  return Fetch.post(urls.getUserActReport, postbody);
}

//request for get User Request Account Report
export async function requestGetRequestAcctReport(search, paginator) {
  const urls = await getUrl();
  const postbody = {
    ...search,
    paginator
  };
  return Fetch.post(urls.getRequestAcctReport, postbody);
}

//request for Export User Application Report
export async function requestExportUserAppReport(search, paginator) {
  const urls = await getUrl();
  let { status = [], selectedSegmentGroup = [], selectedAplication = [] } =
    search || {};
  status = status.map((item) => item.value);
  selectedSegmentGroup = selectedSegmentGroup.map((item) => item.value);
  selectedAplication = selectedAplication.map((item) => item.label);
  const postbody = {
    ...search,
    status,
    selectedSegmentGroup,
    selectedAplication,
    paginator
  };
  return Fetch.post(urls.exportUserAppReport, postbody);
}

//request for Export User access eBA, eBO, DMBD Application Report
export async function requestExportPegasusReport(appId, search, reportType) {
  const urls = await getUrl();
  // paginator details coming from search Obj
  const postbody = {
    ...search,
    ...appId
  };
  return Fetch.post(urls.exportPegasusReport + `/${reportType}`, postbody);
}

//request for Export User Login Report
export async function requestExportLoginReport(search, paginator) {
  const urls = await getUrl();
  let { selectedAplication = [] } = search || {};
  selectedAplication = selectedAplication.map((item) => item.label);
  const postbody = {
    ...search,
    selectedAplication,
    paginator
  };
  return Fetch.post(urls.exportLoginReport, postbody);
}

//request for Export User Activity Report
export async function requestExportUserActReport(search, paginator) {
  const urls = await getUrl();
  const postbody = {
    ...search,
    paginator
  };
  return Fetch.post(urls.exportUserActReport, postbody);
}

//request for Export User Request Account Report
export async function requestExportRequestAcctReport(search, paginator) {
  const urls = await getUrl();
  const postbody = {
    ...search,
    paginator
  };
  return Fetch.post(urls.exportRequestAcctReport, postbody);
}
