import Fetch from '@/utils/fetch';
import getUrl from '@/utils/urlUtils';

//request for account
export async function requestAccount() {
  const urls = await getUrl();
  return Fetch.get(urls.getAccount);
}

//request for update account
export async function requestUpdateAccount(accountDetails) {
  const urls = await getUrl();
  return Fetch.post(urls.updateAccount, accountDetails);
}
