import {
  REQUEST,
  SUCCESS,
  FAILURE,
  createAction
} from '../../utils/reduxUtils';
import * as types from '../../actionTypes/companyTypes';

export const getCompanyList = {
  request: (search, paginator) =>
    createAction(types.getCompanyList[REQUEST], { search, paginator }),
  success: (data, pagination) =>
    createAction(types.getCompanyList[SUCCESS], { data, pagination }),
  failure: (status) => createAction(types.getCompanyList[FAILURE], status)
};

export const updateCompany = {
  request: (companyDetails) =>
    createAction(types.updateCompany[REQUEST], { companyDetails })
};

export const getCompanyDetails = {
  request: (profileId) =>
    createAction(types.getCompanyDetails[REQUEST], { profileId }),
  success: (data) => createAction(types.getCompanyDetails[SUCCESS], { data }),
  failure: (status) => createAction(types.getCompanyDetails[FAILURE], status)
};

export const getAppList = {
  request: (msbs, segment, mode) =>
    createAction(types.getAppList[REQUEST], { msbs, segment, mode }),
  success: (data) => createAction(types.getAppList[SUCCESS], { data }),
  failure: (status) => createAction(types.getAppList[FAILURE], status)
};

export const getBRNFromAcctO = {
  request: (brn) => createAction(types.getBRNFromAcctO[REQUEST], { brn }),
  success: (data) => createAction(types.getBRNFromAcctO[SUCCESS], { data }),
  failure: (status) => createAction(types.getBRNFromAcctO[FAILURE], status)
};

export const createCompany = {
  request: (companyDetails) =>
    createAction(types.createCompany[REQUEST], { companyDetails })
};

export const deleteCompany = {
  request: (companyList) =>
    createAction(types.deleteCompany[REQUEST], { companyList })
};

export const suspendCompany = {
  request: (companyId) =>
    createAction(types.suspendCompany[REQUEST], { companyId })
};

export const activateCompany = {
  request: (companyId) =>
    createAction(types.activateCompany[REQUEST], { companyId })
};

export const getPackageUserList = {
  request: (cahpId) =>
    createAction(types.getPackageUserList[REQUEST], { cahpId }),
  success: (data) => createAction(types.getPackageUserList[SUCCESS], { data }),
  failure: (status) => createAction(types.getPackageUserList[FAILURE], status)
};

export const getEBAPackageList = {
  request: () => createAction(types.getEBAPackageList[REQUEST]),
  success: (data) => createAction(types.getEBAPackageList[SUCCESS], { data }),
  failure: (status) => createAction(types.getEBAPackageList[FAILURE], status)
};

export const getEBOPackageList = {
  request: () => createAction(types.getEBOPackageList[REQUEST]),
  success: (data) => createAction(types.getEBOPackageList[SUCCESS], { data }),
  failure: (status) => createAction(types.getEBOPackageList[FAILURE], status)
};

export const getDMBDPackageList = {
  request: () => createAction(types.getDMBDPackageList[REQUEST]),
  success: (data) => createAction(types.getDMBDPackageList[SUCCESS], { data }),
  failure: (status) => createAction(types.getDMBDPackageList[FAILURE], status)
};

export const exportCompanyList = {
  request: (search) =>
    createAction(types.exportCompanyList[REQUEST], { search }),
  success: (data) => createAction(types.exportCompanyList[SUCCESS], { data }),
  failure: (status) => createAction(types.exportCompanyList[FAILURE], status)
};

export const clearCompanyDetails = () =>
  createAction(types.clearCompanyDetails);

export const changePending = (pendingStatus) =>
  createAction(types.changePending, { pendingStatus });
