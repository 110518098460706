import { createRequestType } from '../utils/reduxUtils';

export const getTrackEmailList = createRequestType(
  'EMAIL/GET_TRACK_EMAIL_LIST'
);

export const resendEmail = createRequestType('EMAIL/RESEND_EMAIL');

export const getTempEmailList = createRequestType('EMAIL/GET_TEMP_EMAIL_LIST');

export const getTempEmail = createRequestType('EMAIL/GET_TEMP_EMAIL');

export const getVariables = createRequestType('EMAIL/GET_VARIABLES');

export const updateTempEmail = createRequestType('EMAIL/UPDATE_TEMP-EMAIL');

export const transferProfile = createRequestType('EMAIL/TRANSFER_PROFILE');

export const changePending = 'EMAIL/CHANGE_PENDING';
