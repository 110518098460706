import { createRequestType } from '@/utils/reduxUtils';

export const getUserList = createRequestType('USER/GET_USER_LIST');

export const getUserDetail = createRequestType('USER/GET_USER_DETAIL');

export const getCompanyList = createRequestType('USER/GET_COMPANY_LIST');

export const getCompanyApp = createRequestType('USER/GET_COMPANY_APP');

export const createUser = createRequestType('USER/CREATE_USER');

export const deleteUser = createRequestType('USER/DELETE_USER');

export const editUser = createRequestType('USER/EDIT_USER');

export const getProvisionList = createRequestType('USER/PROVISION_LIST');

export const getUserAppList = createRequestType('USER/USER_APP_LIST');

export const confirmUser = createRequestType('USER/CONFIRM_USER');

export const downloadUsers = createRequestType('USER/DOWNLOAD_USERS');

export const updateUserStatus = createRequestType('USER/UPDATE_USER_STATUS');

export const uploadUsers = createRequestType('USER/UPLOAD_USERS');

export const getUploadErrorList = createRequestType(
  'USER/GET_UPLOAD_USER_ERROR_LIST'
);

export const downloadErrorCSV = createRequestType('USER/DOWNLOAD_ERROR_CSV');

export const resetPassword = createRequestType('USER/RESET_PASSWORD');

export const unlockUser = createRequestType('USER/UNLOCK_USER');

export const changePending = 'USER/CHANGE_PENDING';

export const exportUserList = createRequestType('USER/EXPORT_USER_LIST');
export const deleteSpocUser = createRequestType('USER/DELETE_SPOC_USER');
