import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Column } from '@lux/components';
import Content from '../Content';
import SC from './styles';
import SingtelLogo from './SingtelLogo';
import IconHamburger from '@lux/components/src/assets/svgs/hamburger-line.svg';
import IconClose from './IconClose';
import { navigate } from 'gatsby';
import { getMenuItemURL } from '@/utils/constants';
import { SYSTEM_PATH_PREFIX } from '@/utils/constants';
import Avatar from '@lux/components/src/components/Header/Avatar';
import { getFlow } from '@/utils/helpers/login';

import { Modal } from '@/components/Lux';
import Title from '@/components/Title';
import { Anchor } from '@lux/components';

const Header = ({
  defaultMenuItems,
  menuItems,
  isLoggedIn,
  title,
  onLogout,
  userName,
  role,
  onItemClick,
  isImpersonation,
  permissions
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);

  const onMenuOpen = () => {
    setMenuOpen(true);
  };

  const onMenuClose = () => {
    setMenuOpen(false);
  };

  const menuOnClick = (item) => {
    const href = getUrl(item);
    if (!href) return;
    if (item.openType === 'iframe') {
      navigate('/external/', { state: { link: href } });
    } else if (item.openType === 'popup') {
      window.open(href);
    } else if (item.openType === 'modal') {
      setOpenModal(true);
    } else {
      navigate(href);
    }
    onItemClick(item);
    setMenuOpen(false);
  };

  const getUrl = (item) => {
    const menuItemURLList = getMenuItemURL(permissions);
    const url = item.href
      ? item.href
      : item.urlKey
      ? menuItemURLList[item.urlKey]
      : undefined;
    return item.urlKey === 'DMBD' ? url + '?' + Math.random() : url;
  };

  const { flow } = getFlow();
  const isUDF = flow === 'UDF';

  return (
    <SC.Header>
      {isImpersonation && <SC.Impersonation>Impersonation</SC.Impersonation>}
      {!isLoggedIn && (
        <SC.Grid>
          <Row middle="sm">
            <Column sm={12} md={3} lg={5}>
              <SC.LeftMenuItems isInline={true}>
                <SC.ControlListItem>
                  <SingtelLogo />
                </SC.ControlListItem>

                {!!isUDF ? null : (
                  <SC.ControlListItem>
                    <SC.Brand level={4}>{title}</SC.Brand>
                  </SC.ControlListItem>
                )}
              </SC.LeftMenuItems>
            </Column>

            {/* {!!isUDF ? null : (
              <Column>
                {(defaultMenuItems || []).map((item, index) => (
                  <SC.PaddedLinkAnchor
                    key={item.title + index}
                    secondary
                    onClick={() => menuOnClick(item)}
                  >
                    {item.title}
                  </SC.PaddedLinkAnchor>
                ))}
              </Column>
            )} */}
          </Row>
        </SC.Grid>
      )}

      {isLoggedIn && !isUDF && (
        <>
          {isOpenModal && (
            <Modal
              open={open}
              onClose={() => {
                setOpenModal(false);
              }}
            >
              <Title title="" level={3} />
              <Content>
                <SC.List>
                  <SC.ListTitle>
                    <strong>
                      These billing applications (View My Bill, eBill Analyser,
                      eBill Organiser, Download Bill Data) have been
                      discontinued from 22-Feb-24. We have migrated your
                      BizPortal accounts to the new portal.
                    </strong>
                  </SC.ListTitle>
                  <SC.ListTitle />
                  <SC.ListTitle>
                    To access your <strong>bills and billing reports</strong>,
                    please log in to <strong>Empower Portal</strong> at&nbsp;
                    <Anchor href="https://empower.singtel.com/">
                      empower.singtel.com
                    </Anchor>
                    &nbsp;with your BizPortal login email.
                    <SC.ListItem key="1" />
                    <SC.ListItem key="2">
                      <SC.ListNumber>•</SC.ListNumber>
                      Click <i>Access Empower now &gt; Forgot Password </i>if
                      you have forgotten your password
                    </SC.ListItem>
                    <SC.ListItem key="3">
                      <SC.ListNumber>•</SC.ListNumber>
                      If your account has not been created, click{' '}
                      <i>Create Account</i>
                    </SC.ListItem>
                  </SC.ListTitle>
                </SC.List>
              </Content>
            </Modal>
          )}

          <SC.Grid>
            <Row middle="sm">
              <Column sm={11} md={7}>
                <SC.LeftMenuItems isInline={true}>
                  <SC.ControlListItem>
                    <SC.Hamburger onMouseOver={onMenuOpen} onFocus={onMenuOpen}>
                      <IconHamburger size={24} />
                      <SC.Badge>New</SC.Badge>
                    </SC.Hamburger>
                  </SC.ControlListItem>
                  <SC.ControlListItem>
                    <SingtelLogo />
                  </SC.ControlListItem>
                  {!!isUDF ? null : (
                    <SC.ControlListItem>
                      <SC.Brand level={4}>{title}</SC.Brand>
                    </SC.ControlListItem>
                  )}
                </SC.LeftMenuItems>
              </Column>
              <Column sm={1} md={5}>
                <SC.RightMenu isInline>
                  <Row center="sm" middle="sm" end="sm">
                    <SC.GridDesktop>
                      {!!userName.trim() && (
                        <SC.Typography>
                          {'Welcome, '}
                          <SC.User>
                            {userName} ({role})
                          </SC.User>
                        </SC.Typography>
                      )}
                    </SC.GridDesktop>
                    <SC.AvatarConatiner>
                      <Avatar
                        baseUrl=""
                        menu={[
                          {
                            title: 'Account Settings',
                            href: SYSTEM_PATH_PREFIX + '/account/'
                          },
                          {
                            title: 'Change Password',
                            href: SYSTEM_PATH_PREFIX + '/changepassword/'
                          },
                          { title: 'Logout', onClick: onLogout, href: '#' }
                        ]}
                      />
                    </SC.AvatarConatiner>
                  </Row>
                </SC.RightMenu>
              </Column>
            </Row>
          </SC.Grid>
          {isMenuOpen && (
            <SC.MenuList
              onMouseOver={onMenuOpen}
              onFocus={onMenuOpen}
              onMouseLeave={onMenuClose}
              onBlur={onMenuClose}
            >
              <SC.GridMobile>
                <Content>
                  <Row center="sm" middle="sm">
                    <Column>
                      <SingtelLogo />
                      <IconClose size={24} onClick={onMenuClose} />
                    </Column>
                  </Row>
                </Content>
                {!!userName.trim() && (
                  <SC.Typography>
                    {'Welcome, '}
                    <SC.User>
                      {userName} ({role})
                    </SC.User>
                  </SC.Typography>
                )}
              </SC.GridMobile>
              <SC.FlexRow>
                {(menuItems || []).map((primaryMenuItem, index) => {
                  const secondaryMenu = primaryMenuItem.subMenu || [];
                  return (
                    <SC.PrimaryMenuColumn
                      sm={12}
                      lg
                      key={`primary-menu-${index}`}
                    >
                      <SC.AnchorBlackPrimary
                        pointer={!!getUrl(primaryMenuItem)}
                        href={getUrl(primaryMenuItem)}
                      >
                        {primaryMenuItem.title}
                      </SC.AnchorBlackPrimary>

                      {secondaryMenu.map(
                        (secondaryMenuItem, secondaryIndex) => {
                          const tertiaryMenu = secondaryMenuItem.subMenu || [];
                          return (
                            <SC.SecondaryContainer
                              key={`secondary-menu-${secondaryIndex}`}
                            >
                              <Row>
                                <Column sm={12} lg>
                                  <SC.AnchorBlackSecondary
                                    pointer={!!getUrl(secondaryMenuItem)}
                                    onClick={() =>
                                      menuOnClick(secondaryMenuItem)
                                    }
                                  >
                                    {secondaryMenuItem.title}
                                  </SC.AnchorBlackSecondary>
                                </Column>
                              </Row>

                              {tertiaryMenu.map(
                                (tertiaryMenuItem, tertiaryIndex) => (
                                  <Row key={`tertiary-menu-${tertiaryIndex}`}>
                                    <Column sm={12} lg>
                                      <SC.AnchorBlackTertiary
                                        pointer={!!getUrl(tertiaryMenuItem)}
                                        onClick={() =>
                                          menuOnClick(tertiaryMenuItem)
                                        }
                                      >
                                        {tertiaryMenuItem.title}
                                      </SC.AnchorBlackTertiary>
                                    </Column>
                                  </Row>
                                )
                              )}
                            </SC.SecondaryContainer>
                          );
                        }
                      )}
                    </SC.PrimaryMenuColumn>
                  );
                })}
              </SC.FlexRow>
            </SC.MenuList>
          )}
        </>
      )}
    </SC.Header>
  );
};

Header.propTypes = {
  /**menu items before user login */
  defaultMenuItems: PropTypes.array,
  /**menu items after user login */
  menuItems: PropTypes.array,
  /** user login status */
  isLoggedIn: PropTypes.bool,
  /** website title */
  title: PropTypes.string,
  /** logout callback */
  onLogout: PropTypes.func,
  /** click item callback */
  onItemClick: PropTypes.func,
  /** user name */
  userName: PropTypes.string,
  /** user role */
  role: PropTypes.string,
  /** impersonation status */
  isImpersonation: PropTypes.bool,
  permissions: PropTypes.object
};

export default Header;
