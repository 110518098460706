import Fetch from '@/utils/fetch';
import getUrl from '@/utils/urlUtils';

export async function requestContentList(condition, paginator) {
  const urls = await getUrl();
  const {
    contentApplication = [],
    segmentGroup = {},
    dimensionType = {},
    ...others
  } = condition || {};
  const postBody = {
    contentApplication: contentApplication.map((item) => item.value),
    segmentGroup: segmentGroup.value,
    dimensionType: dimensionType.value,
    ...others
  };
  return Fetch.post(urls.searchContent, { ...postBody, paginator });
}

export async function requestContentDetail(contentId) {
  const urls = await getUrl();
  return Fetch.get(urls.getContentDetail + '/' + contentId);
}

export async function requestDeleteContents(contents) {
  const urls = await getUrl();
  return Fetch.post(urls.deleteContent, contents);
}

export async function requestCreateContent(detail) {
  const urls = await getUrl();
  const { file, popupInd, ...others } = detail;

  const postbody = new FormData();
  const contentDetail = {
    ...others,
    contentCategory: 'CC_APP',
    dimensionType: 'CT_A',
    popup: popupInd?.value || false
  };
  postbody.append('item', file);
  postbody.append('json', JSON.stringify(contentDetail));

  const header = { 'Content-Type': undefined };

  return Fetch.postPlainBody(urls.createContent, postbody, header);
}

export async function requestEditContent(detail) {
  const urls = await getUrl();

  const { file, popupInd, ...others } = detail;

  const postbody = new FormData();
  const contentDetail = {
    ...others,
    popup: popupInd?.value || false
  };

  typeof file === 'object' && postbody.append('item', file);
  postbody.append('json', JSON.stringify(contentDetail));

  const header = { 'Content-Type': undefined };

  return Fetch.postPlainBody(urls.updateContent, postbody, header);
}

//assign content
export async function requestAssignContents(contents, target) {
  const urls = await getUrl();
  return Fetch.post(urls.assignContent + target, contents);
}

//get page content
export async function requestPageContents(page) {
  const urls = await getUrl();
  return Fetch.get(urls.getPageContent + '/' + page);
}
