import { createRequestType } from '@/utils/reduxUtils';

export const getCompanyList = createRequestType('SELECT/GET_COMPANY_LIST');

export const getAppList = createRequestType('SELECT/GET_APPLICATION_LIST');

export const getOtherBrnList = createRequestType('SELECT/GET_OTHER_BRNS');

export const getOtherBrnsListForSpoc = createRequestType(
  'SELECT/GET_OTHER_BRNS_SPOC'
);

export const searchContentList = createRequestType(
  'SELECT/SEARCH_CONTENT_LIST'
);

export const getUserList = createRequestType('SELECT/GET_USER_LIST');
