import * as types from '@/actionTypes/accountTypes';
import { REQUEST, SUCCESS, FAILURE, createReducer } from '@/utils/reduxUtils';

export const initialState = {
  accountDetail: {},
  isLoadingAccount: false,
  isPendingAction: false
};

export default createReducer(initialState, {
  //get account reducers
  [types.getAccount[REQUEST]](state) {
    return {
      ...state,
      isLoadingAccount: true
    };
  },
  [types.getAccount[SUCCESS]](state, action) {
    return {
      ...state,
      accountDetail: action.data,
      isLoadingAccount: false
    };
  },
  [types.getAccount[FAILURE]](state) {
    return {
      ...state,
      accountDetail: {},
      isLoadingAccount: false
    };
  },
  //update account reducers
  [types.updateAccount[REQUEST]](state) {
    return {
      ...state,
      isPendingAction: true
    };
  },
  //change pending reducer
  [types.changePending](state, action) {
    return {
      ...state,
      isPendingAction: action.pendingStatus
    };
  }
});
