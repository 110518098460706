import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as ationTypes from '@/actionTypes/messageTypes';
import { REQUEST } from '@/utils/reduxUtils';
import * as apis from '@/apis/messageApi';
import * as actions from '@/actions/MessageActions';
import { OnDownLoadCSV } from '@/utils/helpers';
import { responseCheck } from '@/utils/helpers/responseHelper';

import * as msgs from '@/actions/SnackbarActions';
import * as modalActions from '@/actions/ResultModalActions';

function* editMessage({ message }) {
  try {
    const [result] = yield all([call(apis.editMessage, message)]);
    yield call(responseCheck, result);
    yield put(actions.editMessage.success());
    yield put(
      modalActions.openModal(
        'Success',
        'Message edited successfully!',
        '/message/landing/'
      )
    );
  } catch (error) {
    yield put(msgs.danger(`${error?.message}`));
    yield put(actions.editMessage.failure());
  }
}
function* createMessage({ message }) {
  try {
    const [result] = yield all([call(apis.createMessage, message)]);
    yield call(responseCheck, result);
    yield put(actions.createMessage.success());
    yield put(
      modalActions.openModal(
        'Success',
        'Message created successfully!',
        '/message/landing/'
      )
    );
  } catch (error) {
    yield put(msgs.danger(`${error?.message}`));
    yield put(actions.createMessage.failure());
  }
}
function* exportMessage({ condition }) {
  try {
    const [result] = yield all([call(apis.exportMessage, condition)]);
    yield call(OnDownLoadCSV, result, 'MessageList.csv');
    yield put(actions.exportMessage.success());
  } catch (error) {
    yield put(actions.exportMessage.failure());
  }
}
function* deleteMessageList({ obj }) {
  try {
    const [result] = yield all([call(apis.deleteMessageList, obj)]);
    yield call(responseCheck, result);
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal(
        'Success',
        'Message deleted successfully!',
        '/message/landing/'
      )
    );
  } catch (error) {
    yield put(actions.changePending(false));
    yield put(msgs.danger(`${error}`));
  }
}
function* getMessageList({ condition, paginator }) {
  try {
    const [result] = yield all([
      call(apis.requestMessageList, condition, paginator)
    ]);
    yield call(responseCheck, result);
    yield put(actions.getMessageList.success(result.data, result.pagination));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.getMessageList.failure(error));
  }
}

export default function* messageSagas() {
  yield takeLatest(ationTypes.editMessage[REQUEST], editMessage);
  yield takeLatest(ationTypes.createMessage[REQUEST], createMessage);
  yield takeLatest(ationTypes.exportMessage[REQUEST], exportMessage);
  yield takeLatest(ationTypes.getMessageList[REQUEST], getMessageList);
  yield takeLatest(ationTypes.deleteMessageList[REQUEST], deleteMessageList);
}
