import React from 'react';
import PropTypes from 'prop-types';
import { DropDown } from '@lux/components';
import styled from 'styled-components';
import { remCalc } from '@lux/helpers';
import Theme from '@/theme';

const CusDropDown = ({ className, ...others }) => (
  <div className={className}>
    <DropDown {...others} />
  </div>
);

CusDropDown.propTypes = {
  className: PropTypes.string
};

const StyledDropDown = styled(CusDropDown)`
  .styles__DropDownWrapper-cDpHsq {
    height: ${remCalc(40)};
  }
  .styles__DropDownTrigger-hzIKrJ {
    height: 100%;
    border: 1px solid
      ${(props) =>
        props.error ? Theme.colours.rederror : Theme.colours.grey_700};
  }
`;

export default StyledDropDown;
