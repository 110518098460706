import * as types from '@/actionTypes/registrationTypes';
import { REQUEST, SUCCESS, FAILURE, createAction } from '@/utils/reduxUtils';

export const verifyBrn = {
  request: (brn, accountNum) =>
    createAction(types.verifyBrn[REQUEST], { brn, accountNum }),
  success: (data) => createAction(types.verifyBrn[SUCCESS], { data }),
  failure: (status) => createAction(types.verifyBrn[FAILURE], { status })
};

export const verifyBrnList = {
  request: (brn, brnList) =>
    createAction(types.verifyBrnList[REQUEST], { brn, brnList }),
  success: (data) => createAction(types.verifyBrnList[SUCCESS], { data }),
  failure: (status) => createAction(types.verifyBrnList[FAILURE], { status })
};

export const companyRegistration = {
  request: (companyInfo) =>
    createAction(types.companyRegistration[REQUEST], { companyInfo })
};

export const verifyServiceNum = {
  request: (serviceInfo) =>
    createAction(types.verifyServiceNum[REQUEST], { serviceInfo }),
  success: (data) => createAction(types.verifyServiceNum[SUCCESS], { data }),
  failure: (status) => createAction(types.verifyServiceNum[FAILURE], { status })
};

export const registerCESCustomer = {
  request: (userInfo) =>
    createAction(types.registerCESCustomer[REQUEST], { userInfo })
};

export const changePending = (pendingStatus) =>
  createAction(types.changePending, { pendingStatus });
