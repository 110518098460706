import * as types from '@/actionTypes/resultModalTypes';
import { createReducer } from '@/utils/reduxUtils';

export const initialState = {
  open: false,
  modalTitle: '',
  modalMessage: '',
  redirectTo: ''
};

export default createReducer(initialState, {
  [types.openModal](state, action) {
    return {
      ...state,
      open: true,
      modalTitle: action.message,
      modalMessage: action.title,
      redirectTo: action.redirectTo
    };
  },
  [types.closeModal](state) {
    return {
      ...state,
      open: false,
      modalTitle: '',
      modalMessage: ''
    };
  }
});
