import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SYSTEM_PATH_PREFIX } from '@/utils/constants';
import { Breadcrumbs as Original } from '@lux/components';

const Breadcrumbs = ({ items = [], ...props }) => {
  const [config, setConfig] = useState([]);
  useEffect(() => {
    const result = items.map((item) => {
      const link =
        typeof item.link === 'string' && item.link.startsWith('/')
          ? SYSTEM_PATH_PREFIX + item.link
          : item.link;
      return { ...item, link };
    });
    setConfig(result);
  }, [items]);
  return <Original items={config} {...props} />;
};

Breadcrumbs.propTypes = {
  items: PropTypes.array
};

export default Breadcrumbs;
