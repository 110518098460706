import { call, put, takeLatest, select } from 'redux-saga/effects';
import { navigate } from 'gatsby';

import * as contentApis from '@/apis/contentApi';
import * as apis from '@/apis/loginApi';
import * as userInfoApis from '@/apis/accountApi';
import * as actions from '@/actions/LoginActions';
import * as types from '@/actionTypes/loginTypes';
import { responseCheck } from '@/utils/helpers/responseHelper';
import { REQUEST } from '@/utils/reduxUtils';
import * as msgs from '@/actions/SnackbarActions';
import * as modalActions from '@/actions/ResultModalActions';
import createStore from '@/store';
import cookieUtils from '@/utils/cookieUtils';
import { TOKEN_KEY_COOKIE } from '@/utils/constants';
import { getVendorTarget } from '@/utils/helpers/login';

const serverError = (statusCode) => {
  if (statusCode)
    return `Sorry, An unexpected error occurred while processing your request, Code - ${statusCode}`;
  return 'Unable to login at this time due to System unavailability, Please try again later, contact customer support if you need help.';
};

function* fetchToken({ userId, password }) {
  try {
    const result = yield call(apis.requestToken, userId);
    yield call(cookieUtils.remove, TOKEN_KEY_COOKIE);
    yield call(responseCheck, result);
    if (result.data.token) {
      yield call(cookieUtils.set, TOKEN_KEY_COOKIE, result.data.token, 1);
      yield put(actions.requestLogin.request(userId, password));
    } else {
      throw new Error(serverError(result?.status?.code));
    }
  } catch (error) {
    yield put(msgs.danger(`${error}`));
  }
}

function* fetchLogin({ userid, password }) {
  try {
    yield put(msgs.clear());
    const result = yield call(loginFlow, userid, password);
    yield put(actions.requestLogin.success(result));
    let userInfo = { data: {} };
    userInfo = yield call(userInfoApis.requestAccount);
    yield put(actions.setUserInfo(userInfo.data));
    // yield put(modalActions.openModal('', 'loginNotification', '/home/'));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.requestLogin.failure(error));
  }
}

function* fetchImpersonate({ userid }) {
  try {
    yield put(msgs.clear());
    const result = yield call(loginFlow, userid, undefined, true);
    yield put(actions.requestImpersonate.success(result));
    let userInfo = { data: {} };
    userInfo = yield call(userInfoApis.requestAccount);
    yield put(actions.setUserInfo(userInfo.data));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.requestImpersonate.failure(error));
  }
}

function* loginFlow(userid, password, isImpersonation) {
  const result = yield call(
    isImpersonation ? apis.requestImpersonate : apis.requestLogin,
    userid,
    password
  );
  const status = result?.status;
  const pswdStatus = ['RESET_PASSWORD', 'PASSWORD_EXPIRED'];
  if (status?.code === '000000' || pswdStatus.indexOf(status?.error) > -1) {
    const { access_token } = result.data;
    if (access_token) {
      yield put(actions.requestMenuItems.request(userid));
      return { ...result.data };
    } else {
      throw new Error(serverError(status?.code));
    }
  } else {
    throw new Error(status?.message || serverError(status?.code));
  }
}

function* fetchLogout({ isImpersonation, redirectLink, message, external }) {
  try {
    yield call(apis.requestLogout, isImpersonation);
    yield put(actions.clearLoginTrash(redirectLink, message, external));
  } catch (error) {
    yield put(actions.clearLoginTrash(redirectLink, message, external));
    yield put(msgs.danger(`${error}`));
  }
}

function* clearLoginTrash({ redirectLink, message, external }) {
  try {
    yield call(cookieUtils.remove, TOKEN_KEY_COOKIE);
    if (redirectLink && !external)
      yield call(navigate, redirectLink, { state: { message } });
    if (redirectLink && external)
      setTimeout(() => {
        window.location.href = redirectLink;
      }, 1000);
  } catch (error) {
    yield put(msgs.danger(`${error}`));
  }
}

function* fetchInitData() {
  try {
    const result = yield call(contentApis.requestPageContents, 'login');
    yield call(responseCheck, result);
    yield put(actions.getContentData.success(result.data));
  } catch (error) {
    yield put(actions.getContentData.failure(error));
  }
}

function* fetchMenuItems({ email }) {
  try {
    const result = yield call(apis.requestMenu, { email });
    yield call(responseCheck, result);
    yield put(actions.requestMenuItems.success(result.data));
  } catch (error) {
    const pswdStatus = ['RESET_PASSWORD', 'PASSWORD_EXPIRED'];
    const getDtls = yield select(
      (state) => state.login?.currentUser?.authStatus
    );
    if (pswdStatus.indexOf(getDtls) === -1) {
      yield put(msgs.danger(`${error}`));
    }

    yield put(actions.requestMenuItems.failure(error));
  }
}

function* fetchNotification({ location }) {
  try {
    const result = yield call(apis.requestNotifications, location);
    yield call(responseCheck, result);
    yield put(actions.getNotifications.success(result.data));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.getNotifications.failure());
  }
}

function* fetchSetNewPassword({ key, token, password, flow }) {
  try {
    const result = yield call(
      apis.requestSetNewPassword,
      key,
      token,
      password,
      flow
    );
    yield put(actions.changePending(false));
    yield call(responseCheck, result);
    yield put(
      modalActions.openModal(
        'New Password Saved',
        'The new password has been saved.',
        flow === 'UDF'
          ? getVendorTarget(flow)
          : '/login/' + (window.location.search || '')
      )
    );
  } catch (error) {
    yield put(
      modalActions.openModal(
        'Failure',
        `${error?.message}`,
        `/static/forgotpassword/?key=${key}&token=${token}&` +
          (window.location.search || '')
      )
    );
  }
}

function* fetchForgotPassword({ email, flow }) {
  try {
    yield put(msgs.clear());
    const result = yield call(apis.requestForgotPassword, email, flow);
    yield call(responseCheck, result);
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal(
        'Success',
        'Forgot password request has been sent out. You will receive the email in a short time.',
        flow === 'UDF'
          ? getVendorTarget(flow)
          : '/login/' + (window.location.search || '')
      )
    );
  } catch (error) {
    yield put(actions.changePending(false));
    yield put(msgs.danger(`${error?.message}`));
  }
}

function* fetchChangePassword({ oldPassword, newPassword, authStatus, flow }) {
  try {
    const result = yield call(
      apis.requestChangePassword,
      oldPassword,
      newPassword
    );
    yield call(responseCheck, result);
    yield put(actions.changePending(false));
    if (authStatus !== 'LOGIN_SUCCESS') {
      //if user is not in LOGIN_SUCCESS status, logout user firstly
      const store = createStore.store.getState();
      const isImpersonation = store.login?.isImpersonation;
      yield put(actions.userLogout.request(isImpersonation, false));
    }

    yield put(
      modalActions.openModal(
        'New Password Saved',
        'The new password has been saved.',
        flow === 'UDF' ? getVendorTarget(flow) : '/home/'
      )
    );
  } catch (error) {
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal(
        'Failure',
        `${error?.message}`,
        '/changepassword/' + (window.location.search || '')
      )
    );
  }
}

function* fetchReportUserAccessApp({ auditKey }) {
  yield call(apis.requestReportAppAccess, auditKey);
}

export default function* loginSagas() {
  yield takeLatest(types.requestUserLogin[REQUEST], fetchLogin);
  yield takeLatest(types.requestMenuItems[REQUEST], fetchMenuItems);
  yield takeLatest(types.requestImpersonate[REQUEST], fetchImpersonate);
  yield takeLatest(types.startLogin, fetchToken);
  yield takeLatest(types.getContentData[REQUEST], fetchInitData);
  yield takeLatest(types.getNotifications[REQUEST], fetchNotification);
  yield takeLatest(types.forgotPassword[REQUEST], fetchForgotPassword);
  yield takeLatest(types.setNewPassword[REQUEST], fetchSetNewPassword);
  yield takeLatest(types.changePassword[REQUEST], fetchChangePassword);
  yield takeLatest(
    types.reportUserAccessApp[REQUEST],
    fetchReportUserAccessApp
  );
  yield takeLatest(types.userLogout[REQUEST], fetchLogout);
  yield takeLatest(types.clearLoginTrash, clearLoginTrash);
}
