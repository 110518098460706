import * as actionTypes from '@/actionTypes/snackbarTypes';
import { createReducer } from '@/utils/reduxUtils';

export const initialState = {
  message: '',
  types: ''
};

const messageReducer = (state, action) => {
  const { message, types } = action;
  return {
    ...state,
    message,
    types
  };
};

export default createReducer(initialState, {
  [actionTypes.danger]: messageReducer,
  [actionTypes.info]: messageReducer,
  [actionTypes.warning]: messageReducer,
  [actionTypes.success]: messageReducer,
  [actionTypes.clear]: messageReducer
});
