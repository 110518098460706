import * as types from '@/actionTypes/selectTypes';
import { REQUEST, SUCCESS, FAILURE, createAction } from '@/utils/reduxUtils';

export const searchCompany = {
  request: (condition, paginator) =>
    createAction(types.getCompanyList[REQUEST], { condition, paginator }),
  success: (data, pagination) =>
    createAction(types.getCompanyList[SUCCESS], { data, pagination }),
  failure: (status) => createAction(types.getCompanyList[FAILURE], { status })
};

export const getApplications = {
  request: () => createAction(types.getAppList[REQUEST]),
  success: (data) => createAction(types.getAppList[SUCCESS], { data }),
  failure: (status) => createAction(types.getAppList[FAILURE], { status })
};

export const getOtherBrnList = {
  request: (brn) => createAction(types.getOtherBrnList[REQUEST], { brn }),
  success: (data) => createAction(types.getOtherBrnList[SUCCESS], { data }),
  failure: (status) => createAction(types.getOtherBrnList[FAILURE], { status })
};

export const getOtherBrnsListForSpoc = {
  request: () => createAction(types.getOtherBrnsListForSpoc[REQUEST]),
  success: (data) =>
    createAction(types.getOtherBrnsListForSpoc[SUCCESS], { data }),
  failure: (status) =>
    createAction(types.getOtherBrnsListForSpoc[FAILURE], { status })
};

export const searchContentList = {
  request: (condition, paginator, id) =>
    createAction(types.searchContentList[REQUEST], {
      condition,
      paginator,
      id
    }),
  success: (data, pagination, id) =>
    createAction(types.searchContentList[SUCCESS], { data, pagination, id }),
  failure: (status, id) =>
    createAction(types.searchContentList[FAILURE], { status, id })
};

export const getUserList = {
  request: (condition, paginator) =>
    createAction(types.getUserList[REQUEST], { condition, paginator }),
  success: (data, pagination) =>
    createAction(types.getUserList[SUCCESS], { data, pagination }),
  failure: (status) => createAction(types.getUserList[FAILURE], { status })
};
