import { createRequestType } from '@/utils/reduxUtils';

export const getContentList = createRequestType('CONTENT/GET_CONTENT_LIST');

export const getContentDetail = createRequestType('CONTENT/GET_CONTENT_DETAIL');

export const deleteContents = createRequestType('CONTENT/DELETE_CONTENTS');

export const createContent = createRequestType('CONTENT/CREATE_CONTENT');

export const editContent = createRequestType('CONTENT/EDIT_CONTENT');

export const assignContent = createRequestType('CONTENT/ASSIGN_CONTENT');

export const getPageContents = createRequestType('CONTENT/GET_PAGE_CONTENTS');

export const changePending = 'CONTENT/CHANGE_PENDING';
