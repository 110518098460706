import * as actionTypes from '../../actionTypes/emailTypes';
import { REQUEST } from '../../utils/reduxUtils';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as apis from '../../apis/emailApi';
import * as actions from '../../actions/EmailActions';
import { responseCheck } from '@/utils/helpers/responseHelper';
import * as msgs from '@/actions/SnackbarActions';
import * as modalActions from '@/actions/ResultModalActions';

function* fetchTrackEmailList({ search, paginator }) {
  try {
    const result = yield call(apis.requestGetTrackEmailList, search, paginator);
    yield call(responseCheck, result);
    yield put(
      actions.getTrackEmailList.success(result.data, result.pagination)
    );
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.getTrackEmailList.failure(error));
  }
}

function* fetchResendEmail({ recipientIdSet }) {
  try {
    yield put(msgs.clear());
    const result = yield call(apis.requestResendEmail, recipientIdSet);
    yield call(responseCheck, result);
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal(
        'Success',
        'Email re-sent successfully!',
        '/email/track/'
      )
    );
  } catch (error) {
    yield put(actions.changePending(false));
    yield put(msgs.danger(`${error?.message}`));
  }
}

function* fetchTempEmailList() {
  try {
    const result = yield call(apis.requestGetTempEmailList);
    yield call(responseCheck, result);
    yield put(actions.getTempEmailList.success(result.data));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.getTempEmailList.failure(error));
  }
}

function* fetchTempEmail({ templateName }) {
  try {
    const result = yield call(apis.requestGetTempEmail, templateName);
    yield call(responseCheck, result);
    yield put(actions.getTempEmail.success(result.data));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.getTempEmail.failure(error));
  }
}

function* fetchTempEmailVariables({ templateName }) {
  try {
    const result = yield call(apis.requestGetVariables, templateName);
    yield put(actions.getVariables.success(result.data));
  } catch (error) {
    yield put(msgs.danger(`${error}`));
    yield put(actions.getVariables.failure(error));
  }
}

function* fetchUpdateTempEmail({ name, context, updateTime }) {
  try {
    const result = yield call(
      apis.requestUpdateTempEmail,
      name,
      context,
      updateTime
    );
    yield call(responseCheck, result);
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal(
        'Success',
        'Email Template updated successfully!',
        '/email/template/landing'
      )
    );
  } catch (error) {
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal(
        'Success',
        `${error?.message}`,
        '/email/template/landing'
      )
    );
  }
}

function* fetchTransferProfile({ giverAccountId, recipientAccountId }) {
  try {
    const result = yield call(
      apis.requestTransferProfile,
      giverAccountId,
      recipientAccountId
    );
    yield put(actions.changePending(false));
    yield call(responseCheck, result);
    yield put(
      modalActions.openModal(
        'Success',
        'Transferred profile successfully!',
        '/transferprofile'
      )
    );
  } catch (error) {
    yield put(actions.changePending(false));
    yield put(
      modalActions.openModal('Failure', `${error?.message}`, '/transferprofile')
    );
  }
}

export default function* emailSagas() {
  yield takeLatest(actionTypes.getTrackEmailList[REQUEST], fetchTrackEmailList);
  yield takeLatest(actionTypes.resendEmail[REQUEST], fetchResendEmail);
  yield takeLatest(actionTypes.getTempEmailList[REQUEST], fetchTempEmailList);
  yield takeLatest(actionTypes.getTempEmail[REQUEST], fetchTempEmail);
  yield takeLatest(actionTypes.getVariables[REQUEST], fetchTempEmailVariables);
  yield takeLatest(actionTypes.updateTempEmail[REQUEST], fetchUpdateTempEmail);
  yield takeLatest(actionTypes.transferProfile[REQUEST], fetchTransferProfile);
}
