import styled from 'styled-components';
import BaseIconClose from '@lux/components/src/assets/svgs/close.svg';
import { remCalc } from '@lux/helpers';

const IconClose = styled(BaseIconClose)`
  position: absolute;
  top: ${remCalc(32)};
  right: ${remCalc(32)};
  cursor: pointer;
`;

export default IconClose;
